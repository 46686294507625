/* eslint-disable max-lines */
import { FC } from "react"
import { Translate } from "../../../../../constant/Translate"
import { Card } from "../../../../../_webui/layout/components/Card"
import { Layout } from "../../../../../_webui/layout/components/Layout"
import { Table } from "../../../../../_webui/layout/components/Table"
import { TotalRecords } from "../../../../../_webui/layout/components/Typography/TotalRecords"
import { SchedulesAdvancedSearch } from "../../components/list/AdvancedSearch"
import { SchedulesButtonGroup } from "../../components/list/ButtonGroup"
import { SchedulesFormSearch } from "../../components/list/FormSearch"
import { SchedulesModalConfirm } from "../../components/list/ModalConfirm"
import { SchedulesListDataType } from "../../models/list"
import { useSchedulesList } from "./modules/useList"
import { Styled } from "./styled"

const { StyledOverRecord } = Styled
const { TEXT_T190 } = Translate

export const SchedulesList: FC = () => {
  const {
    allCountries,
    arrivalCities,
    arrivalHourItems,
    arrivalMinuteItems,
    breadcrumbItems,
    columns,
    countries,
    dataSource,
    departments,
    departureCities,
    disabledDateTo,
    dispatchType,
    dispatchTypeDetail,
    form,
    formAdvanced,
    fullName,
    goBack,
    goToAttention,
    goToSafety,
    goToSchedulesAdd,
    goToSchedulesConfirmDelete,
    goToSchedulesDetail,
    goToSchedulesEdit,
    handleAdvancedSearch,
    handleCancelModalExport,
    handleExportFile,
    handleOpenModalExport,
    handleSearch,
    isDefaultValueArrivalDate,
    isDefaultValueDepartureDate,
    isDisabledArrivalCity,
    isDisabledBtnCreateOrSend,
    isDisabledBtnDelete,
    isDisabledBtnRefer,
    isDisabledDepartureCity,
    isDisabledDispatchTypeDetail,
    isFromSCR114UserSearch,
    isHiddenBtnCreateOrSendAttentions,
    isHiddenBtnCreateOrSendSafeties,
    isHiddenBtnDelete,
    isHiddenBtnEdit,
    isHiddenBtnExportCsv,
    isHiddenBtnExportExcel,
    isHiddenBtnExportExcelSafeties,
    isHiddenBtnExportExcelTravelers,
    isHiddenBtnRefer,
    isHiddenBtnResetForm,
    isHiddenBtnSearchBasic,
    isLoading,
    isLoadingLayout,
    isOpen,
    isOpenLoadData,
    isOpenModalExport,
    isOpenShowData,
    isOverseasResident,
    isPathnameSchedules,
    isPathnameSchedulesOrTravelers,
    isPathnameTravelers,
    isResetTable,
    isSearchAdvance,
    messageConfirmDownload,
    onBlurAdvanced,
    onBlurBasic,
    onCancelCustomLoadData,
    onCancelSearchAdvance,
    onChange,
    onChangeArrivalCity,
    onChangeArrivalCountryCode,
    onChangeArrivalDate,
    onChangeDepartureCity,
    onChangeDepartureCountryCode,
    onChangeDepartureDate,
    onChangeDispatchType,
    onChangeTable,
    onChangeWorkPlaceLocation,
    onClickReset,
    onClickResetSearchAdvance,
    onKeyDownAdvanced,
    onKeyDownBasic,
    onOkLoadData,
    onOkShowData,
    onOpenSearchAdvance,
    onValuesChangeFormSearchAdvanced,
    overRecordLabel,
    perItem,
    personPrefectureResidence,
    purposeTravel,
    rowSelection,
    transportation,
  } = useSchedulesList()

  return (
    <Layout
      breadcrumbItems={breadcrumbItems}
      isLoadingLayout={isLoadingLayout}
      title={TEXT_T190}
    >
      <Card heading={TEXT_T190} isLoading={isLoading}>
        {!isFromSCR114UserSearch && (
          <SchedulesFormSearch
            arrivalCities={arrivalCities}
            countries={countries}
            departments={departments}
            disabledDateTo={disabledDateTo}
            form={form}
            fullName={fullName}
            handleSearch={handleSearch}
            isDisabledArrivalCity={isDisabledArrivalCity}
            isHiddenBtnResetForm={isHiddenBtnResetForm}
            isHiddenBtnSearchBasic={isHiddenBtnSearchBasic}
            isPathnameSchedules={isPathnameSchedules}
            isSearchAdvance={isSearchAdvance}
            onBlurBasic={onBlurBasic}
            onChangeArrivalCity={onChangeArrivalCity}
            onChangeArrivalCountryCode={onChangeArrivalCountryCode}
            onClickReset={onClickReset}
            onKeyDownBasic={onKeyDownBasic}
            onOpenSearchAdvance={onOpenSearchAdvance}
          />
        )}
        <TotalRecords
          perItem={perItem}
          titleLeft={TEXT_T190}
          titleRight={<StyledOverRecord>{overRecordLabel}</StyledOverRecord>}
          total={dataSource?.length}
        />
        <Table
          columns={columns}
          dataSource={dataSource}
          isResetTable={isResetTable}
          onChange={onChange}
          onChangeTable={onChangeTable}
          rowKey={(record: SchedulesListDataType) =>
            record.info.travelScheduleId
          }
          rowSelection={rowSelection}
        />
        <SchedulesButtonGroup
          goBack={goBack}
          goToAttention={goToAttention}
          goToSafety={goToSafety}
          goToSchedulesAdd={goToSchedulesAdd}
          goToSchedulesConfirmDelete={goToSchedulesConfirmDelete}
          goToSchedulesDetail={goToSchedulesDetail}
          goToSchedulesEdit={goToSchedulesEdit}
          handleOpenModalExport={handleOpenModalExport}
          isDisabledBtnCreateOrSend={isDisabledBtnCreateOrSend}
          isDisabledBtnDelete={isDisabledBtnDelete}
          isDisabledBtnRefer={isDisabledBtnRefer}
          isFromSCR114UserSearch={isFromSCR114UserSearch}
          isHiddenBtnCreateOrSendAttentions={isHiddenBtnCreateOrSendAttentions}
          isHiddenBtnCreateOrSendSafeties={isHiddenBtnCreateOrSendSafeties}
          isHiddenBtnDelete={isHiddenBtnDelete}
          isHiddenBtnEdit={isHiddenBtnEdit}
          isHiddenBtnExportCsv={isHiddenBtnExportCsv}
          isHiddenBtnExportExcel={isHiddenBtnExportExcel}
          isHiddenBtnExportExcelSafeties={isHiddenBtnExportExcelSafeties}
          isHiddenBtnExportExcelTravelers={isHiddenBtnExportExcelTravelers}
          isHiddenBtnRefer={isHiddenBtnRefer}
          isPathnameTravelers={isPathnameTravelers}
          isPathnameSchedulesOrTravelers={isPathnameSchedulesOrTravelers}
        />
        <SchedulesAdvancedSearch
          allCountries={allCountries}
          arrivalCities={arrivalCities}
          arrivalHourItems={arrivalHourItems}
          arrivalMinuteItems={arrivalMinuteItems}
          departments={departments}
          departureCities={departureCities}
          dispatchType={dispatchType}
          dispatchTypeDetail={dispatchTypeDetail}
          formAdvanced={formAdvanced}
          isDefaultValueArrivalDate={isDefaultValueArrivalDate}
          isDefaultValueDepartureDate={isDefaultValueDepartureDate}
          isDisabledArrivalCity={isDisabledArrivalCity}
          isDisabledDepartureCity={isDisabledDepartureCity}
          isDisabledDispatchTypeDetail={isDisabledDispatchTypeDetail}
          isOpen={isOpen}
          isOverseasResident={isOverseasResident}
          isPathnameSchedules={isPathnameSchedules}
          onBlurAdvanced={onBlurAdvanced}
          onCancelSearchAdvance={onCancelSearchAdvance}
          onChangeArrivalCity={onChangeArrivalCity}
          onChangeArrivalCountryCode={onChangeArrivalCountryCode}
          onChangeArrivalDate={onChangeArrivalDate}
          onChangeDepartureCity={onChangeDepartureCity}
          onChangeDepartureCountryCode={onChangeDepartureCountryCode}
          onChangeDepartureDate={onChangeDepartureDate}
          onChangeDispatchType={onChangeDispatchType}
          onChangeWorkPlaceLocation={onChangeWorkPlaceLocation}
          onClickResetSearchAdvance={onClickResetSearchAdvance}
          onFinish={handleAdvancedSearch}
          onKeyDownAdvanced={onKeyDownAdvanced}
          onValuesChangeFormSearchAdvanced={onValuesChangeFormSearchAdvanced}
          personPrefectureResidence={personPrefectureResidence}
          purposeTravel={purposeTravel}
          transportation={transportation}
        />
        <SchedulesModalConfirm
          isOpen={isOpenModalExport}
          isOpenLoadData={isOpenLoadData}
          isOpenShowData={isOpenShowData}
          messageConfirmDownload={messageConfirmDownload}
          onCancel={handleCancelModalExport}
          onCancelCustomLoadData={onCancelCustomLoadData}
          onOk={handleExportFile}
          onOkLoadData={onOkLoadData}
          onOkShowData={onOkShowData}
        />
      </Card>
    </Layout>
  )
}
