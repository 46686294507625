/* eslint-disable max-lines */
import { Key } from "react"
import { Endpoint } from "../../../../constant/Endpoint"
import { Environment } from "../../../../constant/Environment"
import { apiService } from "../../../../infrastructure/apiService"
import { replaceStringParam } from "../../../../utils/replaceStringParam"
import { ScheduleDataAddType } from "../models/add/DataAdd"
import { SchedulesListPostType } from "../models/list"

const { COMMON_ENDPOINT, SCHEDULE_ENDPOINT } = Environment

const {
  FNC1B_Add_Schedule,
  FNC1B_Add_Schedule_Draft,
  FNC1B_Bulk_Add,
  FNC1B_Bulk_Check,
  FNC1B_Bulk_Data,
  FNC1B_Bulk_Delete_Data,
  FNC1B_Bulk_Delete,
  FNC1B_Bulk_File_Add,
  FNC1B_Bulk_File_Delete,
  FNC1B_Bulk_File_Update,
  FNC1B_Bulk_Update,
  FNC1B_Delete_Schedule,
  FNC1B_Delete_Schedules,
  FNC1B_Export,
  FNC1B_Get_Flight_Info,
  FNC1B_Get_Schedule,
  FNC1B_Get_Schedule_Draft,
  FNC1B_Get_Schedule_Draft_Export,
  FNC1B_Get_Schedules,
  FNC1B_Update_Schedule,
  FNC9_Get_Countries,
  FNC9_Get_Purpose_Travel,
} = Endpoint

export const scheduleApi = {
  addSchedule: (data: ScheduleDataAddType) =>
    apiService({
      data,
      method: "POST",
      url: `${SCHEDULE_ENDPOINT}${FNC1B_Add_Schedule}`,
    }),
  addScheduleDraft: (scheduleId: string, data: ScheduleDataAddType) =>
    apiService({
      data,
      method: "POST",
      url: `${SCHEDULE_ENDPOINT}${replaceStringParam(
        FNC1B_Add_Schedule_Draft,
        scheduleId,
      )}`,
    }),
  bulkAdd: (screenId: string) =>
    apiService({
      data: screenId,
      method: "POST",
      url: `${SCHEDULE_ENDPOINT}${FNC1B_Bulk_Add}`,
    }),
  bulkCheck: (screenId: string) =>
    apiService({
      method: "GET",
      url: `${SCHEDULE_ENDPOINT}${replaceStringParam(
        FNC1B_Bulk_Check,
        screenId,
      )}`,
    }),
  bulkData: (screenId: string) =>
    apiService({
      method: "GET",
      url: `${SCHEDULE_ENDPOINT}${FNC1B_Bulk_Data}?screenId=${screenId}`,
    }),
  bulkDelete: (screenId: string) =>
    apiService({
      data: screenId,
      method: "DELETE",
      url: `${SCHEDULE_ENDPOINT}${FNC1B_Bulk_Delete}`,
    }),
  bulkDeleteData: (screenId: string) =>
    apiService({
      method: "DELETE",
      url: `${SCHEDULE_ENDPOINT}${replaceStringParam(
        FNC1B_Bulk_Delete_Data,
        screenId,
      )}`,
    }),

  bulkFileAdd: (data: FormData) =>
    apiService({
      data,
      method: "POST",
      url: `${SCHEDULE_ENDPOINT}${FNC1B_Bulk_File_Add}`,
    }),

  bulkFileDelete: (data: FormData) =>
    apiService({
      data,
      method: "POST",
      url: `${SCHEDULE_ENDPOINT}${FNC1B_Bulk_File_Delete}`,
    }),

  bulkFileUpdate: (data: FormData) =>
    apiService({
      data,
      method: "POST",
      url: `${SCHEDULE_ENDPOINT}${FNC1B_Bulk_File_Update}`,
    }),

  bulkUpdate: (screenId: string) =>
    apiService({
      data: screenId,
      method: "PUT",
      url: `${SCHEDULE_ENDPOINT}${FNC1B_Bulk_Update}`,
    }),
  deleteSchedule: (scheduleId: string) =>
    apiService({
      method: "PUT",
      url: `${SCHEDULE_ENDPOINT}${replaceStringParam(
        FNC1B_Delete_Schedule,
        scheduleId,
      )}`,
    }),
  deleteSchedules: (data: string[]) =>
    apiService({
      data,
      method: "PUT",
      url: `${SCHEDULE_ENDPOINT}${FNC1B_Delete_Schedules}`,
    }),
  export: (data: Key[], formId: string) =>
    apiService({
      data,
      method: "POST",
      url: `${SCHEDULE_ENDPOINT}${FNC1B_Export}?formType=${formId}`,
    }),
  getCountries: () =>
    apiService({
      method: "GET",
      url: `${COMMON_ENDPOINT}${FNC9_Get_Countries}`,
    }),
  getFlightInfo: (travelerId: string) =>
    apiService({
      method: "GET",
      url: `${SCHEDULE_ENDPOINT}${`${FNC1B_Get_Flight_Info}?flightNumber=${""}&travelerId=${travelerId}`}`,
    }),
  getPurposeTravel: () =>
    apiService({
      method: "GET",
      url: `${COMMON_ENDPOINT}${FNC9_Get_Purpose_Travel}`,
    }),
  getSchedule: (scheduleId: string) =>
    apiService({
      method: "GET",
      url: `${SCHEDULE_ENDPOINT}${replaceStringParam(
        FNC1B_Get_Schedule,
        scheduleId,
      )}`,
    }),
  getScheduleDraft: (scheduleId: string) =>
    apiService({
      method: "GET",
      url: `${SCHEDULE_ENDPOINT}${replaceStringParam(
        FNC1B_Get_Schedule_Draft,
        scheduleId,
      )}`,
    }),
  getScheduleDraftExport: (scheduleId: string) =>
    apiService({
      method: "GET",
      url: `${SCHEDULE_ENDPOINT}${replaceStringParam(
        FNC1B_Get_Schedule_Draft_Export,
        scheduleId,
      )}`,
    }),
  getSchedules: (data?: SchedulesListPostType) =>
    apiService({
      data,
      method: "POST",
      url: `${SCHEDULE_ENDPOINT}${FNC1B_Get_Schedules}`,
    }),
  updateSchedule: (travelScheduleId: string, data: ScheduleDataAddType) =>
    apiService({
      data,
      method: "PUT",
      url: `${SCHEDULE_ENDPOINT}${replaceStringParam(
        FNC1B_Update_Schedule,
        travelScheduleId,
      )}`,
    }),
}
