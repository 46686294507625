/* eslint-disable max-nested-callbacks */
/* eslint-disable max-statements */
/* eslint-disable complexity */
/* eslint-disable max-lines */
import { useEffect } from "react"
import { OptionItemType } from "../../../../../../../_webui/layout/components/Select/models"
import { GeneralCode } from "../../../../../../../constant/GeneralCode"
import { Routers } from "../../../../../../../constant/Routers"
import { AxiosErrorType } from "../../../../../../../infrastructure/axiosError/models"
import { departmentsApi } from "../../../../../../../infrastructure/handlers/departments"
import { DepartmentsResType } from "../../../../../../../infrastructure/handlers/departments/models"
import { generalMastersApi } from "../../../../../../../infrastructure/handlers/generalMasters"
import { GeneralMastersResType } from "../../../../../../../infrastructure/handlers/generalMasters/models"
import { backToLogin } from "../../../../../../../utils/backToLogin"
import { getValueByLanguage } from "../../../../../../../utils/getValueByLanguage"
import { replaceString } from "../../../../../../../utils/replaceString"
import { setFieldsErrors } from "../../../../../../../utils/setFieldsErrors"
import { travelerApi } from "../../../../../travelers/apis"
import { scheduleApi } from "../../../../apis"
import { TravelerInfoResponseType } from "../../../../components/add/Form/ContentCollapse/Personal/models"
import { Variables } from "../../../../constants/add/variables"
import {
  HandleDataTravelerBaseProps,
  ScheduleDataType,
  UseSchedulesAddType,
} from "../../../../models/add"
import { ScheduleDataAddType } from "../../../../models/add/DataAdd"
import { getScheduleDataAdd } from "../../utils/getDataAdd"
import { getTravelerInfo } from "../../utils/getTravelerInfo"
import { useVariables } from "../useVariables"
import { countriesApi } from "../../../../../../../infrastructure/handlers/countries"
import { CitiesResType } from "../../../../../../../infrastructure/handlers/countries/models"
import { SelectItems } from "../../../../../../../constant/SelectItems"
import { TravelersDetailResType } from "../../../../../travelers/models/detail"
import { Translate } from "../../../../../../../constant/Translate"
import { setNameRowDetail } from "../../utils/setNameRowDetail"
import { translateMessage } from "../../../../../../../utils/translateMessage"
import { getWorkPlaceLocation } from "../../../../../../../utils/getWorkPlaceLocation"
import { Variables as TravelerEditVariables } from "../../../../../travelers/constants/edit/variables"
import { openNotification } from "../../../../../../../utils/openNotification"
import { SchedulesListDataType } from "../../../../models/list"
import { exportFRM101 } from "../../../list/modules/useExportFile/exportFRM101"

const { DPTD, DPTD_SUBADMIN, OTHER, PPTV, PPTV_SCR212 } = GeneralCode
const {
  SCR114,
  SCR210,
  SCR210_TRAVELERS,
  SCR210_USER_SEARCH,
  SCR212_USER,
  SCR213,
  SCR920,
} = Routers
const { OTHER_CITY_SELECT_ITEMS } = SelectItems
const { MESSAGE_I0026, MESSAGE_E0138 } = Translate
const {
  DPTD_OTHERS_STAFF_NUMBER,
  E0017,
  E0104,
  E0105,
  E0106,
  E0107,
  E0152,
  E0156,
} = Variables
const { KEYS_ACCOMPANYING_FAMILY } = TravelerEditVariables

export const useSchedulesAdd = (): UseSchedulesAddType => {
  const {
    allCountries,
    assignmentValidMsg,
    breadcrumbItems,
    dataSubmit,
    defaultActiveKey,
    departments,
    detailColumnsFirst,
    detailColumnsSecond,
    detailDataSource,
    detailIndexIdentity,
    detailIndexTabActive,
    detailRowSelection,
    detailSelectedRowKeys,
    detailTabsItems,
    detailValidMsg,
    dispatchType,
    form,
    getFlightInfo,
    getInitialValues,
    getPurposeTravelList,
    goToSchedulesClone,
    handleAddDetailItem,
    handleAddLodgingItem,
    handleAddStayItem,
    handleCheckHiredLocally,
    handleCheckInsuranceAndUrgent,
    handleCheckRequiredDispatchDate,
    handleCheckTwoWay,
    handleDeleteDetailItem,
    handleDeleteLodgingItem,
    handleDeleteStayItem,
    handleError,
    handleErrorValidate,
    handleSetDetailRequiredFields,
    handleSetLodgingRequiredFields,
    handleSetStayRequiredFields,
    id,
    isAccompanyingFamily,
    isActiveBumon,
    isDifferentInfoChecked,
    isDisabledSubmit,
    isFromSCR212,
    isHiddenBtnScheduleDraft,
    isHiredLocally,
    isInsuranceAndUrgent,
    isInsuranceRequired,
    isLoading,
    isLoadingLayout,
    isNotAccessScreen,
    isOpen,
    isOpenExportScheduleDraft,
    isOpenPopup,
    isOpenRoundTrip,
    isOpenScheduleDraft,
    isOtherStaffNumber,
    isRequiredDispatchDate,
    isRequiredItemTableByDate,
    isRequiredItemTableByPPTV,
    isRequiredItemTableLodgings,
    isRoleAdmin,
    isSchedulesAdd,
    isSchedulesEdit,
    isSubmitValidate,
    isTwoWay,
    isValidForm,
    isVisibleBtn,
    isVisibleBtnCancel,
    isVisibleBtnConfirm,
    isVisibleBtnCopy,
    isVisibleOtherRemarks,
    listPPTVIsValue3Equal1,
    listPPTVIsValue4Equal2,
    lodgingColumns,
    lodgingDataSource,
    lodgingIndexIdentity,
    lodgingIndexTabActive,
    lodgingRowSelection,
    lodgingSelectedRowKeys,
    lodgingTabsItems,
    lodgingValidMsg,
    onBlur,
    onCancel,
    onCancelExportScheduleDraft,
    onCancelPopup,
    onCancelRoundTrip,
    onCancelScheduleDraft,
    onChangeCollapse,
    onChangeDate,
    onChangeDifferentInfo,
    onChangeTravelPurpose,
    onClickDetailTab,
    onClickLodgingTab,
    onClickStayTab,
    onDispatchTypeDetailChange,
    onFinish,
    onKeyDown,
    onOkRoundTrip,
    onOpenExportScheduleDraft,
    onOpenPopup,
    onOpenScheduleDraft,
    onValidateChangeDate,
    onValuesChange,
    pathname,
    purposeTravelList,
    push,
    scheduleDetails,
    scheduleLodgings,
    scheduleStays,
    schedulesData,
    setAssignmentValidMsg,
    setDataScheduleDetails,
    setDataScheduleLodgings,
    setDataScheduleStays,
    setDefaultCountries,
    setDepartments,
    setDetailDataSource,
    setDetailValidMsg,
    setDispatchType,
    setDispatchTypeDetailRes,
    setIsAccompanyingFamily,
    setIsActiveBumon,
    setIsDifferentInfoChecked,
    setIsHiddenBtnScheduleDraft,
    setIsLoading,
    setIsLoadingLayout,
    setIsOtherStaffNumber,
    setIsRequiredItemTableByPPTV,
    setIsRequiredItemTableLodgings,
    setIsTwoWay,
    setLodgingDataSource,
    setLodgingValidMsg,
    setPurposeTravelRes,
    setSchedulesData,
    setStayDataSource,
    setTravelerBase,
    setTravelerBumonCd,
    setTravelerDetail,
    setTravelerId,
    setWorkPlaceCountryCode,
    setWorkPlaceLocation,
    state,
    stayColumns,
    stayDataSource,
    stayIndexIdentity,
    stayIndexTabActive,
    stayRowSelection,
    staySelectedRowKeys,
    stayTabsItems,
    transportationList,
    travelerBase,
    travelerBumonCd,
    travelerDetail,
    travelerId,
  } = useVariables()

  const goBack = () => {
    // 「SCR920_ホーム画面」に遷移する。
    if (isFromSCR212) push(SCR920)
    //「SCR210_渡航予定情報の一覧画面」に遷移する。
    else if (state?.isFromSCR114UserSearch) {
      push(SCR210_USER_SEARCH, {
        ...state,
        isFromSCR114UserSearch: true,
        isFromSCR115: true,
      })
    }
    // 「SCR114_渡航者情報の一覧画面」に遷移する。
    else push(SCR114, state)
  }

  const onOk = async () => {
    const check = handleCheckTwoWay()
    setIsTwoWay(check)
    if (check) {
      setTimeout(() => {
        document.querySelectorAll(".ant-collapse-item")[4]?.scrollIntoView()
      }, 300)
    } else if (travelerId) {
      const data: ScheduleDataAddType = await getScheduleDataAdd({
        allCountries,
        dataSubmit,
        departments,
        detailDataSource,
        detailIndexIdentity,
        id,
        isFromSCR114UserSearch: state?.isFromSCR114UserSearch,
        isHiredLocally,
        isOtherStaffNumber,
        isSchedulesAdd,
        isSchedulesEdit,
        lodgingIndexIdentity,
        purposeTravelList,
        schedulesData,
        stayIndexIdentity,
        transportationList,
        travelerId,
      })
      // データをデータベースに登録する。
      setAssignmentValidMsg("")
      setIsLoading(true)
      scheduleApi
        .addSchedule(data)
        .then((res) => {
          // 「SCR213_渡航予定情報の登録後画面」に遷移する。
          push(replaceString(SCR213, res.travelScheduleId), {
            ...state,
            isFromSCR212: true,
            schedulesURL: !(pathname === replaceString(SCR212_USER, id))
              ? SCR210
              : state.isFromSCR114UserSearch
              ? SCR210_USER_SEARCH
              : SCR210_TRAVELERS,
            sourceURL: pathname,
          })
        })
        .catch((error: AxiosErrorType) => {
          const data = error?.debug?.response?.data
          const messages = [E0017, E0104, E0105, E0106, E0107, E0152, E0156]
          const entityName = error?.debug?.response?.data?.entityName
          if (data.message === "E0138") {
            const _entityName = entityName.split(",")
            for (let index = 0; index < _entityName.length; index++) {
              form.setFields([
                {
                  errors: [MESSAGE_E0138],
                  name: `${setNameRowDetail(
                    +_entityName[index],
                  )}.departureDate`,
                },
              ])
            }
            form.scrollToField(
              `${setNameRowDetail(+_entityName[0])}.departureDate`,
            )
          } else {
            if (entityName && !["detail", "lodging"].includes(entityName))
              setFieldsErrors(error, form)
            else if (messages.includes(data?.message)) {
              if (entityName === "detail") {
                setDetailValidMsg(translateMessage(`MESSAGE.${data.message}`))
              } else if (entityName === "lodging") {
                setLodgingValidMsg(translateMessage(`MESSAGE.${data.message}`))
              } else {
                handleErrorValidate(data.message, entityName)
              }
            } else handleError(error)
          }
        })
        .finally(() => setIsLoading(false))
    }
  }

  const handleDataTravelerBase = (props: HandleDataTravelerBaseProps) => {
    const {
      dispatchTypeDetailRes,
      purposeTravelRes,
      travelerDetailsRes,
      travelerId,
      travelerInfoRes,
    } = props
    handleCheckInsuranceAndUrgent(travelerDetailsRes)
    const { othersStaffNumber, workPlaceCountry, workPlaceLocation } =
      travelerDetailsRes.detail
    const _workPlaceLocation = getWorkPlaceLocation(workPlaceLocation)
    form.setFieldValue("workPlaceLocation", _workPlaceLocation)
    setWorkPlaceLocation(workPlaceLocation)
    setWorkPlaceCountryCode(workPlaceCountry)
    setTravelerBumonCd(travelerDetailsRes.base.bumonCd)
    setIsOtherStaffNumber(!!othersStaffNumber)
    setIsHiddenBtnScheduleDraft(!travelerDetailsRes.hasTemporarilySchedule)
    setIsAccompanyingFamily(
      KEYS_ACCOMPANYING_FAMILY.includes(travelerInfoRes.dispatchTypeDetailId),
    )

    const optionItem: OptionItemType[] = dispatchTypeDetailRes.map((item) => ({
      text: String(getValueByLanguage(item.value1, item.value2)),
      value: item.key1,
    }))
    const dispatchTypeDetailId = DPTD_SUBADMIN.includes(
      travelerInfoRes.dispatchTypeDetailId,
    )
      ? [travelerInfoRes.dispatchTypeDetailId]
      : []
    const _dispatchType = optionItem.filter((item) =>
      [...DPTD_OTHERS_STAFF_NUMBER, ...dispatchTypeDetailId].includes(
        String(item.value),
      ),
    )
    setDispatchType(_dispatchType)
    getFlightInfo(travelerId)
    // APIを呼び出し、都市一覧情報を取得する。
    countriesApi
      .getCitiesByCountry(workPlaceCountry)
      .then((res: CitiesResType[]) => {
        const data = res.map((item) => ({
          text: item.cityDistrict,
          value: item.cityCode,
        }))
        setDefaultCountries([...data, OTHER_CITY_SELECT_ITEMS])
      })
      .catch((error: AxiosErrorType) => {
        handleError(error)
      })

    const purposeTravelList = getPurposeTravelList({
      dispatchTypeDetailId: travelerInfoRes?.dispatchTypeDetailId,
      dispatchTypeDetailRes,
      purposeTravelRes,
      workPlaceLocation,
    })

    return {
      purposeTravelList,
    }
  }

  const handleGetScheduleDraft = () => {
    const getDispatchTypeDetailListAPI: Promise<GeneralMastersResType[]> =
      generalMastersApi.getGeneralMaster(DPTD)
    // APIを呼び出し、渡航目的一覧情報を取得し
    const getPurposeTravelListAPI: Promise<GeneralMastersResType[]> =
      generalMastersApi.getGeneralMaster(PPTV)
    // APIを呼び出し、部門一覧情報を取得し
    const getAllDepartments: Promise<DepartmentsResType[]> =
      departmentsApi.getAllDepartments()
    Promise.all([
      getDispatchTypeDetailListAPI,
      getPurposeTravelListAPI,
      getAllDepartments,
    ])
      .then(async (res) => {
        const optionItemDepartment: OptionItemType[] = res[2].map((item) => ({
          text: String(
            getValueByLanguage(
              item.departmentNameJapan,
              item.departmentNameEnglish,
            ),
          ),
          value: item.bumonCd,
        }))

        setDepartments(optionItemDepartment)

        setDispatchTypeDetailRes(res[0])
        setPurposeTravelRes(res[1])
        scheduleApi
          .getScheduleDraft(id)
          .then(async (scheduleRes: ScheduleDataType) => {
            const _travelerId = scheduleRes.info?.travelerId
            setTravelerId(_travelerId)

            // APIを呼び出し、渡航者の基本情報を取得し
            travelerApi
              .getTravelerBase(_travelerId)
              .then((travelerInfoRes: TravelerInfoResponseType) => {
                const travelerInfo = getTravelerInfo(travelerInfoRes)
                form.setFieldsValue(travelerInfo)
                // APIを呼び出し、データベースから渡航者情報を取得する。
                travelerApi
                  .getTravelerDetails(travelerInfoRes.travelerId)
                  .then((travelerDetailsRes: TravelersDetailResType) => {
                    const { purposeTravelList } = handleDataTravelerBase({
                      dispatchTypeDetailRes: res[0],
                      purposeTravelRes: res[1],
                      travelerDetailsRes,
                      travelerId: _travelerId,
                      travelerInfoRes,
                    })

                    if (purposeTravelList) {
                      const initialValues = getInitialValues(
                        scheduleRes.info,
                        purposeTravelList,
                        optionItemDepartment,
                      )
                      handleCheckHiredLocally(initialValues.travelPurposeId)
                      form.setFieldsValue(initialValues)
                    }
                    if (
                      travelerInfoRes.dispatchTypeDetailId !==
                        scheduleRes.info.travelerDispatchTypeDetailId &&
                      scheduleRes.info.travelerDispatchTypeDetailId
                    )
                      onOpenPopup()
                  })
                  .catch((error: AxiosErrorType) => {
                    handleError(error)
                  })
              })
              .catch((error: AxiosErrorType) => {
                handleError(error)
              })
            handleCheckRequiredDispatchDate(scheduleRes.info.travelPurposeId)

            setDetailDataSource([])
            setLodgingDataSource([])
            setStayDataSource([])
            setSchedulesData({
              ...scheduleRes,
              details: scheduleRes.details.map((item, index) => ({
                ...item,
                arrivalCityNameOther:
                  item.arrivalCityCode === OTHER ? item.arrivalCityName : "",
                departureCityNameOther:
                  item.departureCityCode === OTHER
                    ? item.departureCityName
                    : "",
                index: index,
              })),
              lodgings: scheduleRes.lodgings.map((item, index) => ({
                ...item,
                index: index,
              })),
              stays: scheduleRes.stays.map((item, index) => ({
                ...item,
                index: index,
              })),
            })
            setIsRequiredItemTableLodgings(
              !PPTV_SCR212.includes(scheduleRes?.info?.travelPurposeId),
            )
            if (scheduleRes.info?.differentInforCheck)
              setIsDifferentInfoChecked(true)
            setTimeout(() => {
              if (!scheduleRes.details.length) handleAddDetailItem()
              if (!scheduleRes.lodgings.length) handleAddLodgingItem()
              if (!scheduleRes.stays.length) handleAddStayItem()
            }, 100)
          })
          .catch((error: AxiosErrorType) => {
            handleError(error)
          })
      })
      .catch((error: AxiosErrorType) => {
        handleError(error)
      })
      .finally(() => {
        setIsLoadingLayout(false)
      })
  }

  const getData = () => {
    if (state?.isTemporarilySchedule) {
      handleGetScheduleDraft()
    } else {
      const getDispatchTypeDetailListAPI: Promise<GeneralMastersResType[]> =
        generalMastersApi.getGeneralMaster(DPTD)
      // APIを呼び出し、渡航目的一覧情報を取得し
      const getPurposeTravelListAPI: Promise<GeneralMastersResType[]> =
        generalMastersApi.getGeneralMaster(PPTV)
      // APIを呼び出し、部門一覧情報を取得し
      const getAllDepartments: Promise<DepartmentsResType[]> =
        departmentsApi.getAllDepartments()
      Promise.all([
        getDispatchTypeDetailListAPI,
        getPurposeTravelListAPI,
        getAllDepartments,
      ])
        .then(async (res) => {
          const optionItemDepartment: OptionItemType[] = res[2].map((item) => ({
            text: String(
              getValueByLanguage(
                item.departmentNameJapan,
                item.departmentNameEnglish,
              ),
            ),
            value: item.bumonCd,
          }))

          setDepartments(optionItemDepartment)

          setDispatchTypeDetailRes(res[0])
          setPurposeTravelRes(res[1])

          setTravelerId(id)

          // APIを呼び出し、渡航者の基本情報を取得し
          travelerApi
            .getTravelerBase(id)
            .then((travelerInfoRes: TravelerInfoResponseType) => {
              setTravelerBase(travelerInfoRes)
              const travelerInfo = getTravelerInfo(travelerInfoRes)
              form.setFieldsValue(travelerInfo)
              // APIを呼び出し、データベースから渡航者情報を取得する。
              travelerApi
                .getTravelerDetails(travelerInfoRes.travelerId)
                .then((travelerDetailsRes: TravelersDetailResType) => {
                  setTravelerDetail(travelerDetailsRes.detail)
                  const { purposeTravelList } = handleDataTravelerBase({
                    dispatchTypeDetailRes: res[0],
                    purposeTravelRes: res[1],
                    travelerDetailsRes,
                    travelerId: id,
                    travelerInfoRes,
                  })
                  form.setFieldsValue({
                    detailSortType: schedulesData.info.detailSortType,
                    travelerBumonCd: travelerDetailsRes.base.bumonCd,
                    travelerDispatchTypeDetailId:
                      travelerDetailsRes.base.dispatchTypeDetailId,
                  })

                  if (
                    state?.isCloneSchedule &&
                    state?.scheduleData &&
                    purposeTravelList
                  ) {
                    const _scheduleData = state.scheduleData
                    const initialValues = getInitialValues(
                      _scheduleData.info,
                      purposeTravelList,
                      optionItemDepartment,
                    )
                    form.setFieldsValue(initialValues)
                    handleCheckRequiredDispatchDate(
                      _scheduleData.info.travelPurposeId,
                    )

                    setSchedulesData(_scheduleData)
                    onValidateChangeDate()
                    setIsRequiredItemTableLodgings(
                      !PPTV_SCR212.includes(
                        _scheduleData?.info?.travelPurposeId,
                      ),
                    )
                    if (_scheduleData.info?.differentInforCheck)
                      setIsDifferentInfoChecked(true)

                    setTimeout(() => {
                      if (!_scheduleData.details.length) handleAddDetailItem()
                      if (!_scheduleData.lodgings.length) handleAddLodgingItem()
                      if (!_scheduleData.stays.length) handleAddStayItem()
                    }, 100)
                  } else {
                    handleAddDetailItem()
                    handleAddLodgingItem()
                    handleAddStayItem()
                  }
                })
                .catch((error: AxiosErrorType) => {
                  handleError(error)
                })
            })
            .catch((error: AxiosErrorType) => {
              handleError(error)
            })
          handleCheckRequiredDispatchDate()
        })
        .catch((error: AxiosErrorType) => {
          handleError(error)
        })
        .finally(() => {
          setIsLoadingLayout(false)
        })
    }
  }

  const onOkScheduleDraft = async () => {
    const data: ScheduleDataAddType = await getScheduleDataAdd({
      allCountries,
      dataSubmit: form.getFieldsValue(),
      departments,
      detailDataSource,
      detailIndexIdentity,
      id,
      isFromSCR114UserSearch: state?.isFromSCR114UserSearch,
      isHiredLocally,
      isOtherStaffNumber,
      isSchedulesAdd: false,
      isSchedulesEdit,
      lodgingIndexIdentity,
      purposeTravelList,
      schedulesData,
      stayIndexIdentity,
      transportationList,
      travelerBase,
      travelerDetail,
      travelerId,
    })
    setIsLoading(true)
    scheduleApi
      .addScheduleDraft(id, data)
      .then(() => {
        onCancelScheduleDraft()
        setIsLoading(false)
        openNotification(MESSAGE_I0026)
      })
      .catch((error: AxiosErrorType) => {
        handleError(error)
      })
  }

  const onOkExportScheduleDraft = async () => {
    const data: ScheduleDataAddType = await getScheduleDataAdd({
      allCountries,
      dataSubmit: form.getFieldsValue(),
      departments,
      detailDataSource,
      detailIndexIdentity,
      id,
      isFromSCR114UserSearch: state?.isFromSCR114UserSearch,
      isHiredLocally,
      isOtherStaffNumber,
      isSchedulesAdd: false,
      isSchedulesEdit,
      lodgingIndexIdentity,
      purposeTravelList,
      schedulesData,
      stayIndexIdentity,
      transportationList,
      travelerBase,
      travelerDetail,
      travelerId,
    })
    setIsLoading(true)
    scheduleApi
      .addScheduleDraft(id, data)
      .then(() => {
        scheduleApi
          .getScheduleDraftExport(id)
          .then((scheduleRes: SchedulesListDataType) => {
            exportFRM101([scheduleRes])
          })
          .catch((error: AxiosErrorType) => {
            handleError(error)
          })
        setIsLoading(false)
      })
      .catch((error: AxiosErrorType) => {
        handleError(error)
      })
  }

  useEffect(() => {
    onValuesChange()
  })

  useEffect(() => {
    if (scheduleDetails.length) setDataScheduleDetails()
  }, [scheduleDetails])

  useEffect(() => {
    if (scheduleLodgings.length) setDataScheduleLodgings()
  }, [scheduleLodgings])

  useEffect(() => {
    if (scheduleStays.length) setDataScheduleStays()
  }, [scheduleStays])

  useEffect(() => {
    const timeId = setTimeout(() => {
      handleSetDetailRequiredFields()
      handleSetLodgingRequiredFields()
      handleSetStayRequiredFields()
      onValidateChangeDate()
    }, 100)

    return () => clearTimeout(timeId)
  }, [isHiredLocally])

  useEffect(() => {
    const timeId = setTimeout(() => {
      handleSetDetailRequiredFields()
      onValidateChangeDate()
    }, 100)

    return () => clearTimeout(timeId)
  }, [detailDataSource])

  useEffect(() => {
    const timeId = setTimeout(() => {
      handleSetLodgingRequiredFields()
    }, 100)

    return () => clearTimeout(timeId)
  }, [
    isRequiredItemTableByDate,
    isRequiredItemTableByPPTV,
    isRequiredItemTableLodgings,
    lodgingDataSource,
  ])

  useEffect(() => {
    const timeId = setTimeout(() => {
      handleSetStayRequiredFields()
    }, 100)

    return () => clearTimeout(timeId)
  }, [stayDataSource])

  useEffect(() => {
    if (schedulesData.info.travelPurposeId) {
      if (
        listPPTVIsValue3Equal1.length &&
        listPPTVIsValue3Equal1.includes(schedulesData.info.travelPurposeId)
      ) {
        if (!form.getFieldValue("bumonCd")) {
          form.setFieldValue("bumonCd", travelerBumonCd)
        }
        setIsActiveBumon(true)
      }

      if (
        listPPTVIsValue4Equal2.length &&
        listPPTVIsValue4Equal2.includes(schedulesData.info.travelPurposeId)
      )
        setIsRequiredItemTableByPPTV(false)
    }
  }, [
    schedulesData.info.travelPurposeId,
    listPPTVIsValue3Equal1,
    listPPTVIsValue4Equal2,
  ])

  useEffect(() => {
    // ユーザーの画面アクセス許可チェックを処理する。
    if (isNotAccessScreen) {
      // 「SCR973_エラー情報の表示画面」に遷移する。
      const { pathname, state } = backToLogin()
      push({ pathname, state })
    } else {
      getData()
    }
  }, [])

  return {
    assignmentValidMsg,
    breadcrumbItems,
    defaultActiveKey,
    departments,
    detailColumnsFirst,
    detailColumnsSecond,
    detailDataSource,
    detailIndexTabActive,
    detailRowSelection,
    detailSelectedRowKeys,
    detailTabsItems,
    detailValidMsg,
    dispatchType,
    form,
    goBack,
    goToSchedulesClone,
    handleAddDetailItem,
    handleAddLodgingItem,
    handleAddStayItem,
    handleDeleteDetailItem,
    handleDeleteLodgingItem,
    handleDeleteStayItem,
    handleGetScheduleDraft,
    isAccompanyingFamily,
    isActiveBumon,
    isDifferentInfoChecked,
    isDisabledSubmit,
    isHiddenBtnScheduleDraft,
    isHiredLocally,
    isInsuranceAndUrgent,
    isInsuranceRequired,
    isLoading,
    isLoadingLayout,
    isOpen,
    isOpenExportScheduleDraft,
    isOpenPopup,
    isOpenRoundTrip,
    isOpenScheduleDraft,
    isOtherStaffNumber,
    isRequiredDispatchDate,
    isRoleAdmin,
    isSchedulesAdd,
    isSchedulesEdit,
    isSubmitValidate,
    isTwoWay,
    isValidForm,
    isVisibleBtn,
    isVisibleBtnCancel,
    isVisibleBtnConfirm,
    isVisibleBtnCopy,
    isVisibleOtherRemarks,
    lodgingColumns,
    lodgingDataSource,
    lodgingIndexTabActive,
    lodgingRowSelection,
    lodgingSelectedRowKeys,
    lodgingTabsItems,
    lodgingValidMsg,
    onBlur,
    onCancel,
    onCancelExportScheduleDraft,
    onCancelPopup,
    onCancelRoundTrip,
    onCancelScheduleDraft,
    onChangeCollapse,
    onChangeDate,
    onChangeDifferentInfo,
    onChangeTravelPurpose,
    onClickDetailTab,
    onClickLodgingTab,
    onClickStayTab,
    onDispatchTypeDetailChange,
    onFinish,
    onKeyDown,
    onOk,
    onOkExportScheduleDraft,
    onOkRoundTrip,
    onOkScheduleDraft,
    onOpenExportScheduleDraft,
    onOpenScheduleDraft,
    onValuesChange,
    purposeTravelList,
    stayColumns,
    stayDataSource,
    stayIndexTabActive,
    stayRowSelection,
    staySelectedRowKeys,
    stayTabsItems,
  }
}
