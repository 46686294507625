import styled, { css } from "styled-components"
import { ItemInput } from "../../../../../../../../../_webui/layout/components/Form/Item/Input"
import { Colors } from "../../../../../../../../../constant/Styled/Colors"
import { Regex } from "../../../../../../../../../constant/Regex"

const { FORM_ENTRY_BORDER } = Colors
const { REGEX_IS_DESKTOP } = Regex

const StyledItemInput = styled(ItemInput)`
  .ant-form-item-control-input-content {
    .ant {
      &-form-item {
        margin: 0;
        &-control {
          max-width: 100%;
        }
      }
      &-input {
        border: 1px solid ${FORM_ENTRY_BORDER};
        height: 40px;
        padding-left: 11px;
        &-affix-wrapper {
          flex: 1;
          width: 100%;
        }
        &-group {
          column-gap: 10px;
          &-addon {
            flex: 1;
            width: 100%;
          }
          &-wrapper {
            border: none;
            padding: 0;
          }
        }
      }
    }
  }
  ${!REGEX_IS_DESKTOP &&
  css`
    .ant-form-item-control-input-content {
      .ant {
        &-input {
          &-affix-wrapper {
            display: block;
          }
          &-group {
            &-addon {
              display: block;
              margin-bottom: 25px;
            }
            &-wrapper {
              height: initial;
            }
          }
        }
      }
    }
  `}
`

export const Styled = {
  StyledItemInput,
}
