/* eslint-disable max-lines */
import { FC } from "react"
import { Form } from "../../../../../../_webui/layout/components/Form"
import { ItemRadio } from "../../../../../../_webui/layout/components/Form/Item/Radio"
import { validatorRequiredField } from "../../../../../../utils/validator/requiredField"
import { ItemInput } from "../../../../../../_webui/layout/components/Form/Item/Input"
import { validatorEmailAddress } from "../../../../../../utils/validator/emailAddress"
import { validatorHalfSize } from "../../../../../../utils/validator/halfSize"
import { Styled } from "./styled"
import { Translate } from "../../../../../../constant/Translate"
import { ContactButtonGroup } from "../ButtonGroup"
import { ContactFormDataType, ContactFormProps } from "../../../models/contact"

const { StyledItemTextArea } = Styled
const {
  TEXT_T009,
  TEXT_T011,
  TEXT_T065,
  TEXT_T329,
  TEXT_T442,
  TEXT_T467,
  TEXT_T469,
  TEXT_T470,
  TEXT_T475,
  TEXT_T528,
  TEXT_T676,
  TEXT_T677,
  TEXT_T678,
  TEXT_T679,
} = Translate

export const ContactForm: FC<ContactFormProps<ContactFormDataType>> = (
  props,
) => {
  const {
    form,
    goToHome,
    isDisabledSubmit,
    isLoading,
    onFinish,
    onOpenClear,
    onValuesChange,
  } = props

  return (
    <Form
      form={form}
      isHeading={false}
      isLoading={isLoading}
      onFinish={onFinish}
      onValuesChange={onValuesChange}
    >
      <ItemRadio
        label={TEXT_T065}
        name="type"
        radioItems={[
          { text: TEXT_T467, value: 1 },
          { text: TEXT_T475, value: 2 },
          { text: TEXT_T469, value: 3 },
          { text: TEXT_T470, value: 4 },
          { text: TEXT_T442, value: 5 },
          { text: TEXT_T528, value: 6 },
        ]}
        required
        rules={[validatorRequiredField]}
      />
      <ItemInput
        label={TEXT_T676}
        maxLength={100}
        name="name"
        required
        rules={[validatorRequiredField]}
      />
      <ItemInput label={TEXT_T677} maxLength={100} name="companyName" />
      <ItemInput label={TEXT_T009} maxLength={100} name="department" />
      <ItemInput label={TEXT_T678} maxLength={200} name="address" />
      <ItemInput
        label={TEXT_T329}
        maxLength={20}
        name="phoneNumber"
        rules={[validatorHalfSize]}
      />
      <ItemInput
        label={TEXT_T011}
        maxLength={255}
        name="emailAddress"
        required
        rules={[validatorRequiredField, validatorEmailAddress]}
      />
      <StyledItemTextArea
        className="contact-text-area"
        label={TEXT_T679}
        maxLength={500}
        name="content"
        required
        rows={7}
        rules={[validatorRequiredField]}
      />
      <ContactButtonGroup
        goToHome={goToHome}
        isDisabledSubmit={isDisabledSubmit}
        onOpenClear={onOpenClear}
      />
    </Form>
  )
}
