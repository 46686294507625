/* eslint-disable complexity */
import Excel from "exceljs"

export const cellConfig = (worksheet: Excel.Worksheet) => {
  worksheet.eachRow((sheetColumn, rowNumber) => {
    sheetColumn.eachCell((cell, colNumber) => {
      worksheet.getCell("B1").font = {
        bold: true,
        size: 14,
      }
      if (rowNumber > 1) {
        cell.style = {
          alignment: {
            horizontal: rowNumber >= 5 && colNumber !== 1 ? "left" : "center",
            vertical: "middle",
            wrapText: rowNumber !== 5,
          },
        }
        cell.border = {
          bottom: {
            color: {
              argb: "000000",
            },
            style: "thin",
          },
          left: {
            color: {
              argb: "000000",
            },
            style: "thin",
          },
          right: {
            color: {
              argb: "000000",
            },
            style: "thin",
          },
          top: {
            color: {
              argb: "000000",
            },
            style: "thin",
          },
        }
      }
      if ((rowNumber === 4 && colNumber !== 1) || rowNumber === 5) {
        cell.fill = {
          fgColor: { argb: "E2EFDA" },
          pattern: "solid",
          type: "pattern",
        }
      }
    })
  })
  worksheet.mergeCells("B4:O4")
  worksheet.mergeCells("P4:BE4")
  worksheet.mergeCells("BF4:BI4")
  worksheet.mergeCells("BJ4:BM4")
}
