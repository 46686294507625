/* eslint-disable max-lines */
/* eslint-disable complexity */
/* eslint-disable max-statements */
import { GetScheduleDataTableAddProps } from "../../../../models/add"
import {
  SchedulesAddDetailsAddType,
  SchedulesAddLodgingsAddType,
  SchedulesAddStaysAddType,
} from "../../../../models/add/DataAdd"
import { getItemDataTable } from "./getItemDataTable"
import { getSchedulesAddDetailsSubmit } from "./getDetailsSubmit"
import { getSchedulesAddLodgingsSubmit } from "./getLodgingsSubmit"
import { getSchedulesAddStaysSubmit } from "./getStaysSubmit"
import { getSubmitData } from "./getSubmitData"
import { travelerApi } from "../../../../../travelers/apis"
import { getUserInformation } from "../../../../../../../utils/getUserInformation"
import { TravelersDetailResType } from "../../../../../travelers/models/detail"
import { Variables } from "../../../../constants/add/variables"
import { formatDateSaveDB } from "../../../../../../../utils/getDate"
import { getValueComboboxTable } from "../../../../../../../utils/getValueComboboxTable"
import { getTextByKey } from "../../../../../../../utils/getTextByKey"
import { GeneralCode } from "../../../../../../../constant/GeneralCode"
import { SelectItems } from "../../../../../../../constant/SelectItems"

const { OTHER } = GeneralCode
const { OTHER_CITY_SELECT_ITEMS } = SelectItems
const { SCHEDULES_DETAIL_ROW_PREFIX, TPTT_OTHER } = Variables

export const getScheduleDataTableAdd = async (
  props: GetScheduleDataTableAddProps,
) => {
  const {
    allCountries,
    dataSubmit,
    detailDataSource,
    detailIndexIdentity,
    id,
    isHiredLocally,
    isSchedulesAdd,
    isSchedulesEdit,
    lodgingIndexIdentity,
    schedulesData,
    stayIndexIdentity,
    transportationList,
  } = props
  const userInformation = getUserInformation()

  const dataSubmitEntries = Object.entries(dataSubmit)

  const schedulesAddDetailsSubmit: SchedulesAddDetailsAddType[] = []
  const schedulesAddLodgingsSubmit: SchedulesAddLodgingsAddType[] = []
  const schedulesAddStaysSubmit: SchedulesAddStaysAddType[] = []

  if (isHiredLocally) {
    if (userInformation?.travelerId) {
      await travelerApi
        .getTravelerDetails(userInformation.travelerId)
        .then((res: TravelersDetailResType) => {
          const {
            workPlace,
            workPlaceCity,
            workPlaceCityName,
            workPlaceCountry,
            workPlaceCountryName,
          } = res.detail
          const _workPlaceCity = getValueComboboxTable(
            workPlaceCity,
            workPlaceCity === OTHER
              ? String(OTHER_CITY_SELECT_ITEMS.text)
              : workPlaceCityName,
            0,
            SCHEDULES_DETAIL_ROW_PREFIX,
          )
          const _workPlaceCountry = getValueComboboxTable(
            workPlaceCountry,
            workPlaceCountryName,
            0,
            SCHEDULES_DETAIL_ROW_PREFIX,
          )
          const _workPlaceCityName = workPlaceCityName || workPlace
          const transportation = getValueComboboxTable(
            TPTT_OTHER,
            getTextByKey(TPTT_OTHER, transportationList),
            0,
            SCHEDULES_DETAIL_ROW_PREFIX,
          )
          schedulesAddDetailsSubmit.push({
            arrivalCityCode: _workPlaceCity,
            arrivalCityName: _workPlaceCityName,
            arrivalCityNameOther: workPlace,
            arrivalCountryCode: _workPlaceCountry,
            arrivalDateTime: formatDateSaveDB(
              dataSubmit.dispatchStartDate,
              true,
            ),
            departureCityCode: _workPlaceCity,
            departureCityName: _workPlaceCityName,
            departureCityNameOther: workPlace,
            departureCountryCode: _workPlaceCountry,
            departureDateTime: formatDateSaveDB(dataSubmit.dispatchStartDate),
            departureFlightNumber: "",
            index: 0,
            isTransit: false,
            othersRemarks1: "",
            othersRemarks2: "",
            othersRemarks3: "",
            othersRemarks4: "",
            othersRemarks5: "",
            transportation,
            travelDetailId: "",
            travelScheduleId: "",
            type: "Add",
          })
        })
    }
  } else {
    for (let index = 0; index < detailIndexIdentity; index++) {
      const item = getItemDataTable(
        dataSubmitEntries,
        index,
        "schedulesAddDetail",
      )

      const tempItem = {
        lodgingAddress: item.lodgingAddress,
        lodgingCheckin: item.lodgingCheckin,
        lodgingCheckout: item.lodgingCheckout,
        lodgingName: item.lodgingName,
        lodgingPhoneNumber: item.lodgingPhoneNumber,
      }
      const checkItem = Object.values(tempItem).filter(
        (value) => value !== "",
      ).length

      if (checkItem && Object.keys(item).length)
        schedulesAddDetailsSubmit.push(
          getSchedulesAddDetailsSubmit({
            allCountries,
            detailDataSource,
            id,
            isSchedulesAdd,
            isSchedulesEdit,
            item,
            transportationList,
          }),
        )
    }
  }

  for (let index = 0; index < lodgingIndexIdentity; index++) {
    const item = getItemDataTable(
      dataSubmitEntries,
      index,
      "schedulesAddLodging",
    )

    if (Object.keys(item).length)
      schedulesAddLodgingsSubmit.push(
        getSchedulesAddLodgingsSubmit({
          id,
          isSchedulesAdd,
          isSchedulesEdit,
          item,
        }),
      )
  }

  for (let index = 0; index < stayIndexIdentity; index++) {
    const item = getItemDataTable(dataSubmitEntries, index, "schedulesAddStay")

    if (Object.keys(item).length)
      schedulesAddStaysSubmit.push(
        getSchedulesAddStaysSubmit({
          id,
          isSchedulesAdd,
          isSchedulesEdit,
          item,
        }),
      )
  }

  if (isSchedulesEdit) {
    const { detailDeleteDataNew, lodgingDeleteDataNew, stayDeleteDataNew } =
      getSubmitData({
        id,
        isHiredLocally,
        schedulesAddDetailsSubmit,
        schedulesAddLodgingsSubmit,
        schedulesAddStaysSubmit,
        schedulesData,
      })

    schedulesAddDetailsSubmit.push(...detailDeleteDataNew)

    schedulesAddLodgingsSubmit.push(...lodgingDeleteDataNew)

    schedulesAddStaysSubmit.push(...stayDeleteDataNew)
  }

  const _dataLodgings: (SchedulesAddLodgingsAddType | null)[] =
    schedulesAddLodgingsSubmit.map((item) => {
      const tempItem = {
        lodgingAddress: item.lodgingAddress,
        lodgingCheckin: item.lodgingCheckin,
        lodgingCheckout: item.lodgingCheckout,
        lodgingName: item.lodgingName,
        lodgingPhoneNumber: item.lodgingPhoneNumber,
      }
      const checkItem = Object.values(tempItem).filter(
        (value) => value !== "",
      ).length
      if (item.travelLodgingId) {
        return {
          ...item,
          type: checkItem ? item.type : "Delete",
        }
      }
      return checkItem ? item : null
    })

  const _dataLodgingsFilter: SchedulesAddLodgingsAddType[] = []
  for (let index = 0; index < _dataLodgings.length; index++) {
    const dataItem = _dataLodgings[index]
    if (typeof dataItem !== null && dataItem !== null) {
      _dataLodgingsFilter.push(dataItem)
    }
  }

  const _dataStays: (SchedulesAddStaysAddType | null)[] =
    schedulesAddStaysSubmit.map((item) => {
      const tempItem = {
        emailAddress1: item.emailAddress1,
        emailAddress2: item.emailAddress2,
        locationPhoneNumber1: item.locationPhoneNumber1,
        locationPhoneNumber2: item.locationPhoneNumber2,
        satellitePhoneNumber: item.satellitePhoneNumber,
      }
      const checkItem = Object.values(tempItem).filter(
        (value) => value !== "",
      ).length
      if (item.travelStayId) {
        return {
          ...item,
          type: checkItem ? item.type : "Delete",
        }
      }
      return checkItem ? item : null
    })

  const _dataStaysFilter: SchedulesAddStaysAddType[] = []
  for (let index = 0; index < _dataStays.length; index++) {
    const dataItem = _dataStays[index]
    if (typeof dataItem !== null && dataItem !== null) {
      _dataStaysFilter.push(dataItem)
    }
  }

  return {
    schedulesAddDetailsSubmit,
    schedulesAddLodgingsSubmit: _dataLodgingsFilter,
    schedulesAddStaysSubmit: _dataStaysFilter,
  }
}
