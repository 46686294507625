/* eslint-disable max-lines */
/* eslint-disable max-statements */
import Excel from "exceljs"
import { Translate } from "../../../../../../../../../constant/Translate"

const {
  EXPORT_EP0010,
  EXPORT_EP0011,
  EXPORT_EP0012,
  EXPORT_EP0017,
  EXPORT_EP0022,
  EXPORT_EP0026,
  EXPORT_EP0035,
  EXPORT_EP0036,
  EXPORT_EP0037,
  EXPORT_EP0045,
  EXPORT_EP0046,
  EXPORT_EP0058,
  EXPORT_EP0063,
  EXPORT_EP0065,
  EXPORT_EP0067,
  EXPORT_EP0068,
  EXPORT_EP0069,
  EXPORT_EP0077,
  EXPORT_EP0078,
  EXPORT_EP0087,
  EXPORT_EP0088,
  EXPORT_EP0091,
  EXPORT_EP0092,
  EXPORT_EP0101,
  EXPORT_EP0102,
  EXPORT_EP0120,
  EXPORT_EP0122,
  EXPORT_EP0123,
  EXPORT_EP0124,
  EXPORT_EP0128,
  EXPORT_EP0129,
  EXPORT_EP0130,
  EXPORT_EP0131,
  EXPORT_EP0132,
  EXPORT_EP0134,
  EXPORT_EP0135,
  EXPORT_EP0137,
  EXPORT_EP0146,
  EXPORT_EP0180,
  EXPORT_EP0188,
  EXPORT_EP0192,
  EXPORT_EP0201,
  EXPORT_EP0212,
  EXPORT_EP0213,
  EXPORT_EP0214,
  EXPORT_EP0215,
  EXPORT_EP0216,
  EXPORT_EP0217,
  EXPORT_EP0218,
  EXPORT_EP0219,
  EXPORT_EP0220,
  EXPORT_EP0221,
  EXPORT_EP0222,
  EXPORT_EP0223,
  EXPORT_EP0224,
  EXPORT_EP0225,
  EXPORT_EP0226,
  EXPORT_EP0227,
  EXPORT_EP0228,
  EXPORT_EP0229,
  EXPORT_EP0230,
  EXPORT_EP0231,
  EXPORT_EP0232,
  EXPORT_EP0233,
  EXPORT_EP0234,
} = Translate

export const columnsConfig = (worksheet: Excel.Worksheet) => {
  worksheet.columns = [
    { width: 8 },
    { width: 30 },
    { width: 30 },
    { width: 30 },
    { width: 30 },
    { width: 30 },
    { width: 30 },
    { width: 30 },
    { width: 30 },
    { width: 30 },
    { width: 30 },
    { width: 30 },
    { width: 30 },
    { width: 30 },
    { width: 30 },
    { width: 30 },
    { width: 30 },
    { width: 30 },
    { width: 30 },
    { width: 30 },
    { width: 30 },
    { width: 30 },
    { width: 30 },
    { width: 30 },
    { width: 30 },
    { width: 30 },
    { width: 30 },
    { width: 30 },
    { width: 30 },
    { width: 30 },
    { width: 30 },
    { width: 30 },
    { width: 30 },
    { width: 30 },
    { width: 30 },
    { width: 30 },
    { width: 30 },
    { width: 30 },
    { width: 30 },
    { width: 30 },
    { width: 30 },
    { width: 30 },
    { width: 30 },
    { width: 30 },
    { width: 30 },
    { width: 30 },
    { width: 30 },
    { width: 30 },
    { width: 30 },
    { width: 30 },
    { width: 30 },
    { width: 30 },
    { width: 30 },
    { width: 30 },
    { width: 30 },
    { width: 30 },
    { width: 30 },
    { width: 30 },
    { width: 30 },
    { width: 30 },
    { width: 60 },
    { width: 30 },
    { width: 30 },
    { width: 30 },
    { width: 60 },
  ]
  worksheet.getCell("A4").value = ""
  worksheet.getCell("B4").value = EXPORT_EP0192
  worksheet.getCell("P4").value = EXPORT_EP0188
  worksheet.getCell("BF4").value = EXPORT_EP0212
  worksheet.getCell("BJ4").value = EXPORT_EP0213
  worksheet.getCell("A5").value = EXPORT_EP0214
  worksheet.getCell("B5").value = EXPORT_EP0017
  worksheet.getCell("C5").value = EXPORT_EP0091
  worksheet.getCell("D5").value = EXPORT_EP0092
  worksheet.getCell("E5").value = EXPORT_EP0087
  worksheet.getCell("F5").value = EXPORT_EP0088
  worksheet.getCell("G5").value = EXPORT_EP0215
  worksheet.getCell("H5").value = EXPORT_EP0216
  worksheet.getCell("I5").value = EXPORT_EP0217
  worksheet.getCell("J5").value = EXPORT_EP0218
  worksheet.getCell("K5").value = EXPORT_EP0026
  worksheet.getCell("L5").value = EXPORT_EP0122
  worksheet.getCell("M5").value = EXPORT_EP0123
  worksheet.getCell("N5").value = EXPORT_EP0180
  worksheet.getCell("O5").value = EXPORT_EP0078
  worksheet.getCell("P5").value = EXPORT_EP0069
  worksheet.getCell("Q5").value = EXPORT_EP0068
  worksheet.getCell("R5").value = EXPORT_EP0067
  worksheet.getCell("S5").value = EXPORT_EP0058
  worksheet.getCell("T5").value = EXPORT_EP0035
  worksheet.getCell("U5").value = EXPORT_EP0120
  worksheet.getCell("V5").value = EXPORT_EP0124
  worksheet.getCell("W5").value = EXPORT_EP0201
  worksheet.getCell("X5").value = EXPORT_EP0219
  worksheet.getCell("Y5").value = EXPORT_EP0220
  worksheet.getCell("Z5").value = EXPORT_EP0221
  worksheet.getCell("AA5").value = EXPORT_EP0222
  worksheet.getCell("AB5").value = EXPORT_EP0223
  worksheet.getCell("AC5").value = EXPORT_EP0224
  worksheet.getCell("AD5").value = EXPORT_EP0225
  worksheet.getCell("AE5").value = EXPORT_EP0226
  worksheet.getCell("AF5").value = EXPORT_EP0045
  worksheet.getCell("AG5").value = EXPORT_EP0077
  worksheet.getCell("AH5").value = EXPORT_EP0011
  worksheet.getCell("AI5").value = EXPORT_EP0010
  worksheet.getCell("AJ5").value = EXPORT_EP0065
  worksheet.getCell("AK5").value = EXPORT_EP0063
  worksheet.getCell("AL5").value = EXPORT_EP0227
  worksheet.getCell("AM5").value = EXPORT_EP0228
  worksheet.getCell("AN5").value = EXPORT_EP0229
  worksheet.getCell("AO5").value = EXPORT_EP0022
  worksheet.getCell("AP5").value = EXPORT_EP0101
  worksheet.getCell("AQ5").value = EXPORT_EP0102
  worksheet.getCell("AR5").value = EXPORT_EP0046
  worksheet.getCell("AS5").value = EXPORT_EP0128
  worksheet.getCell("AT5").value = EXPORT_EP0129
  worksheet.getCell("AU5").value = EXPORT_EP0130
  worksheet.getCell("AV5").value = EXPORT_EP0131
  worksheet.getCell("AW5").value = EXPORT_EP0132
  worksheet.getCell("AX5").value = EXPORT_EP0230
  worksheet.getCell("AY5").value = EXPORT_EP0231
  worksheet.getCell("AZ5").value = EXPORT_EP0232
  worksheet.getCell("BA5").value = EXPORT_EP0233
  worksheet.getCell("BB5").value = EXPORT_EP0234
  worksheet.getCell("BC5").value = EXPORT_EP0012
  worksheet.getCell("BD5").value = EXPORT_EP0037
  worksheet.getCell("BE5").value = EXPORT_EP0146
  worksheet.getCell("BF5").value = EXPORT_EP0135
  worksheet.getCell("BG5").value = EXPORT_EP0134
  worksheet.getCell("BH5").value = EXPORT_EP0137
  worksheet.getCell("BI5").value = EXPORT_EP0036
  worksheet.getCell("BJ5").value = EXPORT_EP0135
  worksheet.getCell("BK5").value = EXPORT_EP0134
  worksheet.getCell("BL5").value = EXPORT_EP0137
  worksheet.getCell("BM5").value = EXPORT_EP0036
}
