import {
  SchedulesSearchConditionAdvancedType,
  SchedulesSearchConditionBasicType,
} from "../../../models/list"

const SCHEDULES_ADVANCED_SEARCH_INITIAL_VALUES: SchedulesSearchConditionAdvancedType =
  {
    arrivalCity: "",
    arrivalCityCode: "",
    arrivalCountryCode: "",
    arrivalDateTimeDate: "",
    arrivalDateTimeHour: "",
    arrivalDateTimeMinutes: "",
    bumonCd: "",
    departureCity: "",
    departureCityCode: "",
    departureCountryCode: "",
    departureDateTimeDate: "",
    departureDateTimeHour: "",
    departureDateTimeMinutes: "",
    departureFlightNumber: "",
    dispatchType: "",
    dispatchTypeDetail: "",
    isTransit: false,
    kanjiName: "",
    kanjiPassportName: "",
    kanjiPassportSurname: "",
    kanjiSurname: "",
    lodgingName: "",
    othersDispatchNumber: "",
    othersMemberNumber: "",
    othersStaffNumber: "",
    personCompanyPosition: "",
    personDispatcherName: "",
    personPrefectureResidence: "",
    procurementControlNumber: "",
    projectNameEn: "",
    projectNameJp: "",
    romajiName: "",
    romajiPassportName: "",
    romajiPassportSurname: "",
    romajiSurname: "",
    transportation: "",
    travelPurposeId: "",
    travelerId: "",
    workPlace: "",
    workPlaceCity: "",
    workPlaceCountry: "",
    workPlaceLocation: "",
  }

const SCHEDULES_BASIC_SEARCH_INITIAL_VALUES: SchedulesSearchConditionBasicType =
  {
    arrivalCity: "",
    arrivalCityCode: "",
    arrivalDateTimeDate: "",
    bumonCd: "",
    countryToTravel: "",
    departureDateTimeDate: "",
    fullName: "",
    isTransit: false,
    projectName: "",
    travelerId: "",
  }

export const InitialValues = {
  SCHEDULES_ADVANCED_SEARCH_INITIAL_VALUES,
  SCHEDULES_BASIC_SEARCH_INITIAL_VALUES,
}
