/* eslint-disable max-lines */
const CODE_MESSAGE_E0001 = "MESSAGE.E0001"
const CODE_MESSAGE_E0002 = "MESSAGE.E0002"
const CODE_MESSAGE_E0003 = "MESSAGE.E0003"
const CODE_MESSAGE_E0004 = "MESSAGE.E0004"
const CODE_MESSAGE_E0005 = "MESSAGE.E0005"
const CODE_MESSAGE_E0006 = "MESSAGE.E0006"
const CODE_MESSAGE_E0007 = "MESSAGE.E0007"
const CODE_MESSAGE_E0008 = "MESSAGE.E0008"
const CODE_MESSAGE_E0009 = "MESSAGE.E0009"
const CODE_MESSAGE_E0010 = "MESSAGE.E0010"
const CODE_MESSAGE_E0011 = "MESSAGE.E0011"
const CODE_MESSAGE_E0012 = "MESSAGE.E0012"
const CODE_MESSAGE_E0013 = "MESSAGE.E0013"
const CODE_MESSAGE_E0014 = "MESSAGE.E0014"
const CODE_MESSAGE_E0015 = "MESSAGE.E0015"
const CODE_MESSAGE_E0016 = "MESSAGE.E0016"
const CODE_MESSAGE_E0017 = "MESSAGE.E0017"
const CODE_MESSAGE_E0018 = "MESSAGE.E0018"
const CODE_MESSAGE_E0019 = "MESSAGE.E0019"
const CODE_MESSAGE_E0020 = "MESSAGE.E0020"
const CODE_MESSAGE_E0021 = "MESSAGE.E0021"
const CODE_MESSAGE_E0022 = "MESSAGE.E0022"
const CODE_MESSAGE_E0023 = "MESSAGE.E0023"
const CODE_MESSAGE_E0024 = "MESSAGE.E0024"
const CODE_MESSAGE_E0025 = "MESSAGE.E0025"
const CODE_MESSAGE_E0026 = "MESSAGE.E0026"
const CODE_MESSAGE_E0027 = "MESSAGE.E0027"
const CODE_MESSAGE_E0028 = "MESSAGE.E0028"
const CODE_MESSAGE_E0029 = "MESSAGE.E0029"
const CODE_MESSAGE_E0030 = "MESSAGE.E0030"
const CODE_MESSAGE_E0031 = "MESSAGE.E0031"
const CODE_MESSAGE_E0032 = "MESSAGE.E0032"
const CODE_MESSAGE_E0033 = "MESSAGE.E0033"
const CODE_MESSAGE_E0034 = "MESSAGE.E0034"
const CODE_MESSAGE_E0035 = "MESSAGE.E0035"
const CODE_MESSAGE_E0036 = "MESSAGE.E0036"
const CODE_MESSAGE_E0037 = "MESSAGE.E0037"
const CODE_MESSAGE_E0038 = "MESSAGE.E0038"
const CODE_MESSAGE_E0039 = "MESSAGE.E0039"
const CODE_MESSAGE_E0040 = "MESSAGE.E0040"
const CODE_MESSAGE_E0041 = "MESSAGE.E0041"
const CODE_MESSAGE_E0042 = "MESSAGE.E0042"
const CODE_MESSAGE_E0043 = "MESSAGE.E0043"
const CODE_MESSAGE_E0045 = "MESSAGE.E0045"
const CODE_MESSAGE_E0046 = "MESSAGE.E0046"
const CODE_MESSAGE_E0047 = "MESSAGE.E0047"
const CODE_MESSAGE_E0048 = "MESSAGE.E0048"
const CODE_MESSAGE_E0049 = "MESSAGE.E0049"
const CODE_MESSAGE_E0050 = "MESSAGE.E0050"
const CODE_MESSAGE_E0051 = "MESSAGE.E0051"
const CODE_MESSAGE_E0052 = "MESSAGE.E0052"
const CODE_MESSAGE_E0053 = "MESSAGE.E0053"
const CODE_MESSAGE_E0054 = "MESSAGE.E0054"
const CODE_MESSAGE_E0055 = "MESSAGE.E0055"
const CODE_MESSAGE_E0056 = "MESSAGE.E0056"
const CODE_MESSAGE_E0057 = "MESSAGE.E0057"
const CODE_MESSAGE_E0058 = "MESSAGE.E0058"
const CODE_MESSAGE_E0059 = "MESSAGE.E0059"
const CODE_MESSAGE_E0060 = "MESSAGE.E0060"
const CODE_MESSAGE_E0061 = "MESSAGE.E0061"
const CODE_MESSAGE_E0062 = "MESSAGE.E0062"
const CODE_MESSAGE_E0063 = "MESSAGE.E0063"
const CODE_MESSAGE_E0064 = "MESSAGE.E0064"
const CODE_MESSAGE_E0065 = "MESSAGE.E0065"
const CODE_MESSAGE_E0066 = "MESSAGE.E0066"
const CODE_MESSAGE_E0067 = "MESSAGE.E0067"
const CODE_MESSAGE_E0068 = "MESSAGE.E0068"
const CODE_MESSAGE_E0069 = "MESSAGE.E0069"
const CODE_MESSAGE_E0070 = "MESSAGE.E0070"
const CODE_MESSAGE_E0071 = "MESSAGE.E0071"
const CODE_MESSAGE_E0072 = "MESSAGE.E0072"
const CODE_MESSAGE_E0073 = "MESSAGE.E0073"
const CODE_MESSAGE_E0074 = "MESSAGE.E0074"
const CODE_MESSAGE_E0075 = "MESSAGE.E0075"
const CODE_MESSAGE_E0076 = "MESSAGE.E0076"
const CODE_MESSAGE_E0077 = "MESSAGE.E0077"
const CODE_MESSAGE_E0078 = "MESSAGE.E0078"
const CODE_MESSAGE_E0079 = "MESSAGE.E0079"
const CODE_MESSAGE_E0080 = "MESSAGE.E0080"
const CODE_MESSAGE_E0081 = "MESSAGE.E0081"
const CODE_MESSAGE_E0082 = "MESSAGE.E0082"
const CODE_MESSAGE_E0083 = "MESSAGE.E0083"
const CODE_MESSAGE_E0084 = "MESSAGE.E0084"
const CODE_MESSAGE_E0085 = "MESSAGE.E0085"
const CODE_MESSAGE_E0086 = "MESSAGE.E0086"
const CODE_MESSAGE_E0087 = "MESSAGE.E0087"
const CODE_MESSAGE_E0088 = "MESSAGE.E0088"
const CODE_MESSAGE_E0089 = "MESSAGE.E0089"
const CODE_MESSAGE_E0090 = "MESSAGE.E0090"
const CODE_MESSAGE_E0091 = "MESSAGE.E0091"
const CODE_MESSAGE_E0092 = "MESSAGE.E0092"
const CODE_MESSAGE_E0093 = "MESSAGE.E0093"
const CODE_MESSAGE_E0094 = "MESSAGE.E0094"
const CODE_MESSAGE_E0095 = "MESSAGE.E0095"
const CODE_MESSAGE_E0096 = "MESSAGE.E0096"
const CODE_MESSAGE_E0097 = "MESSAGE.E0097"
const CODE_MESSAGE_E0098 = "MESSAGE.E0098"
const CODE_MESSAGE_E0099 = "MESSAGE.E0099"
const CODE_MESSAGE_E0100 = "MESSAGE.E0100"
const CODE_MESSAGE_E0101 = "MESSAGE.E0101"
const CODE_MESSAGE_E0102 = "MESSAGE.E0102"
const CODE_MESSAGE_E0103 = "MESSAGE.E0103"
const CODE_MESSAGE_E0104 = "MESSAGE.E0104"
const CODE_MESSAGE_E0105 = "MESSAGE.E0105"
const CODE_MESSAGE_E0106 = "MESSAGE.E0106"
const CODE_MESSAGE_E0107 = "MESSAGE.E0107"
const CODE_MESSAGE_E0108 = "MESSAGE.E0108"
const CODE_MESSAGE_E0109 = "MESSAGE.E0109"
const CODE_MESSAGE_E0110 = "MESSAGE.E0110"
const CODE_MESSAGE_E0111 = "MESSAGE.E0111"
const CODE_MESSAGE_E0112 = "MESSAGE.E0112"
const CODE_MESSAGE_E0113 = "MESSAGE.E0113"
const CODE_MESSAGE_E0114 = "MESSAGE.E0114"
const CODE_MESSAGE_E0115 = "MESSAGE.E0115"
const CODE_MESSAGE_E0116 = "MESSAGE.E0116"
const CODE_MESSAGE_E0117 = "MESSAGE.E0117"
const CODE_MESSAGE_E0118 = "MESSAGE.E0118"
const CODE_MESSAGE_E0119 = "MESSAGE.E0119"
const CODE_MESSAGE_E0120 = "MESSAGE.E0120"
const CODE_MESSAGE_E0121 = "MESSAGE.E0121"
const CODE_MESSAGE_E0122 = "MESSAGE.E0122"
const CODE_MESSAGE_E0123 = "MESSAGE.E0123"
const CODE_MESSAGE_E0124 = "MESSAGE.E0124"
const CODE_MESSAGE_E0125 = "MESSAGE.E0125"
const CODE_MESSAGE_E0126 = "MESSAGE.E0126"
const CODE_MESSAGE_E0127 = "MESSAGE.E0127"
const CODE_MESSAGE_E0128 = "MESSAGE.E0128"
const CODE_MESSAGE_E0129 = "MESSAGE.E0129"
const CODE_MESSAGE_E0130 = "MESSAGE.E0130"
const CODE_MESSAGE_E0131 = "MESSAGE.E0131"
const CODE_MESSAGE_E0132 = "MESSAGE.E0132"
const CODE_MESSAGE_E0133 = "MESSAGE.E0133"
const CODE_MESSAGE_E0134 = "MESSAGE.E0134"
const CODE_MESSAGE_E0135 = "MESSAGE.E0135"
const CODE_MESSAGE_E0136 = "MESSAGE.E0136"
const CODE_MESSAGE_E0137 = "MESSAGE.E0137"
const CODE_MESSAGE_E0138 = "MESSAGE.E0138"
const CODE_MESSAGE_E0139 = "MESSAGE.E0139"
const CODE_MESSAGE_E0140 = "MESSAGE.E0140"
const CODE_MESSAGE_E0141 = "MESSAGE.E0141"
const CODE_MESSAGE_E0142 = "MESSAGE.E0142"
const CODE_MESSAGE_E0143 = "MESSAGE.E0143"
const CODE_MESSAGE_E0144 = "MESSAGE.E0144"
const CODE_MESSAGE_E0145 = "MESSAGE.E0145"
const CODE_MESSAGE_E0146 = "MESSAGE.E0146"
const CODE_MESSAGE_E0147 = "MESSAGE.E0147"
const CODE_MESSAGE_E0148 = "MESSAGE.E0148"
const CODE_MESSAGE_E0149 = "MESSAGE.E0149"
const CODE_MESSAGE_E0150 = "MESSAGE.E0150"
const CODE_MESSAGE_E0151 = "MESSAGE.E0151"
const CODE_MESSAGE_E0152 = "MESSAGE.E0152"
const CODE_MESSAGE_E0153 = "MESSAGE.E0153"
const CODE_MESSAGE_E0156 = "MESSAGE.E0156"
const CODE_MESSAGE_E9996 = "MESSAGE.E9996"
const CODE_MESSAGE_E9997 = "MESSAGE.E9997"
const CODE_MESSAGE_E9998 = "MESSAGE.E9998"
const CODE_MESSAGE_E9999 = "MESSAGE.E9999"
const CODE_MESSAGE_E9999_TITLE = "MESSAGE.E9999.TITLE"

export const I18nCodeMessageError = {
  CODE_MESSAGE_E0001,
  CODE_MESSAGE_E0002,
  CODE_MESSAGE_E0003,
  CODE_MESSAGE_E0004,
  CODE_MESSAGE_E0005,
  CODE_MESSAGE_E0006,
  CODE_MESSAGE_E0007,
  CODE_MESSAGE_E0008,
  CODE_MESSAGE_E0009,
  CODE_MESSAGE_E0010,
  CODE_MESSAGE_E0011,
  CODE_MESSAGE_E0012,
  CODE_MESSAGE_E0013,
  CODE_MESSAGE_E0014,
  CODE_MESSAGE_E0015,
  CODE_MESSAGE_E0016,
  CODE_MESSAGE_E0017,
  CODE_MESSAGE_E0018,
  CODE_MESSAGE_E0019,
  CODE_MESSAGE_E0020,
  CODE_MESSAGE_E0021,
  CODE_MESSAGE_E0022,
  CODE_MESSAGE_E0023,
  CODE_MESSAGE_E0024,
  CODE_MESSAGE_E0025,
  CODE_MESSAGE_E0026,
  CODE_MESSAGE_E0027,
  CODE_MESSAGE_E0028,
  CODE_MESSAGE_E0029,
  CODE_MESSAGE_E0030,
  CODE_MESSAGE_E0031,
  CODE_MESSAGE_E0032,
  CODE_MESSAGE_E0033,
  CODE_MESSAGE_E0034,
  CODE_MESSAGE_E0035,
  CODE_MESSAGE_E0036,
  CODE_MESSAGE_E0037,
  CODE_MESSAGE_E0038,
  CODE_MESSAGE_E0039,
  CODE_MESSAGE_E0040,
  CODE_MESSAGE_E0041,
  CODE_MESSAGE_E0042,
  CODE_MESSAGE_E0043,
  CODE_MESSAGE_E0045,
  CODE_MESSAGE_E0046,
  CODE_MESSAGE_E0047,
  CODE_MESSAGE_E0048,
  CODE_MESSAGE_E0049,
  CODE_MESSAGE_E0050,
  CODE_MESSAGE_E0051,
  CODE_MESSAGE_E0052,
  CODE_MESSAGE_E0053,
  CODE_MESSAGE_E0054,
  CODE_MESSAGE_E0055,
  CODE_MESSAGE_E0056,
  CODE_MESSAGE_E0057,
  CODE_MESSAGE_E0058,
  CODE_MESSAGE_E0059,
  CODE_MESSAGE_E0060,
  CODE_MESSAGE_E0061,
  CODE_MESSAGE_E0062,
  CODE_MESSAGE_E0063,
  CODE_MESSAGE_E0064,
  CODE_MESSAGE_E0065,
  CODE_MESSAGE_E0066,
  CODE_MESSAGE_E0067,
  CODE_MESSAGE_E0068,
  CODE_MESSAGE_E0069,
  CODE_MESSAGE_E0070,
  CODE_MESSAGE_E0071,
  CODE_MESSAGE_E0072,
  CODE_MESSAGE_E0073,
  CODE_MESSAGE_E0074,
  CODE_MESSAGE_E0075,
  CODE_MESSAGE_E0076,
  CODE_MESSAGE_E0077,
  CODE_MESSAGE_E0078,
  CODE_MESSAGE_E0079,
  CODE_MESSAGE_E0080,
  CODE_MESSAGE_E0081,
  CODE_MESSAGE_E0082,
  CODE_MESSAGE_E0083,
  CODE_MESSAGE_E0084,
  CODE_MESSAGE_E0085,
  CODE_MESSAGE_E0086,
  CODE_MESSAGE_E0087,
  CODE_MESSAGE_E0088,
  CODE_MESSAGE_E0089,
  CODE_MESSAGE_E0090,
  CODE_MESSAGE_E0091,
  CODE_MESSAGE_E0092,
  CODE_MESSAGE_E0093,
  CODE_MESSAGE_E0094,
  CODE_MESSAGE_E0095,
  CODE_MESSAGE_E0096,
  CODE_MESSAGE_E0097,
  CODE_MESSAGE_E0098,
  CODE_MESSAGE_E0099,
  CODE_MESSAGE_E0100,
  CODE_MESSAGE_E0101,
  CODE_MESSAGE_E0102,
  CODE_MESSAGE_E0103,
  CODE_MESSAGE_E0104,
  CODE_MESSAGE_E0105,
  CODE_MESSAGE_E0106,
  CODE_MESSAGE_E0107,
  CODE_MESSAGE_E0108,
  CODE_MESSAGE_E0109,
  CODE_MESSAGE_E0110,
  CODE_MESSAGE_E0111,
  CODE_MESSAGE_E0112,
  CODE_MESSAGE_E0113,
  CODE_MESSAGE_E0114,
  CODE_MESSAGE_E0115,
  CODE_MESSAGE_E0116,
  CODE_MESSAGE_E0117,
  CODE_MESSAGE_E0118,
  CODE_MESSAGE_E0119,
  CODE_MESSAGE_E0120,
  CODE_MESSAGE_E0121,
  CODE_MESSAGE_E0122,
  CODE_MESSAGE_E0123,
  CODE_MESSAGE_E0124,
  CODE_MESSAGE_E0125,
  CODE_MESSAGE_E0126,
  CODE_MESSAGE_E0127,
  CODE_MESSAGE_E0128,
  CODE_MESSAGE_E0129,
  CODE_MESSAGE_E0130,
  CODE_MESSAGE_E0131,
  CODE_MESSAGE_E0132,
  CODE_MESSAGE_E0133,
  CODE_MESSAGE_E0134,
  CODE_MESSAGE_E0135,
  CODE_MESSAGE_E0136,
  CODE_MESSAGE_E0137,
  CODE_MESSAGE_E0138,
  CODE_MESSAGE_E0139,
  CODE_MESSAGE_E0140,
  CODE_MESSAGE_E0141,
  CODE_MESSAGE_E0142,
  CODE_MESSAGE_E0143,
  CODE_MESSAGE_E0144,
  CODE_MESSAGE_E0145,
  CODE_MESSAGE_E0146,
  CODE_MESSAGE_E0147,
  CODE_MESSAGE_E0148,
  CODE_MESSAGE_E0149,
  CODE_MESSAGE_E0150,
  CODE_MESSAGE_E0151,
  CODE_MESSAGE_E0152,
  CODE_MESSAGE_E0153,
  CODE_MESSAGE_E0156,
  CODE_MESSAGE_E9996,
  CODE_MESSAGE_E9997,
  CODE_MESSAGE_E9998,
  CODE_MESSAGE_E9999,
  CODE_MESSAGE_E9999_TITLE,
}
