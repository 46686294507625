import { FC, Fragment } from "react"
import { ItemInput } from "../../../../../../../../../_webui/layout/components/Form/Item/Input"
import { Translate } from "../../../../../../../../../constant/Translate"
import { validatorEmailAddress } from "../../../../../../../../../utils/validator/emailAddress"
import { validatorHalfSize } from "../../../../../../../../../utils/validator/halfSize"
import { SchedulesAddTableButtonGroup } from "../../../../Table/ButtonGroup"
import { SchedulesAddStayTabProps } from "./models"

const {
  HELP_H0023,
  HELP_H0024,
  HELP_H0025,
  HELP_H0035,
  TEXT_T256,
  TEXT_T257,
  TEXT_T258,
  TEXT_T259,
  TEXT_T260,
  TEXT_T286,
  TEXT_T287,
} = Translate

export const SchedulesAddStayTab: FC<SchedulesAddStayTabProps> = (props) => {
  const {
    checkRequiredLocationPhoneNumber1,
    getItemTableRequiredField,
    handleAddStayItem,
    handleDeleteStayItem,
    isRequiredItemTableByPPTV,
    isRequiredLocationPhoneNumber1,
    isVisibleBtn,
    schedulesAddStayItem,
  } = props

  const { index } = schedulesAddStayItem

  const itemName = `schedulesAddStay.${index}`

  return (
    <Fragment>
      <SchedulesAddTableButtonGroup
        addText={TEXT_T286}
        deleteText={TEXT_T287}
        handleAdd={handleAddStayItem}
        handleDelete={() =>
          handleDeleteStayItem(schedulesAddStayItem.travelStayId)
        }
        isDisabled={false}
        isVisibleBtn={isVisibleBtn}
      />

      <ItemInput isHidden name={`${itemName}.travelStayId`} />

      <ItemInput
        label={TEXT_T256}
        maxLength={20}
        name={`${itemName}.locationPhoneNumber1`}
        required={isRequiredLocationPhoneNumber1}
        rules={[...checkRequiredLocationPhoneNumber1(), validatorHalfSize]}
        tooltip={HELP_H0023}
      />

      <ItemInput
        label={TEXT_T259}
        maxLength={320}
        name={`${itemName}.emailAddress1`}
        required={isRequiredItemTableByPPTV}
        rules={[...getItemTableRequiredField(), validatorEmailAddress]}
        tooltip={HELP_H0035}
      />

      <ItemInput
        label={TEXT_T257}
        maxLength={20}
        name={`${itemName}.locationPhoneNumber2`}
        rules={[validatorHalfSize]}
        tooltip={HELP_H0024}
      />

      <ItemInput
        label={TEXT_T258}
        maxLength={20}
        name={`${itemName}.satellitePhoneNumber`}
        rules={[validatorHalfSize]}
        tooltip={HELP_H0025}
      />

      <ItemInput
        label={TEXT_T260}
        maxLength={320}
        name={`${itemName}.emailAddress2`}
        rules={[validatorEmailAddress]}
        tooltip={HELP_H0035}
      />
    </Fragment>
  )
}
