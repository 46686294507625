import { FC, Fragment } from "react"
import { ItemInput } from "../../../../../../../../_webui/layout/components/Form/Item/Input"
import { ItemRadio } from "../../../../../../../../_webui/layout/components/Form/Item/Radio"
import { ItemSelect } from "../../../../../../../../_webui/layout/components/Form/Item/Select"
import { RadioItems } from "../../../../../../../../constant/RadioItems"
import { Translate } from "../../../../../../../../constant/Translate"
import { CollapsePostingProps } from "../models"

const { WORK_PLACE_LOCATION_SEARCH } = RadioItems
const { TEXT_T054, TEXT_T055, TEXT_T056, TEXT_T057 } = Translate

export const CollapsePosting: FC<CollapsePostingProps> = (props) => {
  const { allCountries, isOverseasResident, onChangeWorkPlaceLocation } = props

  return (
    <Fragment>
      <ItemRadio
        label={TEXT_T054}
        name="workPlaceLocation"
        radioItems={WORK_PLACE_LOCATION_SEARCH}
        onChange={onChangeWorkPlaceLocation}
      />
      {isOverseasResident && (
        <Fragment>
          <ItemSelect
            label={TEXT_T055}
            name="workPlaceCountry"
            selectItem={allCountries}
          />
          <ItemInput label={TEXT_T056} maxLength={200} name="workPlaceCity" />
          <ItemInput label={TEXT_T057} maxLength={200} name="workPlace" />
        </Fragment>
      )}
    </Fragment>
  )
}
