import { FC, Fragment } from "react"
import { Translate } from "../../../../../../../../constant/Translate"
import { validatorHalfSize } from "../../../../../../../../utils/validator/halfSize"
import { ItemCheckbox } from "../../../../../../../../_webui/layout/components/Form/Item/Checkbox"
import { ItemInput } from "../../../../../../../../_webui/layout/components/Form/Item/Input"
import { SchedulesAddInsuranceProps } from "./models"
import { requiredField } from "../../../../../../../../utils/requiredField"
import { Styled } from "./styled"

const { StyledItemInput } = Styled
const {
  HELP_H0020,
  HELP_H0021,
  TEXT_T061,
  TEXT_T062,
  TEXT_T063,
  TEXT_T064,
  TEXT_T239,
} = Translate

export const SchedulesAddInsurance: FC<SchedulesAddInsuranceProps> = (
  props,
) => {
  const { isDifferentInfoChecked, isInsuranceRequired, onChangeDifferentInfo } =
    props

  const insuranceCompanyNameRules = requiredField(isDifferentInfoChecked)

  const insuranceRequired = isDifferentInfoChecked && isInsuranceRequired
  const insuranceRequireRule = requiredField(insuranceRequired)

  return (
    <Fragment>
      <ItemCheckbox
        isHiddenLabel
        label={TEXT_T239}
        name="differentInforCheck"
        onChange={onChangeDifferentInfo}
        text={TEXT_T239}
      />

      <StyledItemInput
        isDisabled={!isDifferentInfoChecked}
        label={TEXT_T061}
        maxLength={100}
        name="insuranceCompanyName"
        required={isDifferentInfoChecked}
        rules={insuranceCompanyNameRules}
      />

      <ItemInput
        isDisabled={!isDifferentInfoChecked}
        isHidden={!isInsuranceRequired}
        label={TEXT_T062}
        maxLength={20}
        name="insuranceCompanyPhoneNumber"
        required={insuranceRequired}
        rules={[...insuranceRequireRule, validatorHalfSize]}
        tooltip={HELP_H0020}
      />

      <ItemInput
        isDisabled={!isDifferentInfoChecked}
        isHidden={!isInsuranceRequired}
        label={TEXT_T063}
        maxLength={25}
        name="insuranceTicketNumber"
        required={insuranceRequired}
        rules={insuranceRequireRule}
      />

      <ItemInput
        className="mt-50 mb-50"
        isDisabled={!isDifferentInfoChecked}
        isHidden={!isInsuranceRequired}
        label={TEXT_T064}
        maxLength={100}
        name="insuranceSonsignor"
        required={insuranceRequired}
        rules={insuranceRequireRule}
        tooltip={HELP_H0021}
      />
    </Fragment>
  )
}
