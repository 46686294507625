/* eslint-disable complexity */
import { Form } from "antd"
import { FC, Fragment } from "react"
import { Translate } from "../../../../../../../constant/Translate"
import { SchedulesAddButtonSubmitProps } from "../models"
import { ButtonGroup } from "../../../../../../../_webui/layout/components/Button/Group"

const { Item } = Form
const { TEXT_T012, TEXT_T013, TEXT_T390, TEXT_T403 } = Translate

export const SchedulesEditButtonSubmit: FC<SchedulesAddButtonSubmitProps> = (
  props,
) => {
  const {
    form,
    goBack,
    isDisabledSubmit,
    isSchedulesAdd,
    isTwoWay,
    isValidForm,
    isVisibleBtnCancel,
    isVisibleBtnConfirm,
    onOpenExportScheduleDraft,
    onOpenScheduleDraft,
  } = props

  return (
    <Item shouldUpdate className="ant-form-btn-submit">
      {() => {
        const _isDisabledSubmit =
          isTwoWay ||
          !isValidForm ||
          isDisabledSubmit ||
          (form &&
            !!form.getFieldsError().filter(({ errors }) => errors.length)
              .length)

        return (
          <Fragment>
            <ButtonGroup
              buttonItems={[
                {
                  htmlType: "reset",
                  isHidden: !isVisibleBtnCancel,
                  onClick: goBack,
                  order: 3,
                  text: TEXT_T012,
                  type: "ghost",
                },
                {
                  htmlType: "submit",
                  isDisabled: _isDisabledSubmit,
                  isHidden: !isVisibleBtnConfirm,
                  order: 1,
                  text: TEXT_T013,
                  type: "primary",
                },
                {
                  absolutePosition: "right",
                  htmlType: "button",
                  isAbsolute: true,
                  isHidden: !isSchedulesAdd || !_isDisabledSubmit,
                  onClick: onOpenScheduleDraft,
                  order: 2,
                  text: TEXT_T390,
                  type: "warning",
                },
              ]}
            />
            <ButtonGroup
              buttonItems={[
                {
                  isHidden: !isSchedulesAdd,
                  onClick: onOpenExportScheduleDraft,
                  text: TEXT_T403,
                  type: "primary",
                },
              ]}
              isMargin={false}
            />
          </Fragment>
        )
      }}
    </Item>
  )
}
