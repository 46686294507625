/* eslint-disable max-lines */
import { Fragment } from "react"
import {
  ColumnType,
  UseTableConfigType,
} from "../../../../../../../_webui/layout/components/Table/models"
import { Translate } from "../../../../../../../constant/Translate"
import { getDay } from "../../../../../../../utils/getDate"
import { getValueByLanguage } from "../../../../../../../utils/getValueByLanguage"
import { SchedulesCloneDataType } from "../../../../models/clone"

const {
  TEXT_T194,
  TEXT_T196,
  TEXT_T197,
  TEXT_T212,
  TEXT_T213,
  TEXT_T214,
  TEXT_T215,
  TEXT_T405,
  TEXT_T439,
} = Translate

export const useTableConfig =
  (): UseTableConfigType<SchedulesCloneDataType> => {
    const columns: ColumnType<SchedulesCloneDataType>[] = [
      {
        dataIndex: "minDepartureDateTime",
        render: (_, data) => getDay(data.minDepartureDateTime),
        sorter: (a, b) =>
          a.minDepartureDateTime.localeCompare(b.minDepartureDateTime),
        title: TEXT_T196,
        width: 250,
      },
      {
        dataIndex: "maxArrivalDateTime",
        render: (_, data) => getDay(data.maxArrivalDateTime),
        sorter: (a, b) =>
          a.maxArrivalDateTime.localeCompare(b.maxArrivalDateTime),
        title: TEXT_T197,
        width: 250,
      },
      {
        dataIndex: "departureCountryName",
        sorter: (a, b) =>
          a.departureCountryName
            .toString()
            .localeCompare(b.departureCountryName.toString()),
        title: TEXT_T212,
        width: getValueByLanguage(250, 220),
      },
      {
        dataIndex: "transitCountryName",
        render: (_, data) =>
          data.transitCountryName?.split("_").map((item, index) => (
            <Fragment key={index}>
              {item}
              <br />
            </Fragment>
          )),
        sorter: (a, b) =>
          a.transitCountryOrders.localeCompare(b.transitCountryOrders),
        title: TEXT_T213,
        width: getValueByLanguage(250, 220),
      },
      {
        dataIndex: "destinationCountryName",
        sorter: (a, b) =>
          a.destinationCountryName
            .toString()
            .localeCompare(b.destinationCountryName.toString()),
        title: TEXT_T214,
        width: getValueByLanguage(250, 220),
      },
      {
        dataIndex: "travelPurposeName",
        sorter: (a, b) =>
          a.travelPurposeName.localeCompare(b.travelPurposeName),
        title: TEXT_T215,
        width: getValueByLanguage(150, 170),
      },
      {
        dataIndex: "dispatchStartDate",
        render: (_, data) => getDay(data.dispatchStartDate),
        sorter: (a, b) =>
          a.dispatchStartDate.localeCompare(b.dispatchStartDate),
        title: TEXT_T405,
        width: 250,
      },
      {
        dataIndex: "dispatchEndDate",
        render: (_, data) => getDay(data.dispatchEndDate),
        sorter: (a, b) => a.dispatchEndDate.localeCompare(b.dispatchEndDate),
        title: TEXT_T439,
        width: 250,
      },
      {
        dataIndex: "projectName",
        sorter: (a, b) => a.projectName.localeCompare(b.projectName),
        title: TEXT_T194,
        width: 150,
      },
    ]
    return {
      columns,
    }
  }
