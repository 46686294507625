import { RuleObject } from "antd/lib/form"
import { Regex } from "../../../constant/Regex"
import { Translate } from "../../../constant/Translate"

const { REGEX_HALF_SIZE, REGEX_HALF_SIZE_PROJECT_NAME } = Regex
const { MESSAGE_E0004 } = Translate

export const validatorHalfSize = () => ({
  validator(_: RuleObject, value: string) {
    if (value && !REGEX_HALF_SIZE.exec(value)) {
      return Promise.reject(new Error(MESSAGE_E0004))
    }
    return Promise.resolve()
  },
})

export const validatorHalfSizeProjectName = () => ({
  validator(_: RuleObject, value: string) {
    if (value && !REGEX_HALF_SIZE_PROJECT_NAME.exec(value)) {
      return Promise.reject(new Error(MESSAGE_E0004))
    }
    return Promise.resolve()
  },
})
