import { FC } from "react"
import { Card } from "../../../../../_webui/layout/components/Card"
import { Layout } from "../../../../../_webui/layout/components/Layout"
import { Translate } from "../../../../../constant/Translate"
import { SchedulesAddForm } from "../../components/add/Form"
import { useSchedulesAdd } from "./modules/useAdd"
import { Button } from "../../../../../_webui/layout/components/Button"

const { TEXT_T271, TEXT_T272, TEXT_T400 } = Translate

export const SchedulesAdd: FC = () => {
  const {
    breadcrumbItems,
    goToSchedulesClone,
    handleGetScheduleDraft,
    isHiddenBtnScheduleDraft,
    isLoading,
    isLoadingLayout,
    isSchedulesAdd,
    isVisibleBtnCopy,
    ...rest
  } = useSchedulesAdd()

  return (
    <Layout
      breadcrumbItems={breadcrumbItems}
      isLoadingLayout={isLoadingLayout}
      title={TEXT_T271}
    >
      <Card
        childrenRight={
          isSchedulesAdd && (
            <Button
              htmlType="button"
              isFullWidth
              isHidden={isHiddenBtnScheduleDraft}
              onClick={handleGetScheduleDraft}
              text={TEXT_T400}
              type="warning"
            />
          )
        }
        heading={TEXT_T271}
        isLoading={isLoading}
        isSearch={isVisibleBtnCopy}
        onClick={goToSchedulesClone}
        searchText={TEXT_T272}
      >
        <SchedulesAddForm isSchedulesAdd={isSchedulesAdd} {...rest} />
      </Card>
    </Layout>
  )
}
