import { Translate } from "../../../../../../../../constant/Translate"
import { GridItemType } from "../../../../../../../../_webui/layout/components/Grid/models"
import { ScheduleInfoType } from "../../../../../models/addSuccess"

const { TEXT_T215, TEXT_T234, TEXT_T235, TEXT_T405, TEXT_T439 } = Translate

export const getDetailsGridConfig = (
  scheduleInfo: ScheduleInfoType,
): GridItemType[] => [
  {
    key: TEXT_T215,
    text: scheduleInfo.travelPurposeName,
  },
  {
    key: TEXT_T405,
    text: scheduleInfo.dispatchStartDate,
  },
  {
    key: TEXT_T439,
    text: scheduleInfo.dispatchEndDate,
  },
  {
    key: TEXT_T234,
    text: scheduleInfo.bumonName,
  },
  {
    key: TEXT_T235,
    text: scheduleInfo.mainPersonName,
  },
]
