/* eslint-disable max-lines */
import { Table } from "antd"
import { ItemAutoComplete } from "../../../../../../../../_webui/layout/components/Form/Item/AutoComplete"
import { ItemInput } from "../../../../../../../../_webui/layout/components/Form/Item/Input"
import { ItemSelect } from "../../../../../../../../_webui/layout/components/Form/Item/Select"
import {
  ColumnType,
  UseTableConfigType,
} from "../../../../../../../../_webui/layout/components/Table/models"
import { LabelRequired } from "../../../../../../../../_webui/layout/components/Typography/LabelRequired"
import { GeneralCode } from "../../../../../../../../constant/GeneralCode"
import { Translate } from "../../../../../../../../constant/Translate"
import { validatorHalfSize } from "../../../../../../../../utils/validator/halfSize"
import { validatorRequiredField } from "../../../../../../../../utils/validator/requiredField"
import { UseTableDetailsConfigProps } from "../../../../../components/add/Form/ContentCollapse/Detail/models"
import { SchedulesAddDetailRow } from "../../../../../constants/add/addDetailRow"
import { ScheduleInfoDetailsType } from "../../../../../models/add"
import { setNameRowDetail } from "../../../utils/setNameRowDetail"
import { ItemTimePicker } from "../../../../../../../../_webui/layout/components/Form/Item/TimePicker"
import { ItemDatePicker } from "../../../../../../../../_webui/layout/components/Form/Item/DatePicker"
import { ItemCheckbox } from "../../../../../../../../_webui/layout/components/Form/Item/Checkbox"
import { requiredField } from "../../../../../../../../utils/requiredField"

const {
  TEXT_T155,
  TEXT_T163,
  TEXT_T169,
  TEXT_T171,
  TEXT_T173,
  TEXT_T175,
  TEXT_T183,
  TEXT_T202,
  TEXT_T205,
  TEXT_T206,
  TEXT_T208,
  TEXT_T209,
  TEXT_T220,
  TEXT_T240,
  TEXT_T243,
} = Translate

const { OTHER, TPTT001 } = GeneralCode
const { ARRIVAL, DEPARTURE } = SchedulesAddDetailRow
const { SELECTION_COLUMN } = Table

export const useTableDetailsConfig = (
  props: UseTableDetailsConfigProps,
): UseTableConfigType<ScheduleInfoDetailsType> => {
  const {
    allCountries,
    flightNumberList,
    onBlur,
    onBlurArrivalCityName,
    onChangeCity,
    onChangeCountry,
    onChangeDate,
    onChangeTransportation,
    onClearDepartureFlight,
    onKeyDown,
    onSelectDepartureFlight,
    onValidateChangeDate,
    transportationList,
  } = props

  const columns: ColumnType<ScheduleInfoDetailsType>[] = [
    {
      render: (_, _data, index) => index + 1,
      title: TEXT_T240,
      width: 65,
    },
    SELECTION_COLUMN,
    {
      render: (_, data) => (
        <ItemInput
          isHidden
          isHiddenLabel
          label={TEXT_T243}
          name={`${setNameRowDetail(data.index)}.travelDetailId`}
        />
      ),
      title: <div className="d-none">{TEXT_T243}</div>,
      width: 0,
    },
    {
      render: (_, data, index) => (
        <ItemSelect
          isHiddenLabel
          label={TEXT_T202}
          name={`${setNameRowDetail(data.index)}.transportation`}
          onChange={(value: string) => onChangeTransportation(value, index)}
          rules={[validatorRequiredField]}
          selectItem={transportationList}
        />
      ),
      title: <LabelRequired label={TEXT_T202} />,
      width: 250,
    },
    {
      render: (_, data) => (
        <ItemCheckbox
          isCenter
          isHiddenLabel
          label={TEXT_T220}
          name={`${setNameRowDetail(data.index)}.isTransit`}
          text={TEXT_T220}
        />
      ),
      title: TEXT_T220,
      width: 110,
    },
    {
      render: (_, data) => (
        <ItemAutoComplete
          clearIndex={data.index}
          isHiddenLabel
          label={TEXT_T173}
          maxLength={100}
          name={`${setNameRowDetail(data.index)}.departureFlightNumber`}
          onClear={() =>
            onClearDepartureFlight(data.travelDetailId, data.index)
          }
          onSelect={(value) =>
            onSelectDepartureFlight(value, data.travelDetailId, data.index)
          }
          options={flightNumberList}
          rules={[
            ...requiredField(data.transportation === TPTT001),
            validatorHalfSize,
          ]}
        />
      ),
      title: <LabelRequired isBgBlue label={TEXT_T173} />,
      width: 250,
    },
    {
      render: (_, data) => (
        <ItemSelect
          isHiddenLabel
          label={TEXT_T205}
          name={`${setNameRowDetail(data.index)}.departureCountryCode`}
          onChange={(value) =>
            onChangeCountry(data.travelDetailId, value, DEPARTURE)
          }
          rules={[validatorRequiredField]}
          selectItem={allCountries}
        />
      ),
      title: <LabelRequired label={TEXT_T205} />,
      width: 250,
    },
    {
      render: (_, data) => (
        <ItemSelect
          isHiddenLabel
          label={TEXT_T206}
          name={`${setNameRowDetail(data.index)}.departureCityCode`}
          onChange={(value) =>
            onChangeCity(data.travelDetailId, value, DEPARTURE)
          }
          rules={[validatorRequiredField]}
          selectItem={data?.departureCities}
        />
      ),
      title: <LabelRequired label={TEXT_T206} />,
      width: 250,
    },
    {
      render: (_, data) => (
        <ItemInput
          isDisabled={data.departureCityCode !== OTHER}
          isHiddenLabel
          label={TEXT_T175}
          maxLength={200}
          name={`${setNameRowDetail(data.index)}.departureCityName`}
          rules={requiredField(data.departureCityCode === OTHER)}
        />
      ),
      title: <LabelRequired isBgBlue label={TEXT_T175} />,
      width: 250,
    },
    {
      render: (_, data) => {
        const departureName = `${setNameRowDetail(data.index)}.departureDate`
        return (
          <ItemDatePicker
            name={departureName}
            onBlur={(event) => {
              onBlur(event, TEXT_T155, departureName)
              onValidateChangeDate()
            }}
            onChange={() => {
              onValidateChangeDate()
            }}
            onKeyDown={(event) => {
              onKeyDown(event, TEXT_T155, departureName)
              if (event.keyCode === 13) onValidateChangeDate()
            }}
            rules={[validatorRequiredField]}
          />
        )
      },
      title: <LabelRequired label={TEXT_T155} />,
      width: 250,
    },
    {
      render: (_, data) => {
        const name = `${setNameRowDetail(data.index)}.departureTime`
        const departureName = `${setNameRowDetail(data.index)}.departureDate`
        return (
          <ItemTimePicker
            name={name}
            onBlur={(event) => {
              onBlur(event, TEXT_T163, name)
              onChangeDate(departureName)
            }}
            onChange={() => {
              onChangeDate(departureName)
            }}
            onKeyDown={(event) => {
              onKeyDown(event, TEXT_T163, name)
              onChangeDate(departureName)
            }}
            rules={[validatorRequiredField]}
          />
        )
      },
      title: <LabelRequired label={TEXT_T163} />,
      width: 150,
    },
    {
      render: (_, data) => (
        <ItemSelect
          isHiddenLabel
          label={TEXT_T208}
          name={`${setNameRowDetail(data.index)}.arrivalCountryCode`}
          onChange={(value) =>
            onChangeCountry(data.travelDetailId, value, ARRIVAL)
          }
          rules={[validatorRequiredField]}
          selectItem={allCountries}
        />
      ),
      title: <LabelRequired label={TEXT_T208} />,
      width: 250,
    },
    {
      render: (_, data) => (
        <ItemSelect
          isHiddenLabel
          label={TEXT_T209}
          name={`${setNameRowDetail(data.index)}.arrivalCityCode`}
          onChange={(value) =>
            onChangeCity(data.travelDetailId, value, ARRIVAL)
          }
          rules={[validatorRequiredField]}
          selectItem={data.arrivalCities}
        />
      ),
      title: <LabelRequired label={TEXT_T209} />,
      width: 250,
    },
    {
      render: (_, data) => (
        <ItemInput
          isDisabled={data.arrivalCityCode !== OTHER}
          isHiddenLabel
          label={TEXT_T183}
          maxLength={200}
          name={`${setNameRowDetail(data.index)}.arrivalCityName`}
          onBlur={(event) =>
            onBlurArrivalCityName(
              event.currentTarget.value,
              data.travelDetailId,
            )
          }
          rules={requiredField(data.arrivalCityCode === OTHER)}
        />
      ),
      title: <LabelRequired isBgBlue label={TEXT_T183} />,
      width: 250,
    },
    {
      render: (_, data) => {
        const arrivalName = `${setNameRowDetail(data.index)}.arrivalDate`
        const departureName = `${setNameRowDetail(data.index)}.departureDate`
        return (
          <ItemDatePicker
            name={arrivalName}
            onBlur={(event) => {
              onBlur(event, TEXT_T169, arrivalName)
              onValidateChangeDate()
            }}
            onChange={() => {
              onValidateChangeDate()
              onChangeDate(departureName)
            }}
            onKeyDown={(event) => {
              onKeyDown(event, TEXT_T169, arrivalName)
              if (event.keyCode === 13) onValidateChangeDate()
            }}
            rules={[validatorRequiredField]}
          />
        )
      },
      title: <LabelRequired label={TEXT_T169} />,
      width: 250,
    },
    {
      render: (_, data) => {
        const name = `${setNameRowDetail(data.index)}.arrivalTime`
        const departureName = `${setNameRowDetail(data.index)}.departureDate`
        return (
          <ItemTimePicker
            name={name}
            onBlur={(event) => {
              onBlur(event, TEXT_T171, name)
              onChangeDate(departureName)
            }}
            onChange={() => {
              onChangeDate(departureName)
            }}
            onKeyDown={(event) => {
              onKeyDown(event, TEXT_T171, name)
              onChangeDate(departureName)
            }}
            rules={[validatorRequiredField]}
          />
        )
      },
      title: <LabelRequired label={TEXT_T171} />,
      width: 150,
    },
  ]

  return {
    columns,
  }
}
