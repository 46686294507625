/* eslint-disable complexity */
import { FC, Fragment } from "react"
import { Regex } from "../../../../../../constant/Regex"
import { Table } from "../../../../../../_webui/layout/components/Table"
import { Tabs } from "../../../../../../_webui/layout/components/Tabs"
import { SchedulesAddTableButtonGroup } from "./ButtonGroup"
import { SchedulesAddTableProps } from "./models"
import { ItemRadio } from "../../../../../../_webui/layout/components/Form/Item/Radio"
import { Translate } from "../../../../../../constant/Translate"
import { Constants } from "./constants"

const { DETAIL_SORT_TYPES } = Constants
const { REGEX_IS_DESKTOP } = Regex
const { TEXT_T544 } = Translate

export const SchedulesAddTable: FC<SchedulesAddTableProps> = (props) => {
  const {
    activeKey,
    addText,
    columns,
    dataSource,
    dataTableSelected,
    deleteText,
    handleAdd,
    handleDelete,
    id,
    isLoading,
    isTableDetail,
    isVisibleBtn,
    items,
    onTabClick,
    rowKey,
    rowSelection,
  } = props

  return (
    <Fragment>
      {REGEX_IS_DESKTOP ? (
        <Table
          columns={columns}
          dataSource={dataSource}
          isBackgroundFirstChild
          id={id}
          isLoading={isLoading}
          pagination={false}
          rowKey={rowKey}
          rowSelection={rowSelection}
        />
      ) : (
        <Tabs
          activeKey={activeKey}
          defaultActiveKey={"1"}
          items={items}
          onTabClick={onTabClick}
        />
      )}
      {isTableDetail && (
        <ItemRadio
          label={TEXT_T544}
          name="detailSortType"
          radioItems={DETAIL_SORT_TYPES}
        />
      )}
      {(REGEX_IS_DESKTOP || (!REGEX_IS_DESKTOP && !dataSource.length)) && (
        <SchedulesAddTableButtonGroup
          addText={addText}
          deleteText={deleteText}
          handleAdd={handleAdd}
          handleDelete={handleDelete}
          isDisabled={!dataTableSelected.length}
          isVisibleBtn={isVisibleBtn}
        />
      )}
    </Fragment>
  )
}
