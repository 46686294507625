/* eslint-disable max-statements */
/* eslint-disable max-lines */
import { Form } from "antd"
import { useState } from "react"
import { useHistory, useLocation, useParams } from "react-router-dom"
import { AnyValue } from "../../../../../../../_webui/layout/models"
import { useModal } from "../../../../../../../utils/hook/useModal"
import { UseParamsType } from "../../../../../../../utils/useParams"
import { InitialValues } from "../../../../constants/add/initialValues"
import {
  ScheduleDetailsType,
  ScheduleInfoDetailsType,
  ScheduleInfoLodgingsType,
  ScheduleInfoStaysType,
  SchedulesAddStateProps,
} from "../../../../models/add"
import { getGeneralMasters } from "../../../../../../../utils/apis/getGeneralMasters"
import { getAllCountries } from "../../../../../../../utils/apis/getAllCountries"
import { GeneralCode } from "../../../../../../../constant/GeneralCode"
import { useDetailRowSelection } from "../useRowSelection"
import { FlightNumberLabelItem } from "../../../../models/add/Detail"
import { OptionItemType } from "../../../../../../../_webui/layout/components/Select/models"
import { GeneralMastersResType } from "../../../../../../../infrastructure/handlers/generalMasters/models"

const { COUNTRYRM, TPTT } = GeneralCode
const { COLLAPSE_ACTIVE, INITIAL_VALUES_SCHEDULE_DATA } = InitialValues

export const useVariablesHandle = () => {
  const { pathname, state } = useLocation<SchedulesAddStateProps>()
  const { push } = useHistory()
  const { id } = useParams<UseParamsType>()
  const [form] = Form.useForm()
  const { isOpen, onCancel, onOpen } = useModal()
  const {
    isOpen: isOpenRoundTrip,
    onCancel: onCancelRoundTripModal,
    onOpen: onOpenRoundTrip,
  } = useModal()

  const [dataSubmit, setDataSubmit] = useState<AnyValue>()
  const [detailIndexIdentity, setDetailIndexIdentity] = useState(0)
  const [lodgingIndexIdentity, setLodgingIndexIdentity] = useState(0)
  const [stayIndexIdentity, setStayIndexIdentity] = useState(0)
  const [workPlaceLocation, setWorkPlaceLocation] = useState(0)

  const [detailIndexTabActive, setDetailIndexTabActive] = useState("1")
  const [lodgingIndexTabActive, setLodgingIndexTabActive] = useState("1")
  const [stayIndexTabActive, setStayIndexTabActive] = useState("1")
  const [travelerId, setTravelerId] = useState("")
  const [assignmentValidMsg, setAssignmentValidMsg] = useState("")
  const [detailValidMsg, setDetailValidMsg] = useState("")
  const [lodgingValidMsg, setLodgingValidMsg] = useState("")
  const [workPlaceCountryCode, setWorkPlaceCountryCode] = useState("")
  const [travelerBumonCd, setTravelerBumonCd] = useState("")

  const [isDifferentInfoChecked, setIsDifferentInfoChecked] = useState(false)
  const [isInsuranceRequired, setIsInsuranceRequired] = useState(true)
  const [isDisabledSubmit, setIsDisabledSubmit] = useState(false)
  const [isActiveBumon, setIsActiveBumon] = useState(false)
  const [isSubmitValidate, setIsSubmitValidate] = useState(false)
  const [isTwoWay, setIsTwoWay] = useState(false)
  const [isCheckTwoWay, setIsCheckTwoWay] = useState(false)
  const [isRequiredItemTableByPPTV, setIsRequiredItemTableByPPTV] =
    useState(true)
  const [isRequiredItemTableLodgings, setIsRequiredItemTableLodgings] =
    useState(true)
  const [isRequiredItemTableByDate, setIsRequiredItemTableByDate] =
    useState(true)

  const [isRequiredDispatchDate, setIsRequiredDispatchDate] = useState(false)
  const [isInsuranceAndUrgent, setIsInsuranceAndUrgent] = useState(false)
  const [isOtherStaffNumber, setIsOtherStaffNumber] = useState(false)
  const [isAccompanyingFamily, setIsAccompanyingFamily] = useState(false)
  const [isHiredLocally, setIsHiredLocally] = useState(false)

  const [listPPTVIsValue3Equal1, setListPPTVIsValue3Equal1] = useState<
    string[]
  >([])
  const [listPPTVIsValue4Equal2, setListPPTVIsValue4Equal2] = useState<
    string[]
  >([])
  const [listPPTVIsValue5EqualTwoWay, setListPPTVIsValue5EqualTwoWay] =
    useState<string[]>([])
  const [detailRequiredFields, setDetailRequiredFields] = useState<string[]>([])
  const [lodgingRequiredFields, setLodgingRequiredFields] = useState<string[]>(
    [],
  )
  const [stayRequiredFields, setStayRequiredFields] = useState<string[]>([])
  const [detailDataSource, setDetailDataSource] = useState<
    ScheduleInfoDetailsType[]
  >([])
  const [lodgingDataSource, setLodgingDataSource] = useState<
    ScheduleInfoLodgingsType[]
  >([])
  const [stayDataSource, setStayDataSource] = useState<ScheduleInfoStaysType[]>(
    [],
  )
  const [flightNumberList, setFlightNumberList] = useState<
    FlightNumberLabelItem[]
  >([])
  const [flightNumberListAll, setFlightNumberListAll] = useState<
    ScheduleDetailsType[]
  >([])
  const [defaultCountries, setDefaultCountries] = useState<OptionItemType[]>([])

  const [purposeTravelList, setPurposeTravelList] = useState<OptionItemType[]>(
    [],
  )

  const [dispatchTypeDetailRes, setDispatchTypeDetailRes] = useState<
    GeneralMastersResType[]
  >([])

  const [purposeTravelRes, setPurposeTravelRes] = useState<
    GeneralMastersResType[]
  >([])

  const [defaultActiveKey, setDefaultActiveKey] = useState(COLLAPSE_ACTIVE)

  const [schedulesData, setSchedulesData] = useState(
    INITIAL_VALUES_SCHEDULE_DATA,
  )

  const [departments, setDepartments] = useState<OptionItemType[]>([])
  const [dispatchType, setDispatchType] = useState<OptionItemType[]>([])

  // APIを呼び出し、交通手段一覧情報を取得し
  const { generalMasters: transportationList } = getGeneralMasters(TPTT)

  const { generalMastersRemark } = getGeneralMasters(COUNTRYRM)

  // APIを呼び出し、国一覧情報を取得し
  const { allCountries } = getAllCountries()

  const {
    detailRowSelection,
    detailSelectedRowKeys,
    lodgingRowSelection,
    lodgingSelectedRowKeys,
    setDetailSelectedRowKeys,
    setIsCheckAllDetail,
    setIsCheckAllLodging,
    setIsCheckAllStay,
    setLodgingSelectedRowKeys,
    setStaySelectedRowKeys,
    stayRowSelection,
    staySelectedRowKeys,
  } = useDetailRowSelection({
    detailDataSource,
    lodgingDataSource,
    stayDataSource,
  })

  const {
    details: scheduleDetails,
    lodgings: scheduleLodgings,
    stays: scheduleStays,
  } = schedulesData

  return {
    allCountries,
    assignmentValidMsg,
    dataSubmit,
    defaultActiveKey,
    defaultCountries,
    departments,
    detailDataSource,
    detailIndexIdentity,
    detailIndexTabActive,
    detailRequiredFields,
    detailRowSelection,
    detailSelectedRowKeys,
    detailValidMsg,
    dispatchType,
    dispatchTypeDetailRes,
    flightNumberList,
    flightNumberListAll,
    form,
    generalMastersRemark,
    id,
    isAccompanyingFamily,
    isActiveBumon,
    isCheckTwoWay,
    isDifferentInfoChecked,
    isDisabledSubmit,
    isHiredLocally,
    isInsuranceAndUrgent,
    isInsuranceRequired,
    isOpen,
    isOpenRoundTrip,
    isOtherStaffNumber,
    isRequiredDispatchDate,
    isRequiredItemTableByDate,
    isRequiredItemTableByPPTV,
    isRequiredItemTableLodgings,
    isSubmitValidate,
    isTwoWay,
    listPPTVIsValue3Equal1,
    listPPTVIsValue4Equal2,
    listPPTVIsValue5EqualTwoWay,
    lodgingDataSource,
    lodgingIndexIdentity,
    lodgingIndexTabActive,
    lodgingRequiredFields,
    lodgingRowSelection,
    lodgingSelectedRowKeys,
    lodgingValidMsg,
    onCancel,
    onCancelRoundTripModal,
    onOpen,
    onOpenRoundTrip,
    pathname,
    purposeTravelList,
    purposeTravelRes,
    push,
    scheduleDetails,
    scheduleLodgings,
    scheduleStays,
    schedulesData,
    setAssignmentValidMsg,
    setDataSubmit,
    setDefaultActiveKey,
    setDefaultCountries,
    setDepartments,
    setDetailDataSource,
    setDetailIndexIdentity,
    setDetailIndexTabActive,
    setDetailRequiredFields,
    setDetailSelectedRowKeys,
    setDetailValidMsg,
    setDispatchType,
    setDispatchTypeDetailRes,
    setFlightNumberList,
    setFlightNumberListAll,
    setIsAccompanyingFamily,
    setIsActiveBumon,
    setIsCheckAllDetail,
    setIsCheckAllLodging,
    setIsCheckAllStay,
    setIsCheckTwoWay,
    setIsDifferentInfoChecked,
    setIsDisabledSubmit,
    setIsHiredLocally,
    setIsInsuranceAndUrgent,
    setIsInsuranceRequired,
    setIsOtherStaffNumber,
    setIsRequiredDispatchDate,
    setIsRequiredItemTableByDate,
    setIsRequiredItemTableByPPTV,
    setIsRequiredItemTableLodgings,
    setIsSubmitValidate,
    setIsTwoWay,
    setListPPTVIsValue3Equal1,
    setListPPTVIsValue4Equal2,
    setListPPTVIsValue5EqualTwoWay,
    setLodgingDataSource,
    setLodgingIndexIdentity,
    setLodgingIndexTabActive,
    setLodgingRequiredFields,
    setLodgingSelectedRowKeys,
    setLodgingValidMsg,
    setPurposeTravelList,
    setPurposeTravelRes,
    setSchedulesData,
    setStayDataSource,
    setStayIndexIdentity,
    setStayIndexTabActive,
    setStayRequiredFields,
    setStaySelectedRowKeys,
    setTravelerBumonCd,
    setTravelerId,
    setWorkPlaceCountryCode,
    setWorkPlaceLocation,
    state,
    stayDataSource,
    stayIndexIdentity,
    stayIndexTabActive,
    stayRequiredFields,
    stayRowSelection,
    staySelectedRowKeys,
    transportationList,
    travelerBumonCd,
    travelerId,
    workPlaceCountryCode,
    workPlaceLocation,
  }
}
