import { useHistory, useLocation } from "react-router-dom"
import { SchedulesAddSuccessStateType } from "../../../../models/addSuccess"
import { useVariablesAuthority } from "../useVariablesAuthority"
import { getBreadcrumbItems } from "./getBreadcrumbItems"
import { useApi } from "../useApi"

export const useVariables = () => {
  const { push } = useHistory()
  const { pathname, state } = useLocation<SchedulesAddSuccessStateType>()

  const {
    isNotAccessScreen,
    isVisibleBtnEdit,
    isVisibleBtnSearch,
    isVisibleOtherRemarks,
  } = useVariablesAuthority(pathname, state)

  const breadcrumbItems = getBreadcrumbItems(
    state?.schedulesURL,
    state?.isFromSCR212,
  )

  const {
    assignmentGridItem,
    confirmGridItem,
    detailsColumnsFirst,
    detailsGridItem,
    detailsTabsItems,
    generalMastersRemark,
    getSchedule,
    insuranceGridItem,
    isHiredLocally,
    isLoadingLayout,
    lodgingsColumns,
    lodgingsTabsItems,
    otherGridItem,
    personalGridItem,
    scheduleDetails,
    scheduleLodgings,
    schedulesData,
    scheduleStays,
    staysColumns,
    staysTabsItems,
    travelScheduleId,
  } = useApi(isVisibleOtherRemarks)

  return {
    assignmentGridItem,
    breadcrumbItems,
    confirmGridItem,
    detailsColumnsFirst,
    detailsGridItem,
    detailsTabsItems,
    generalMastersRemark,
    getSchedule,
    insuranceGridItem,
    isHiredLocally,
    isLoadingLayout,
    isNotAccessScreen,
    isVisibleBtnEdit,
    isVisibleBtnSearch,
    isVisibleOtherRemarks,
    lodgingsColumns,
    lodgingsTabsItems,
    otherGridItem,
    personalGridItem,
    push,
    scheduleDetails,
    scheduleLodgings,
    scheduleStays,
    schedulesData,
    state,
    staysColumns,
    staysTabsItems,
    travelScheduleId,
  }
}
