/* eslint-disable max-lines */
import { useEffect } from "react"
import { backToLogin } from "../../../../../../../utils/backToLogin"
import { useVariables } from "../useVariables"
import { scheduleApi } from "../../../../apis"
import { AxiosErrorType } from "../../../../../../../infrastructure/axiosError/models"
import { Routers } from "../../../../../../../constant/Routers"
import { pushStateError } from "../../../../../../../utils/pushStateError"

const { SCR217, SCR973 } = Routers

export const useSchedulesDetail = () => {
  const {
    assignmentGridItem,
    breadcrumbItems,
    confirmGridItem,
    detailsColumnsFirst,
    detailsGridItem,
    detailsTabsItems,
    generalMastersRemark,
    getSchedule,
    goToTravelerDetail,
    insuranceGridItem,
    isHiredLocally,
    isLoadingLayout,
    isNotAccessScreen,
    isOpen,
    isVisibleBtnDelete,
    isVisibleBtnEdit,
    isVisibleBtnRefer,
    isVisibleBtnSearch,
    isVisibleOtherRemarks,
    lodgingsColumns,
    lodgingsTabsItems,
    onCancel,
    onEdit,
    onOpen,
    onSearch,
    otherGridItem,
    pathname,
    personalGridItem,
    push,
    scheduleDetails,
    scheduleLodgings,
    scheduleStays,
    setIsLoadingLayout,
    state,
    staysColumns,
    staysTabsItems,
    travelScheduleId,
  } = useVariables()

  const onOk = () => {
    setIsLoadingLayout(true)
    setTimeout(() => {
      // 渡航予定情報を削除する。
      scheduleApi
        .deleteSchedule(travelScheduleId)
        .then(() => {
          // 「SCR217_渡航予定情報の削除後画面」に遷移する。
          push(SCR217, { ...state, isFromSCR211: true, sourceURL: pathname })
        })
        .catch((error: AxiosErrorType) => {
          // 「SCR973_エラー情報の表示画面」に遷移する。
          const stateError = pushStateError(error)
          push({ pathname: SCR973, state: stateError })
        })
    }, 800)
  }

  useEffect(() => {
    // ユ－ザ－の画面アクセス許可チェックを処理する。
    if (isNotAccessScreen) {
      // 「SCR973_エラー情報の表示画面」に遷移する。
      const { pathname, state } = backToLogin()
      push({ pathname, state })
    } else if (generalMastersRemark) {
      getSchedule()
    }
  }, [generalMastersRemark])

  return {
    assignmentGridItem,
    breadcrumbItems,
    confirmGridItem,
    detailsColumnsFirst,
    detailsGridItem,
    detailsTabsItems,
    goToTravelerDetail,
    insuranceGridItem,
    isHiredLocally,
    isLoadingLayout,
    isOpen,
    isVisibleBtnDelete,
    isVisibleBtnEdit,
    isVisibleBtnRefer,
    isVisibleBtnSearch,
    isVisibleOtherRemarks,
    lodgingsColumns,
    lodgingsTabsItems,
    onCancel,
    onEdit,
    onOk,
    onOpen,
    onSearch,
    otherGridItem,
    personalGridItem,
    scheduleDetails,
    scheduleLodgings,
    scheduleStays,
    staysColumns,
    staysTabsItems,
  }
}
