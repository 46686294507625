/* eslint-disable complexity */
import { Constant } from "../../../../../../../../constant"
import { SchedulesSearchConditionBasicType } from "../../../../../models/list"
import { checkIsFullSize } from "../checkIsFullSize"
import { checkIsHalfSize } from "../checkIsHalfSize"

const { CHECK_SELECTED_LANG } = Constant

export const convertBasicToAdvance = (
  searchConditionBasic: SchedulesSearchConditionBasicType,
) => {
  const {
    arrivalCity,
    arrivalCityCode,
    arrivalDateTimeDate,
    bumonCd,
    countryToTravel: arrivalCountryCode,
    departureDateTimeDate,
    fullName,
    isTransit,
    projectName,
  } = searchConditionBasic

  const getValueBySpace = () => {
    const _fullName = {
      kanjiName: [""],
      kanjiSurname: "",
      romajiName: [""],
      romajiSurname: "",
    }
    if (checkIsFullSize(fullName)) {
      const [surname = "", ...name] = fullName ? fullName.split("　") : []
      _fullName.kanjiSurname = surname
      _fullName.kanjiName = name
    }
    if (checkIsHalfSize(fullName)) {
      const [surname = "", ...name] = fullName ? fullName.split(" ") : []
      _fullName.romajiSurname = surname
      _fullName.romajiName = name
    }
    return _fullName
  }

  const { kanjiName, kanjiSurname, romajiName, romajiSurname } =
    getValueBySpace()
  const fullNameAdvance = {
    kanjiName: kanjiName?.length ? kanjiName.join("　") : "",
    kanjiSurname,
    romajiName: romajiName?.length ? romajiName.join(" ") : "",
    romajiSurname,
  }

  const projectAdvance = {
    projectNameEn:
      !CHECK_SELECTED_LANG && checkIsHalfSize(projectName) ? projectName : "",
    projectNameJp:
      CHECK_SELECTED_LANG && checkIsFullSize(projectName) ? projectName : "",
  }

  const getTime = (isValue: boolean, value: string) => (isValue ? value : "")
  return {
    arrivalCity,
    arrivalCityCode,
    arrivalCountryCode,
    arrivalDateTime: arrivalDateTimeDate,
    arrivalDateTimeDate: arrivalDateTimeDate,
    arrivalDateTimeHour: getTime(!!arrivalDateTimeDate, "23"),
    arrivalDateTimeMinutes: getTime(!!arrivalDateTimeDate, "59"),
    bumonCd,
    departureDateTime: departureDateTimeDate,
    departureDateTimeDate: departureDateTimeDate,
    departureDateTimeHour: getTime(!!departureDateTimeDate, "00"),
    departureDateTimeMinutes: getTime(!!departureDateTimeDate, "00"),
    isTransit: isTransit,
    ...fullNameAdvance,
    ...projectAdvance,
  }
}
