import Excel from "exceljs"
import { Translate } from "../../../../../../../../../../constant/Translate"

const {
  EXPORT_EP0054, // 氏名（日）    Full name(Japanese)
  EXPORT_EP0052, // 氏名（英）    Full name(English)
  EXPORT_EP0158, // 人材種別（詳細）    Dispatch type (Detail)
  EXPORT_EP0184, // 所属先名    Group name
  EXPORT_EP0103, // 電話番号    Phone number
  EXPORT_EP0015, // メールアドレス    Email
  EXPORT_EP0049, // 国名・任地・勤務地    Country name/place of employment/place of work
  EXPORT_EP0043, // 現在地    Current location
  EXPORT_EP0106, // 渡航目的    Purpose of travel
  EXPORT_EP0020, // 案件名    Project name
  EXPORT_EP0209, // 派遣期間/契約期間    Dispatch duration/Contract duration
  EXPORT_EP0035, // 拠点担当者名    Name of person in charge of overseas office
  EXPORT_EP0128, // 備考1   Remarks 1
} = Translate

export const columnsConfig = (worksheet: Excel.Worksheet) => {
  worksheet.columns = [
    { header: EXPORT_EP0054, key: "fullNameKanji", width: 31 }, // width = 22 * 8 = 176px
    { header: EXPORT_EP0052, key: "fullNameRomaji", width: 31 },
    { header: EXPORT_EP0158, key: "dispatchTypeDetailName", width: 32 },
    { header: EXPORT_EP0184, key: "groupName", width: 20 },
    { header: EXPORT_EP0103, key: "phoneNumber", width: 19 },
    { header: EXPORT_EP0015, key: "personEmailAddress", width: 19 },
    {
      header: EXPORT_EP0049,
      key: "workPlaceCountryAndWorkPlaceCity",
      width: 30,
    },
    { header: EXPORT_EP0043, key: "travelerLocation", width: 31 },
    { header: EXPORT_EP0106, key: "travelPurposeName", width: 25 },
    { header: EXPORT_EP0020, key: "projectNameJpOrProjectNameEn", width: 46 },
    {
      header: EXPORT_EP0209,
      key: "departureDateTimeAndArrivalDateTime",
      width: 30,
    },
    { header: EXPORT_EP0035, key: "locationPersonName", width: 26 },
    { header: EXPORT_EP0128, key: "othersRemarks1", width: 25 },
  ]
}
