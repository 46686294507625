import { FC } from "react"
import { Button } from ".."
import { ButtonGroupProps } from "./models"
import { getStyle } from "../../../../../utils/getStyle"
import { Styled } from "./styled"
import { Regex } from "../../../../../constant/Regex"

const { StyledButtonGroup, StyledButtonGroupItem } = Styled
const { REGEX_IS_DESKTOP } = Regex

export const ButtonGroup: FC<ButtonGroupProps> = (props) => {
  const { buttonItems, isMargin = true, position = "center", ...res } = props
  return (
    <StyledButtonGroup isMargin={isMargin} position={position} {...res}>
      {buttonItems.map((item, index) => {
        const styleClassName = getStyle(item.className, ` ${item.className}`)
        const styleBtnGroupItem = `ant-btn-group-item${styleClassName}`
        if (item.isHidden) return null

        return (
          <StyledButtonGroupItem
            className={styleBtnGroupItem}
            key={index}
            style={{ order: REGEX_IS_DESKTOP ? "" : item.order }}
          >
            <Button
              absolutePosition={item.absolutePosition}
              addonAfter={item.addonAfter}
              addonBefore={item.addonBefore}
              htmlType={item.htmlType || "button"}
              id={item.id}
              isAbsolute={item.isAbsolute}
              isDisabled={item.isDisabled}
              isHidden={item.isHidden}
              isSmall={item.isSmall}
              onClick={item.onClick}
              text={item.text}
              title={item.title}
              type={item.type}
            />
          </StyledButtonGroupItem>
        )
      })}
    </StyledButtonGroup>
  )
}
