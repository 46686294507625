/* eslint-disable max-lines */
import { translateMessage } from "../../../../utils/translateMessage"
import { I18nCode } from "../../../I18nCode"

const {
  CODE_MESSAGE_E0001,
  CODE_MESSAGE_E0002,
  CODE_MESSAGE_E0003,
  CODE_MESSAGE_E0004,
  CODE_MESSAGE_E0005,
  CODE_MESSAGE_E0006,
  CODE_MESSAGE_E0007,
  CODE_MESSAGE_E0008,
  CODE_MESSAGE_E0009,
  CODE_MESSAGE_E0010,
  CODE_MESSAGE_E0011,
  CODE_MESSAGE_E0012,
  CODE_MESSAGE_E0013,
  CODE_MESSAGE_E0014,
  CODE_MESSAGE_E0015,
  CODE_MESSAGE_E0016,
  CODE_MESSAGE_E0017,
  CODE_MESSAGE_E0018,
  CODE_MESSAGE_E0019,
  CODE_MESSAGE_E0020,
  CODE_MESSAGE_E0021,
  CODE_MESSAGE_E0022,
  CODE_MESSAGE_E0023,
  CODE_MESSAGE_E0024,
  CODE_MESSAGE_E0025,
  CODE_MESSAGE_E0026,
  CODE_MESSAGE_E0027,
  CODE_MESSAGE_E0028,
  CODE_MESSAGE_E0029,
  CODE_MESSAGE_E0030,
  CODE_MESSAGE_E0031,
  CODE_MESSAGE_E0032,
  CODE_MESSAGE_E0033,
  CODE_MESSAGE_E0034,
  CODE_MESSAGE_E0035,
  CODE_MESSAGE_E0036,
  CODE_MESSAGE_E0037,
  CODE_MESSAGE_E0038,
  CODE_MESSAGE_E0039,
  CODE_MESSAGE_E0040,
  CODE_MESSAGE_E0041,
  CODE_MESSAGE_E0042,
  CODE_MESSAGE_E0043,
  CODE_MESSAGE_E0045,
  CODE_MESSAGE_E0046,
  CODE_MESSAGE_E0047,
  CODE_MESSAGE_E0048,
  CODE_MESSAGE_E0049,
  CODE_MESSAGE_E0050,
  CODE_MESSAGE_E0051,
  CODE_MESSAGE_E0052,
  CODE_MESSAGE_E0053,
  CODE_MESSAGE_E0054,
  CODE_MESSAGE_E0055,
  CODE_MESSAGE_E0056,
  CODE_MESSAGE_E0057,
  CODE_MESSAGE_E0058,
  CODE_MESSAGE_E0059,
  CODE_MESSAGE_E0060,
  CODE_MESSAGE_E0061,
  CODE_MESSAGE_E0062,
  CODE_MESSAGE_E0063,
  CODE_MESSAGE_E0064,
  CODE_MESSAGE_E0065,
  CODE_MESSAGE_E0066,
  CODE_MESSAGE_E0067,
  CODE_MESSAGE_E0068,
  CODE_MESSAGE_E0069,
  CODE_MESSAGE_E0070,
  CODE_MESSAGE_E0071,
  CODE_MESSAGE_E0072,
  CODE_MESSAGE_E0073,
  CODE_MESSAGE_E0074,
  CODE_MESSAGE_E0075,
  CODE_MESSAGE_E0076,
  CODE_MESSAGE_E0077,
  CODE_MESSAGE_E0078,
  CODE_MESSAGE_E0079,
  CODE_MESSAGE_E0080,
  CODE_MESSAGE_E0081,
  CODE_MESSAGE_E0082,
  CODE_MESSAGE_E0083,
  CODE_MESSAGE_E0084,
  CODE_MESSAGE_E0085,
  CODE_MESSAGE_E0086,
  CODE_MESSAGE_E0087,
  CODE_MESSAGE_E0088,
  CODE_MESSAGE_E0089,
  CODE_MESSAGE_E0090,
  CODE_MESSAGE_E0091,
  CODE_MESSAGE_E0092,
  CODE_MESSAGE_E0093,
  CODE_MESSAGE_E0094,
  CODE_MESSAGE_E0095,
  CODE_MESSAGE_E0096,
  CODE_MESSAGE_E0097,
  CODE_MESSAGE_E0098,
  CODE_MESSAGE_E0099,
  CODE_MESSAGE_E0100,
  CODE_MESSAGE_E0101,
  CODE_MESSAGE_E0102,
  CODE_MESSAGE_E0103,
  CODE_MESSAGE_E0104,
  CODE_MESSAGE_E0105,
  CODE_MESSAGE_E0106,
  CODE_MESSAGE_E0107,
  CODE_MESSAGE_E0108,
  CODE_MESSAGE_E0109,
  CODE_MESSAGE_E0110,
  CODE_MESSAGE_E0111,
  CODE_MESSAGE_E0112,
  CODE_MESSAGE_E0113,
  CODE_MESSAGE_E0114,
  CODE_MESSAGE_E0115,
  CODE_MESSAGE_E0116,
  CODE_MESSAGE_E0117,
  CODE_MESSAGE_E0118,
  CODE_MESSAGE_E0119,
  CODE_MESSAGE_E0120,
  CODE_MESSAGE_E0121,
  CODE_MESSAGE_E0122,
  CODE_MESSAGE_E0123,
  CODE_MESSAGE_E0124,
  CODE_MESSAGE_E0125,
  CODE_MESSAGE_E0126,
  CODE_MESSAGE_E0127,
  CODE_MESSAGE_E0128,
  CODE_MESSAGE_E0129,
  CODE_MESSAGE_E0130,
  CODE_MESSAGE_E0131,
  CODE_MESSAGE_E0132,
  CODE_MESSAGE_E0133,
  CODE_MESSAGE_E0134,
  CODE_MESSAGE_E0135,
  CODE_MESSAGE_E0136,
  CODE_MESSAGE_E0137,
  CODE_MESSAGE_E0138,
  CODE_MESSAGE_E0139,
  CODE_MESSAGE_E0140,
  CODE_MESSAGE_E0141,
  CODE_MESSAGE_E0142,
  CODE_MESSAGE_E0143,
  CODE_MESSAGE_E0144,
  CODE_MESSAGE_E0145,
  CODE_MESSAGE_E0146,
  CODE_MESSAGE_E0147,
  CODE_MESSAGE_E0148,
  CODE_MESSAGE_E0149,
  CODE_MESSAGE_E0150,
  CODE_MESSAGE_E0151,
  CODE_MESSAGE_E0152,
  CODE_MESSAGE_E0153,
  CODE_MESSAGE_E0156,
  CODE_MESSAGE_E9996,
  CODE_MESSAGE_E9997,
  CODE_MESSAGE_E9998,
  CODE_MESSAGE_E9999,
  CODE_MESSAGE_E9999_TITLE,
} = I18nCode

const MESSAGE_E0001 = translateMessage(CODE_MESSAGE_E0001)
const MESSAGE_E0002 = translateMessage(CODE_MESSAGE_E0002)
const MESSAGE_E0003 = translateMessage(CODE_MESSAGE_E0003)
const MESSAGE_E0004 = translateMessage(CODE_MESSAGE_E0004)
const MESSAGE_E0005 = translateMessage(CODE_MESSAGE_E0005)
const MESSAGE_E0006 = translateMessage(CODE_MESSAGE_E0006)
const MESSAGE_E0007 = translateMessage(CODE_MESSAGE_E0007)
const MESSAGE_E0008 = translateMessage(CODE_MESSAGE_E0008)
const MESSAGE_E0009 = translateMessage(CODE_MESSAGE_E0009)
const MESSAGE_E0010 = translateMessage(CODE_MESSAGE_E0010)
const MESSAGE_E0011 = translateMessage(CODE_MESSAGE_E0011)
const MESSAGE_E0012 = translateMessage(CODE_MESSAGE_E0012)
const MESSAGE_E0013 = translateMessage(CODE_MESSAGE_E0013)
const MESSAGE_E0014 = translateMessage(CODE_MESSAGE_E0014)
const MESSAGE_E0015 = translateMessage(CODE_MESSAGE_E0015)
const MESSAGE_E0016 = translateMessage(CODE_MESSAGE_E0016)
const MESSAGE_E0017 = translateMessage(CODE_MESSAGE_E0017)
const MESSAGE_E0018 = translateMessage(CODE_MESSAGE_E0018)
const MESSAGE_E0019 = translateMessage(CODE_MESSAGE_E0019)
const MESSAGE_E0020 = translateMessage(CODE_MESSAGE_E0020)
const MESSAGE_E0021 = translateMessage(CODE_MESSAGE_E0021)
const MESSAGE_E0022 = translateMessage(CODE_MESSAGE_E0022)
const MESSAGE_E0023 = translateMessage(CODE_MESSAGE_E0023)
const MESSAGE_E0024 = translateMessage(CODE_MESSAGE_E0024)
const MESSAGE_E0025 = translateMessage(CODE_MESSAGE_E0025)
const MESSAGE_E0026 = translateMessage(CODE_MESSAGE_E0026)
const MESSAGE_E0027 = translateMessage(CODE_MESSAGE_E0027)
const MESSAGE_E0028 = translateMessage(CODE_MESSAGE_E0028)
const MESSAGE_E0029 = translateMessage(CODE_MESSAGE_E0029)
const MESSAGE_E0030 = translateMessage(CODE_MESSAGE_E0030)
const MESSAGE_E0031 = translateMessage(CODE_MESSAGE_E0031)
const MESSAGE_E0032 = translateMessage(CODE_MESSAGE_E0032)
const MESSAGE_E0033 = translateMessage(CODE_MESSAGE_E0033)
const MESSAGE_E0034 = translateMessage(CODE_MESSAGE_E0034)
const MESSAGE_E0035 = translateMessage(CODE_MESSAGE_E0035)
const MESSAGE_E0036 = translateMessage(CODE_MESSAGE_E0036)
const MESSAGE_E0037 = translateMessage(CODE_MESSAGE_E0037)
const MESSAGE_E0038 = translateMessage(CODE_MESSAGE_E0038)
const MESSAGE_E0039 = translateMessage(CODE_MESSAGE_E0039)
const MESSAGE_E0040 = translateMessage(CODE_MESSAGE_E0040)
const MESSAGE_E0041 = translateMessage(CODE_MESSAGE_E0041)
const MESSAGE_E0042 = translateMessage(CODE_MESSAGE_E0042)
const MESSAGE_E0043 = translateMessage(CODE_MESSAGE_E0043)
const MESSAGE_E0045 = translateMessage(CODE_MESSAGE_E0045)
const MESSAGE_E0046 = translateMessage(CODE_MESSAGE_E0046)
const MESSAGE_E0047 = translateMessage(CODE_MESSAGE_E0047)
const MESSAGE_E0048 = translateMessage(CODE_MESSAGE_E0048)
const MESSAGE_E0049 = translateMessage(CODE_MESSAGE_E0049)
const MESSAGE_E0050 = translateMessage(CODE_MESSAGE_E0050)
const MESSAGE_E0051 = translateMessage(CODE_MESSAGE_E0051)
const MESSAGE_E0052 = translateMessage(CODE_MESSAGE_E0052)
const MESSAGE_E0053 = translateMessage(CODE_MESSAGE_E0053)
const MESSAGE_E0054 = translateMessage(CODE_MESSAGE_E0054)
const MESSAGE_E0055 = translateMessage(CODE_MESSAGE_E0055)
const MESSAGE_E0056 = translateMessage(CODE_MESSAGE_E0056)
const MESSAGE_E0057 = translateMessage(CODE_MESSAGE_E0057)
const MESSAGE_E0058 = translateMessage(CODE_MESSAGE_E0058)
const MESSAGE_E0059 = translateMessage(CODE_MESSAGE_E0059)
const MESSAGE_E0060 = translateMessage(CODE_MESSAGE_E0060)
const MESSAGE_E0061 = translateMessage(CODE_MESSAGE_E0061)
const MESSAGE_E0062 = translateMessage(CODE_MESSAGE_E0062)
const MESSAGE_E0063 = translateMessage(CODE_MESSAGE_E0063)
const MESSAGE_E0064 = translateMessage(CODE_MESSAGE_E0064)
const MESSAGE_E0065 = translateMessage(CODE_MESSAGE_E0065)
const MESSAGE_E0066 = translateMessage(CODE_MESSAGE_E0066)
const MESSAGE_E0067 = translateMessage(CODE_MESSAGE_E0067)
const MESSAGE_E0068 = translateMessage(CODE_MESSAGE_E0068)
const MESSAGE_E0069 = translateMessage(CODE_MESSAGE_E0069)
const MESSAGE_E0070 = translateMessage(CODE_MESSAGE_E0070)
const MESSAGE_E0071 = translateMessage(CODE_MESSAGE_E0071)
const MESSAGE_E0072 = translateMessage(CODE_MESSAGE_E0072)
const MESSAGE_E0073 = translateMessage(CODE_MESSAGE_E0073)
const MESSAGE_E0074 = translateMessage(CODE_MESSAGE_E0074)
const MESSAGE_E0075 = translateMessage(CODE_MESSAGE_E0075)
const MESSAGE_E0076 = translateMessage(CODE_MESSAGE_E0076)
const MESSAGE_E0077 = translateMessage(CODE_MESSAGE_E0077)
const MESSAGE_E0078 = translateMessage(CODE_MESSAGE_E0078)
const MESSAGE_E0079 = translateMessage(CODE_MESSAGE_E0079)
const MESSAGE_E0080 = translateMessage(CODE_MESSAGE_E0080)
const MESSAGE_E0081 = translateMessage(CODE_MESSAGE_E0081)
const MESSAGE_E0082 = translateMessage(CODE_MESSAGE_E0082)
const MESSAGE_E0083 = translateMessage(CODE_MESSAGE_E0083)
const MESSAGE_E0084 = translateMessage(CODE_MESSAGE_E0084)
const MESSAGE_E0085 = translateMessage(CODE_MESSAGE_E0085)
const MESSAGE_E0086 = translateMessage(CODE_MESSAGE_E0086)
const MESSAGE_E0087 = translateMessage(CODE_MESSAGE_E0087)
const MESSAGE_E0088 = translateMessage(CODE_MESSAGE_E0088)
const MESSAGE_E0089 = translateMessage(CODE_MESSAGE_E0089)
const MESSAGE_E0090 = translateMessage(CODE_MESSAGE_E0090)
const MESSAGE_E0091 = translateMessage(CODE_MESSAGE_E0091)
const MESSAGE_E0092 = translateMessage(CODE_MESSAGE_E0092)
const MESSAGE_E0093 = translateMessage(CODE_MESSAGE_E0093)
const MESSAGE_E0094 = translateMessage(CODE_MESSAGE_E0094)
const MESSAGE_E0095 = translateMessage(CODE_MESSAGE_E0095)
const MESSAGE_E0096 = translateMessage(CODE_MESSAGE_E0096)
const MESSAGE_E0097 = translateMessage(CODE_MESSAGE_E0097)
const MESSAGE_E0098 = translateMessage(CODE_MESSAGE_E0098)
const MESSAGE_E0099 = translateMessage(CODE_MESSAGE_E0099)
const MESSAGE_E0100 = translateMessage(CODE_MESSAGE_E0100)
const MESSAGE_E0101 = translateMessage(CODE_MESSAGE_E0101)
const MESSAGE_E0102 = translateMessage(CODE_MESSAGE_E0102)
const MESSAGE_E0103 = translateMessage(CODE_MESSAGE_E0103)
const MESSAGE_E0104 = translateMessage(CODE_MESSAGE_E0104)
const MESSAGE_E0105 = translateMessage(CODE_MESSAGE_E0105)
const MESSAGE_E0106 = translateMessage(CODE_MESSAGE_E0106)
const MESSAGE_E0107 = translateMessage(CODE_MESSAGE_E0107)
const MESSAGE_E0108 = translateMessage(CODE_MESSAGE_E0108)
const MESSAGE_E0109 = translateMessage(CODE_MESSAGE_E0109)
const MESSAGE_E0110 = translateMessage(CODE_MESSAGE_E0110)
const MESSAGE_E0111 = translateMessage(CODE_MESSAGE_E0111)
const MESSAGE_E0112 = translateMessage(CODE_MESSAGE_E0112)
const MESSAGE_E0113 = translateMessage(CODE_MESSAGE_E0113)
const MESSAGE_E0114 = translateMessage(CODE_MESSAGE_E0114)
const MESSAGE_E0115 = translateMessage(CODE_MESSAGE_E0115)
const MESSAGE_E0116 = translateMessage(CODE_MESSAGE_E0116)
const MESSAGE_E0117 = translateMessage(CODE_MESSAGE_E0117)
const MESSAGE_E0118 = translateMessage(CODE_MESSAGE_E0118)
const MESSAGE_E0119 = translateMessage(CODE_MESSAGE_E0119)
const MESSAGE_E0120 = translateMessage(CODE_MESSAGE_E0120)
const MESSAGE_E0121 = translateMessage(CODE_MESSAGE_E0121)
const MESSAGE_E0122 = translateMessage(CODE_MESSAGE_E0122)
const MESSAGE_E0123 = translateMessage(CODE_MESSAGE_E0123)
const MESSAGE_E0124 = translateMessage(CODE_MESSAGE_E0124)
const MESSAGE_E0125 = translateMessage(CODE_MESSAGE_E0125)
const MESSAGE_E0126 = translateMessage(CODE_MESSAGE_E0126)
const MESSAGE_E0127 = translateMessage(CODE_MESSAGE_E0127)
const MESSAGE_E0128 = translateMessage(CODE_MESSAGE_E0128)
const MESSAGE_E0129 = translateMessage(CODE_MESSAGE_E0129)
const MESSAGE_E0130 = translateMessage(CODE_MESSAGE_E0130)
const MESSAGE_E0131 = translateMessage(CODE_MESSAGE_E0131)
const MESSAGE_E0132 = translateMessage(CODE_MESSAGE_E0132)
const MESSAGE_E0133 = translateMessage(CODE_MESSAGE_E0133)
const MESSAGE_E0134 = translateMessage(CODE_MESSAGE_E0134)
const MESSAGE_E0135 = translateMessage(CODE_MESSAGE_E0135)
const MESSAGE_E0136 = translateMessage(CODE_MESSAGE_E0136)
const MESSAGE_E0137 = translateMessage(CODE_MESSAGE_E0137)
const MESSAGE_E0138 = translateMessage(CODE_MESSAGE_E0138)
const MESSAGE_E0139 = translateMessage(CODE_MESSAGE_E0139)
const MESSAGE_E0140 = translateMessage(CODE_MESSAGE_E0140)
const MESSAGE_E0141 = translateMessage(CODE_MESSAGE_E0141)
const MESSAGE_E0142 = translateMessage(CODE_MESSAGE_E0142)
const MESSAGE_E0143 = translateMessage(CODE_MESSAGE_E0143)
const MESSAGE_E0144 = translateMessage(CODE_MESSAGE_E0144)
const MESSAGE_E0145 = translateMessage(CODE_MESSAGE_E0145)
const MESSAGE_E0146 = translateMessage(CODE_MESSAGE_E0146)
const MESSAGE_E0147 = translateMessage(CODE_MESSAGE_E0147)
const MESSAGE_E0148 = translateMessage(CODE_MESSAGE_E0148)
const MESSAGE_E0149 = translateMessage(CODE_MESSAGE_E0149)
const MESSAGE_E0150 = translateMessage(CODE_MESSAGE_E0150)
const MESSAGE_E0151 = translateMessage(CODE_MESSAGE_E0151)
const MESSAGE_E0152 = translateMessage(CODE_MESSAGE_E0152)
const MESSAGE_E0153 = translateMessage(CODE_MESSAGE_E0153)
const MESSAGE_E0156 = translateMessage(CODE_MESSAGE_E0156)
const MESSAGE_E9996 = translateMessage(CODE_MESSAGE_E9996)
const MESSAGE_E9997 = translateMessage(CODE_MESSAGE_E9997)
const MESSAGE_E9998 = translateMessage(CODE_MESSAGE_E9998)
const MESSAGE_E9999 = translateMessage(CODE_MESSAGE_E9999)
const MESSAGE_E9999_TITLE = translateMessage(CODE_MESSAGE_E9999_TITLE)

export const TranslateMessageError = {
  MESSAGE_E0001,
  MESSAGE_E0002,
  MESSAGE_E0003,
  MESSAGE_E0004,
  MESSAGE_E0005,
  MESSAGE_E0006,
  MESSAGE_E0007,
  MESSAGE_E0008,
  MESSAGE_E0009,
  MESSAGE_E0010,
  MESSAGE_E0011,
  MESSAGE_E0012,
  MESSAGE_E0013,
  MESSAGE_E0014,
  MESSAGE_E0015,
  MESSAGE_E0016,
  MESSAGE_E0017,
  MESSAGE_E0018,
  MESSAGE_E0019,
  MESSAGE_E0020,
  MESSAGE_E0021,
  MESSAGE_E0022,
  MESSAGE_E0023,
  MESSAGE_E0024,
  MESSAGE_E0025,
  MESSAGE_E0026,
  MESSAGE_E0027,
  MESSAGE_E0028,
  MESSAGE_E0029,
  MESSAGE_E0030,
  MESSAGE_E0031,
  MESSAGE_E0032,
  MESSAGE_E0033,
  MESSAGE_E0034,
  MESSAGE_E0035,
  MESSAGE_E0036,
  MESSAGE_E0037,
  MESSAGE_E0038,
  MESSAGE_E0039,
  MESSAGE_E0040,
  MESSAGE_E0041,
  MESSAGE_E0042,
  MESSAGE_E0043,
  MESSAGE_E0045,
  MESSAGE_E0046,
  MESSAGE_E0047,
  MESSAGE_E0048,
  MESSAGE_E0049,
  MESSAGE_E0050,
  MESSAGE_E0051,
  MESSAGE_E0052,
  MESSAGE_E0053,
  MESSAGE_E0054,
  MESSAGE_E0055,
  MESSAGE_E0056,
  MESSAGE_E0057,
  MESSAGE_E0058,
  MESSAGE_E0059,
  MESSAGE_E0060,
  MESSAGE_E0061,
  MESSAGE_E0062,
  MESSAGE_E0063,
  MESSAGE_E0064,
  MESSAGE_E0065,
  MESSAGE_E0066,
  MESSAGE_E0067,
  MESSAGE_E0068,
  MESSAGE_E0069,
  MESSAGE_E0070,
  MESSAGE_E0071,
  MESSAGE_E0072,
  MESSAGE_E0073,
  MESSAGE_E0074,
  MESSAGE_E0075,
  MESSAGE_E0076,
  MESSAGE_E0077,
  MESSAGE_E0078,
  MESSAGE_E0079,
  MESSAGE_E0080,
  MESSAGE_E0081,
  MESSAGE_E0082,
  MESSAGE_E0083,
  MESSAGE_E0084,
  MESSAGE_E0085,
  MESSAGE_E0086,
  MESSAGE_E0087,
  MESSAGE_E0088,
  MESSAGE_E0089,
  MESSAGE_E0090,
  MESSAGE_E0091,
  MESSAGE_E0092,
  MESSAGE_E0093,
  MESSAGE_E0094,
  MESSAGE_E0095,
  MESSAGE_E0096,
  MESSAGE_E0097,
  MESSAGE_E0098,
  MESSAGE_E0099,
  MESSAGE_E0100,
  MESSAGE_E0101,
  MESSAGE_E0102,
  MESSAGE_E0103,
  MESSAGE_E0104,
  MESSAGE_E0105,
  MESSAGE_E0106,
  MESSAGE_E0107,
  MESSAGE_E0108,
  MESSAGE_E0109,
  MESSAGE_E0110,
  MESSAGE_E0111,
  MESSAGE_E0112,
  MESSAGE_E0113,
  MESSAGE_E0114,
  MESSAGE_E0115,
  MESSAGE_E0116,
  MESSAGE_E0117,
  MESSAGE_E0118,
  MESSAGE_E0119,
  MESSAGE_E0120,
  MESSAGE_E0121,
  MESSAGE_E0122,
  MESSAGE_E0123,
  MESSAGE_E0124,
  MESSAGE_E0125,
  MESSAGE_E0126,
  MESSAGE_E0127,
  MESSAGE_E0128,
  MESSAGE_E0129,
  MESSAGE_E0130,
  MESSAGE_E0131,
  MESSAGE_E0132,
  MESSAGE_E0133,
  MESSAGE_E0134,
  MESSAGE_E0135,
  MESSAGE_E0136,
  MESSAGE_E0137,
  MESSAGE_E0138,
  MESSAGE_E0139,
  MESSAGE_E0140,
  MESSAGE_E0141,
  MESSAGE_E0142,
  MESSAGE_E0143,
  MESSAGE_E0144,
  MESSAGE_E0145,
  MESSAGE_E0146,
  MESSAGE_E0147,
  MESSAGE_E0148,
  MESSAGE_E0149,
  MESSAGE_E0150,
  MESSAGE_E0151,
  MESSAGE_E0152,
  MESSAGE_E0153,
  MESSAGE_E0156,
  MESSAGE_E9996,
  MESSAGE_E9997,
  MESSAGE_E9998,
  MESSAGE_E9999,
  MESSAGE_E9999_TITLE,
}
