import { SchedulesListDataDetailsType } from "../../../../../../models/list/data"
import { SchedulesExportExcelFRM302Type } from "../../../../../../models/list/export"
import moment from "moment"
import { getTextYesNo } from "../../../../../../../../../utils/getTextYesNo"
import { Variables } from "../../../../../../constants/list/variables"
import { convertDateExport } from "../../../../utils/getDate"

const { FRM302_DEFAULT_VALUE } = Variables

const getHourExport = (date?: string) =>
  date ? moment(date).utc().format("HHmm") : ""

export const convertDataExport = (
  data: SchedulesListDataDetailsType[],
): SchedulesExportExcelFRM302Type[] => {
  const _data: SchedulesExportExcelFRM302Type[] = []
  const firstItem = data[0]
  const lastItem = data[data.length - 1]
  _data.push({
    ...FRM302_DEFAULT_VALUE,
    departureCityName:
      firstItem.departureCityName || firstItem.departureCityNameOther,
    departureCountryName: firstItem.departureCountryName,
    departureDay: convertDateExport(firstItem.departureDateTime),
    departureFlightNumber: firstItem.departureFlightNumber,
    departureTime: getHourExport(firstItem.departureDateTime),
    transportationName: firstItem.transportationName,
  })
  data.forEach((item, index) => {
    const beforeItem = data[index - 1]
    if (index > 0) {
      _data.push({
        arrivalCityName:
          beforeItem.arrivalCityName || beforeItem.arrivalCityNameOther,
        arrivalCountryName: beforeItem.arrivalCountryName,
        arrivalDay: convertDateExport(beforeItem.arrivalDateTime),
        arrivalTime: getHourExport(beforeItem.arrivalDateTime),
        departureCityName:
          item.departureCityName || item.departureCityNameOther,
        departureCountryName: item.departureCountryName,
        departureDay: convertDateExport(item.departureDateTime),
        departureFlightNumber: item.departureFlightNumber,
        departureTime: getHourExport(item.departureDateTime),
        transit: getTextYesNo(beforeItem.isTransit),
        transportationName: item.transportationName,
      })
    }
  })
  _data.push({
    ...FRM302_DEFAULT_VALUE,
    arrivalCityName: lastItem.arrivalCityName || lastItem.arrivalCityNameOther,
    arrivalCountryName: lastItem.arrivalCountryName,
    arrivalDay: convertDateExport(lastItem.arrivalDateTime),
    arrivalTime: getHourExport(lastItem.arrivalDateTime),
  })
  return _data
}
