import styled from "styled-components"

const StyledContainer = styled.div`
  position: relative;
  .ant-form {
    @media (min-width: 768px) {
      min-width: 700px;
      position: absolute;
      right: 0;
      top: -18px;
      &-item {
        margin: 0;
      }
    }
  }
`

export const Styled = {
  StyledContainer,
}
