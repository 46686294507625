/* eslint-disable max-lines */
const AUTHORITY_PATTERN_TYPE = "TYPE011"
const CHANGE_APPLICATION_FORM_TYPE = "TYPE002"
const COUNTRYRM = "COUNTRYRM"
const CTGR = "CTGR"
const CTGR_OTHER = "CTGR999"
const DPTD = "DPTD"
const DPTD_SUBADMIN = ["DPTD001", "DPTD002", "DPTD003", "DPTD004", "DPTD020"]
const FAQ = "FAQ"
const FAQ_TYPE_NOT_JICA_STAFF = "TYPE008"
const FSST = "FSST"
const IPTC = "IPTC"
const IPTC001 = "IPTC001"
const IPTC002 = "IPTC002"
const IPTC003 = "IPTC003"
const JAPAN_COUNTRY_CODE = "036"
const MEASURES_TYPE = "TYPE004"
const NTCG = "NTCG"
const NTDP = "NTDP"
const OTHER = "9999999999"
const PPTV = "PPTV"
const PPTV_HIRED_LOCALLY = "PPTV114"
const PPTV_SCR212 = [
  "PPTV003",
  "PPTV005",
  "PPTV023",
  "PPTV025",
  "PPTV047",
  "PPTV050",
  "PPTV063",
  "PPTV077",
  "PPTV078",
  "PPTV095",
  "PPTV097",
]
const PRFT = "PRFT"
const REGISTRATION_APPLICATION_FORM_TYPE = "TYPE001"
const RLTS = "RLTS"
const SAFETY_MANAGEMENT_DEPARTMENT = "02412"
const SAFETY_MANAGEMENT_DEPARTMENT_TYPE = ["TYPE004", "TYPE014", "TYPE013"]
const SAFETY_MEASURES_MANUAL_TYPE = "TYPE013"
const SAFETY_MEASURES_TYPE = "TYPE009"
const SFTS = "SFTS"
const SUBADMIN_MANUAL_TYPE = "TYPE007"
const SUMMER_TIME_TYPE = "TYPE012"
const TEMPLATE_FOR_REGISTRATION_TYPE = "TYPE014"
const TEMPLATE_TYPE = "TYPE010"
const TIFM = "TIFM"
const TIZO = "TIZO"
const TPTT = "TPTT"
const TPTT001 = "TPTT001"
const TRMCNT = "TRMCNT"
const TYPE = "TYPE"
const UNIVERSAL_COUNTRY_CODE = "999"
const USER_MANUAL_TYPE = "TYPE006"

export const GeneralCode = {
  AUTHORITY_PATTERN_TYPE,
  CHANGE_APPLICATION_FORM_TYPE,
  COUNTRYRM,
  CTGR,
  CTGR_OTHER,
  DPTD,
  DPTD_SUBADMIN,
  FAQ,
  FAQ_TYPE_NOT_JICA_STAFF,
  FSST,
  IPTC,
  IPTC001,
  IPTC002,
  IPTC003,
  JAPAN_COUNTRY_CODE,
  MEASURES_TYPE,
  NTCG,
  NTDP,
  OTHER,
  PPTV,
  PPTV_HIRED_LOCALLY,
  PPTV_SCR212,
  PRFT,
  REGISTRATION_APPLICATION_FORM_TYPE,
  RLTS,
  SAFETY_MANAGEMENT_DEPARTMENT,
  SAFETY_MANAGEMENT_DEPARTMENT_TYPE,
  SAFETY_MEASURES_MANUAL_TYPE,
  SAFETY_MEASURES_TYPE,
  SFTS,
  SUBADMIN_MANUAL_TYPE,
  SUMMER_TIME_TYPE,
  TEMPLATE_FOR_REGISTRATION_TYPE,
  TEMPLATE_TYPE,
  TIFM,
  TIZO,
  TPTT,
  TPTT001,
  TRMCNT,
  TYPE,
  UNIVERSAL_COUNTRY_CODE,
  USER_MANUAL_TYPE,
}
