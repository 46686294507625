/* eslint-disable max-statements */
import {
  GetSchedulesAddSubmitDataProps,
  SchedulesAddDetailsAddType,
  SchedulesAddLodgingsAddType,
  SchedulesAddStaysAddType,
} from "../../../../../models/add/DataAdd"
import { getDetailDeleteData } from "./getDetailDeleteData"
import { getLodgingDeleteData } from "./getLodgingDeleteData"
import { getStayDeleteData } from "./getStayDeleteData"

const getAddOrEditValueTable = (value?: string) =>
  !value || value.includes("tempId") ? "Add" : "Update"

export const getSubmitData = (props: GetSchedulesAddSubmitDataProps) => {
  const {
    id,
    isHiredLocally,
    schedulesAddDetailsSubmit,
    schedulesAddLodgingsSubmit,
    schedulesAddStaysSubmit,
    schedulesData,
  } = props

  const detailDeleteDataNew: SchedulesAddDetailsAddType[] = []

  schedulesAddLodgingsSubmit.forEach((item) => {
    item.type = getAddOrEditValueTable(item.travelLodgingId)
  })

  schedulesAddStaysSubmit.forEach((item) => {
    item.type = getAddOrEditValueTable(item.travelStayId)
  })

  if (!isHiredLocally) {
    schedulesAddDetailsSubmit.forEach((item) => {
      item.type = getAddOrEditValueTable(item.travelDetailId)
    })

    const detailDeleteData = schedulesData.details.filter(
      (item) =>
        !schedulesAddDetailsSubmit
          .map((item) => item.travelDetailId)
          .includes(item.travelDetailId),
    )

    const _detailDeleteDataNew: SchedulesAddDetailsAddType[] =
      getDetailDeleteData(detailDeleteData, id)
    detailDeleteDataNew.push(..._detailDeleteDataNew)
  }

  const lodgingDeleteData = schedulesData.lodgings.filter(
    (item) =>
      !schedulesAddLodgingsSubmit
        .map((item) => item.travelLodgingId)
        .includes(item.travelLodgingId),
  )
  const stayDeleteData = schedulesData.stays.filter(
    (item) =>
      !schedulesAddStaysSubmit
        .map((item) => item.travelStayId)
        .includes(item.travelStayId),
  )

  const lodgingDeleteDataNew: SchedulesAddLodgingsAddType[] =
    getLodgingDeleteData(lodgingDeleteData, id)

  const stayDeleteDataNew: SchedulesAddStaysAddType[] = getStayDeleteData(
    stayDeleteData,
    id,
  )

  return {
    detailDeleteDataNew,
    lodgingDeleteDataNew,
    stayDeleteDataNew,
  }
}
