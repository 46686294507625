import { FC } from "react"
import { Divider } from "../../../../../../_webui/layout/components/Divider"
import { Form } from "../../../../../../_webui/layout/components/Form"
import { FormProvider } from "../../../../../../_webui/layout/components/Form/FormProvider"
import { SchedulesAddCollapse } from "./ContentCollapse"
import { SchedulesAddFormProps } from "./models"
import { SchedulesEditButtonSubmit } from "./ButtonSubmit"
import { SchedulesAddModal } from "./Modal"

export const SchedulesAddForm: FC<SchedulesAddFormProps> = (props) => {
  const {
    form,
    goBack,
    isDisabledSubmit,
    isOpen,
    isOpenExportScheduleDraft,
    isOpenPopup,
    isOpenRoundTrip,
    isOpenScheduleDraft,
    isSchedulesAdd,
    isSchedulesEdit,
    isTwoWay,
    isValidForm,
    isVisibleBtnCancel,
    isVisibleBtnConfirm,
    onCancel,
    onCancelExportScheduleDraft,
    onCancelPopup,
    onCancelRoundTrip,
    onCancelScheduleDraft,
    onFinish,
    onOk,
    onOkExportScheduleDraft,
    onOkRoundTrip,
    onOkScheduleDraft,
    onOpenExportScheduleDraft,
    onOpenScheduleDraft,
    onValuesChange,
    ...rest
  } = props
  return (
    <FormProvider>
      <Form
        form={form}
        isCard={false}
        isHeading={false}
        onFinish={onFinish}
        onValuesChange={onValuesChange}
      >
        <SchedulesAddCollapse isTwoWay={isTwoWay} {...rest} />

        <Divider />

        <SchedulesEditButtonSubmit
          form={form}
          goBack={goBack}
          isDisabledSubmit={isDisabledSubmit}
          isSchedulesAdd={isSchedulesAdd}
          isTwoWay={isTwoWay}
          isValidForm={isValidForm}
          isVisibleBtnCancel={isVisibleBtnCancel}
          isVisibleBtnConfirm={isVisibleBtnConfirm}
          onOpenExportScheduleDraft={onOpenExportScheduleDraft}
          onOpenScheduleDraft={onOpenScheduleDraft}
        />

        <SchedulesAddModal
          isOpen={isOpen}
          isOpenExportScheduleDraft={isOpenExportScheduleDraft}
          isOpenPopup={isOpenPopup}
          isOpenRoundTrip={isOpenRoundTrip}
          isOpenScheduleDraft={isOpenScheduleDraft}
          isSchedulesAdd={isSchedulesAdd}
          isSchedulesEdit={isSchedulesEdit}
          onCancel={onCancel}
          onCancelExportScheduleDraft={onCancelExportScheduleDraft}
          onCancelPopup={onCancelPopup}
          onCancelRoundTrip={onCancelRoundTrip}
          onCancelScheduleDraft={onCancelScheduleDraft}
          onOk={onOk}
          onOkExportScheduleDraft={onOkExportScheduleDraft}
          onOkRoundTrip={onOkRoundTrip}
          onOkScheduleDraft={onOkScheduleDraft}
        />
      </Form>
    </FormProvider>
  )
}
