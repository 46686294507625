/* eslint-disable max-lines */
/* eslint-disable max-statements */
import Excel from "exceljs"
import { saveAs } from "file-saver"
import { SchedulesListDataType } from "../../../../../models/list"
import { exportFileName } from "../../../../../../../../utils/exportFileName"
import { convertDataExport } from "./convertDataExport"
import { cellConfig } from "./cellConfig"
import { columnsConfig } from "./columnsConfig"
import { Translate } from "../../../../../../../../constant/Translate"
import { getTextYesNo } from "../../../../../../../../utils/getTextYesNo"
import { convertDateExport } from "../../../utils/getDate"
import { Timezone } from "../../../../../../../../constant/Timezone"
import moment from "moment"

const { EXPORT_EP0210, EXPORT_EP0211, EXPORT_EP0235, EXPORT_EP0236 } = Translate
const { DATE_TIME_FORMAT_EN } = Timezone

const replacePipeWithSlash = (value: string) =>
  value !== "｜" ? value.replace(/｜/g, "/") : ""

const getDateTimeNowExport = () => moment().format(DATE_TIME_FORMAT_EN)

export const exportFRM302 = async (data: SchedulesListDataType[]) => {
  const workbook = new Excel.Workbook()
  const worksheet = workbook.addWorksheet(EXPORT_EP0236)

  worksheet.getCell("B1").value = EXPORT_EP0211
  worksheet.getCell("D1").value = `${EXPORT_EP0210}: ${getDateTimeNowExport()}`
  columnsConfig(worksheet)
  let rowNumber = 6
  let numericalOrder = 1
  data.map((itemData) => {
    const convertData = convertDataExport(itemData.details)

    for (let index = 0; index < convertData.length; index++) {
      const item = convertData[index]

      worksheet.getCell(`$A${index + rowNumber}`).value = index + numericalOrder
      worksheet.getCell(`$B${index + rowNumber}`).value = itemData.csv.userId
      worksheet.getCell(`$C${index + rowNumber}`).value =
        itemData.info.kanjiSurname
      worksheet.getCell(`$D${index + rowNumber}`).value =
        itemData.info.kanjiName
      worksheet.getCell(`$E${index + rowNumber}`).value =
        itemData.info.romajiSurname
      worksheet.getCell(`$F${index + rowNumber}`).value =
        itemData.info.romajiName
      worksheet.getCell(`$G${index + rowNumber}`).value =
        itemData.info.kanjiSurnamePassport
      worksheet.getCell(`$H${index + rowNumber}`).value =
        itemData.info.kanjiNamePassport
      worksheet.getCell(`$I${index + rowNumber}`).value =
        itemData.info.romajiSurnamePassport
      worksheet.getCell(`$J${index + rowNumber}`).value =
        itemData.info.romajiNamePassport
      worksheet.getCell(`$K${index + rowNumber}`).value =
        itemData.info.personCompanyPosition
      worksheet.getCell(`$L${index + rowNumber}`).value =
        itemData.info.dispatchTypeName
      worksheet.getCell(`$M${index + rowNumber}`).value =
        itemData.info.dispatchTypeDetailName
      worksheet.getCell(`$N${index + rowNumber}`).value =
        itemData.info.travelPurposeName
      worksheet.getCell(`$O${index + rowNumber}`).value =
        itemData.info.travelerBumonName
      worksheet.getCell(`$P${index + rowNumber}`).value =
        itemData.info.projectNameJp
      worksheet.getCell(`$Q${index + rowNumber}`).value =
        itemData.info.projectNameEn
      worksheet.getCell(`$R${index + rowNumber}`).value =
        itemData.info.scheduleBumonName
      worksheet.getCell(`$S${index + rowNumber}`).value =
        itemData.info.mainPersonName
      worksheet.getCell(`$T${index + rowNumber}`).value =
        itemData.info.locationPersonName
      worksheet.getCell(`$U${index + rowNumber}`).value = convertDateExport(
        itemData.info.dispatchStartDate,
      )
      worksheet.getCell(`$V${index + rowNumber}`).value = convertDateExport(
        itemData.info.dispatchEndDate,
      )
      worksheet.getCell(`$W${index + rowNumber}`).value = item.transit
      worksheet.getCell(`$X${index + rowNumber}`).value = item.arrivalDay
      worksheet.getCell(`$Y${index + rowNumber}`).value = item.arrivalTime
      worksheet.getCell(`$Z${index + rowNumber}`).value =
        item.arrivalCountryName
      worksheet.getCell(`$AA${index + rowNumber}`).value = item.arrivalCityName
      worksheet.getCell(`$AB${index + rowNumber}`).value = item.departureDay
      worksheet.getCell(`$AC${index + rowNumber}`).value = item.departureTime
      worksheet.getCell(`$AD${index + rowNumber}`).value =
        item.departureCountryName
      worksheet.getCell(`$AE${index + rowNumber}`).value =
        item.departureCityName
      worksheet.getCell(`$AF${index + rowNumber}`).value =
        item.transportationName
      worksheet.getCell(`$AG${index + rowNumber}`).value =
        item.departureFlightNumber
      worksheet.getCell(`$AH${index + rowNumber}`).value = replacePipeWithSlash(
        itemData.csv.lodgingCheckin,
      )
      worksheet.getCell(`$AI${index + rowNumber}`).value = replacePipeWithSlash(
        itemData.csv.lodgingCheckout,
      )
      worksheet.getCell(`$AJ${index + rowNumber}`).value = replacePipeWithSlash(
        itemData.csv.lodgingName,
      )
      worksheet.getCell(`$AK${index + rowNumber}`).value = replacePipeWithSlash(
        itemData.csv.lodgingAddress,
      )
      worksheet.getCell(`$AL${index + rowNumber}`).value = replacePipeWithSlash(
        itemData.csv.lodgingPhoneNumber,
      )
      worksheet.getCell(`$AM${index + rowNumber}`).value = replacePipeWithSlash(
        itemData.csv.locationPhoneNumber1,
      )
      worksheet.getCell(`$AN${index + rowNumber}`).value = replacePipeWithSlash(
        itemData.csv.locationPhoneNumber2,
      )
      worksheet.getCell(`$AO${index + rowNumber}`).value = replacePipeWithSlash(
        itemData.csv.satellitePhoneNumber,
      )
      worksheet.getCell(`$AP${index + rowNumber}`).value = replacePipeWithSlash(
        itemData.csv.emailAddress1,
      )
      worksheet.getCell(`$AQ${index + rowNumber}`).value = replacePipeWithSlash(
        itemData.csv.emailAddress2,
      )
      worksheet.getCell(`$AR${index + rowNumber}`).value = getTextYesNo(
        itemData.info.codeConductConfirmation,
      )
      worksheet.getCell(`$AS${index + rowNumber}`).value = replacePipeWithSlash(
        itemData.csv.othersRemarks1,
      )
      worksheet.getCell(`$AT${index + rowNumber}`).value = replacePipeWithSlash(
        itemData.csv.othersRemarks2,
      )
      worksheet.getCell(`$AU${index + rowNumber}`).value = replacePipeWithSlash(
        itemData.csv.othersRemarks3,
      )
      worksheet.getCell(`$AV${index + rowNumber}`).value = replacePipeWithSlash(
        itemData.csv.othersRemarks4,
      )
      worksheet.getCell(`$AW${index + rowNumber}`).value = replacePipeWithSlash(
        itemData.csv.othersRemarks5,
      )
      worksheet.getCell(`$AX${index + rowNumber}`).value =
        itemData.info.managerRemarks1
      worksheet.getCell(`$AY${index + rowNumber}`).value =
        itemData.info.managerRemarks2
      worksheet.getCell(`$AZ${index + rowNumber}`).value =
        itemData.info.managerRemarks3
      worksheet.getCell(`$BA${index + rowNumber}`).value =
        itemData.info.managerRemarks4
      worksheet.getCell(`$BB${index + rowNumber}`).value =
        itemData.info.managerRemarks5
      worksheet.getCell(`$BC${index + rowNumber}`).value =
        itemData.info.passportNumber
      worksheet.getCell(`$BD${index + rowNumber}`).value =
        itemData.info.urgentContactHome
      worksheet.getCell(`$BE${index + rowNumber}`).value =
        itemData.info.urgentContactName
      worksheet.getCell(`$BF${index + rowNumber}`).value =
        itemData.info.travelerInsuranceCompanyName
      worksheet.getCell(`$BG${index + rowNumber}`).value =
        itemData.info.travelerInsuranceCompanyPhoneNumber
      worksheet.getCell(`$BH${index + rowNumber}`).value =
        itemData.info.travelerInsuranceTicketNumber
      worksheet.getCell(`$BI${index + rowNumber}`).value =
        itemData.info.travelerInsuranceSonsignor
      worksheet.getCell(`$BJ${index + rowNumber}`).value =
        itemData.info.insuranceCompanyName
      worksheet.getCell(`$BK${index + rowNumber}`).value =
        itemData.info.insuranceCompanyPhoneNumber
      worksheet.getCell(`$BL${index + rowNumber}`).value =
        itemData.info.insuranceTicketNumber
      worksheet.getCell(`$BM${index + rowNumber}`).value =
        itemData.info.insuranceSonsignor
    }
    rowNumber += convertData.length
    numericalOrder += convertData.length
  })

  cellConfig(worksheet)

  const buffer = await workbook.xlsx.writeBuffer()
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
  const fileExtension = ".xlsx"

  const blob = new Blob([buffer], { type: fileType })

  saveAs(blob, exportFileName(EXPORT_EP0235) + fileExtension)
}
