/* eslint-disable max-lines */
import { Translate } from "../../../../../../../constant/Translate"
import { useAuthority } from "../../../../../../../utils/hook/useAuthority"
import {
  ColumnType,
  UseTableConfigType,
} from "../../../../../../../_webui/layout/components/Table/models"
import { SchedulesBatchUpdateDataType } from "../../../../models/batchUpdate"
import { getValueByLanguage } from "../../../../../../../utils/getValueByLanguage"

const {
  TEXT_T002,
  TEXT_T061,
  TEXT_T062,
  TEXT_T063,
  TEXT_T064,
  TEXT_T078,
  TEXT_T079,
  TEXT_T080,
  TEXT_T081,
  TEXT_T082,
  TEXT_T192,
  TEXT_T199,
  TEXT_T200,
  TEXT_T201,
  TEXT_T202,
  TEXT_T203,
  TEXT_T204,
  TEXT_T205,
  TEXT_T206,
  TEXT_T207,
  TEXT_T208,
  TEXT_T209,
  TEXT_T210,
  TEXT_T215,
  TEXT_T220,
  TEXT_T224,
  TEXT_T234,
  TEXT_T235,
  TEXT_T236,
  TEXT_T239,
  TEXT_T244,
  TEXT_T245,
  TEXT_T246,
  TEXT_T247,
  TEXT_T248,
  TEXT_T249,
  TEXT_T250,
  TEXT_T251,
  TEXT_T252,
  TEXT_T253,
  TEXT_T254,
  TEXT_T256,
  TEXT_T257,
  TEXT_T258,
  TEXT_T259,
  TEXT_T260,
  TEXT_T262,
  TEXT_T405,
  TEXT_T439,
} = Translate

export const useTableConfig =
  (): UseTableConfigType<SchedulesBatchUpdateDataType> => {
    const { isRoleUser } = useAuthority()
    const columns: ColumnType<SchedulesBatchUpdateDataType>[] = [
      {
        dataIndex: "userId",
        sorter: (a, b) => a.userId.localeCompare(b.userId),
        title: TEXT_T002,
        width: 250,
      },
      {
        dataIndex: "fullName",
        sorter: (a, b) => a.fullName.localeCompare(b.fullName),
        title: TEXT_T192,
        width: 130,
      },
      {
        dataIndex: "travelScheduleId",
        sorter: (a, b) => a.travelScheduleId.localeCompare(b.travelScheduleId),
        title: TEXT_T224,
        width: 170,
      },
      {
        dataIndex: "travelPurposeName",
        sorter: (a, b) =>
          a.travelPurposeName.localeCompare(b.travelPurposeName),
        title: TEXT_T215,
        width: getValueByLanguage(150, 170),
      },
      {
        dataIndex: "projectNameJp",
        sorter: (a, b) => a.projectNameJp.localeCompare(b.projectNameJp),
        title: TEXT_T199,
        width: 370,
      },
      {
        dataIndex: "projectNameEn",
        sorter: (a, b) => a.projectNameEn.localeCompare(b.projectNameEn),
        title: TEXT_T200,
        width: 370,
      },
      {
        dataIndex: "procurementControlNumber",
        sorter: (a, b) =>
          a.procurementControlNumber.localeCompare(b.procurementControlNumber),
        title: TEXT_T201,
        width: 200,
      },
      {
        dataIndex: "bumonName",
        sorter: (a, b) => a.bumonName.localeCompare(b.bumonName),
        title: TEXT_T234,
        width: 200,
      },
      {
        dataIndex: "mainPersonName",
        sorter: (a, b) => a.mainPersonName.localeCompare(b.mainPersonName),
        title: TEXT_T235,
        width: 210,
      },
      {
        dataIndex: "locationPersonName",
        sorter: (a, b) =>
          a.locationPersonName.localeCompare(b.locationPersonName),
        title: TEXT_T236,
        width: 210,
      },
      {
        dataIndex: "dispatchStartDate",
        sorter: (a, b) =>
          a.dispatchStartDate.localeCompare(b.dispatchStartDate),
        title: TEXT_T405,
        width: getValueByLanguage(240, 200),
      },
      {
        dataIndex: "dispatchEndDate",
        sorter: (a, b) => a.dispatchEndDate.localeCompare(b.dispatchEndDate),
        title: TEXT_T439,
        width: getValueByLanguage(240, 200),
      },
      {
        dataIndex: "departureDateTime",
        sorter: (a, b) =>
          a.departureDateTime.localeCompare(b.departureDateTime),
        title: TEXT_T204,
        width: getValueByLanguage(240, 200),
      },
      {
        dataIndex: "arrivalDateTime",
        sorter: (a, b) => a.arrivalDateTime.localeCompare(b.arrivalDateTime),
        title: TEXT_T207,
        width: getValueByLanguage(240, 200),
      },
      {
        dataIndex: "departureCountryName",
        sorter: (a, b) =>
          a.departureCountryName.localeCompare(b.departureCountryName),
        title: TEXT_T205,
        width: 200,
      },
      {
        dataIndex: "arrivalCountryName",
        sorter: (a, b) =>
          a.arrivalCountryName.localeCompare(b.arrivalCountryName),
        title: TEXT_T208,
        width: 200,
      },
      {
        dataIndex: "departureCityCode",
        sorter: (a, b) =>
          a.departureCityCode.localeCompare(b.departureCityCode),
        title: TEXT_T206,
        width: 150,
      },
      {
        dataIndex: "departureCityName",
        sorter: (a, b) =>
          a.departureCityName.localeCompare(b.departureCityName),
        title: TEXT_T244,
        width: 150,
      },
      {
        dataIndex: "arrivalCityCode",
        sorter: (a, b) => a.arrivalCityCode.localeCompare(b.arrivalCityCode),
        title: TEXT_T209,
        width: 150,
      },
      {
        dataIndex: "arrivalCityName",
        sorter: (a, b) => a.arrivalCityName.localeCompare(b.arrivalCityName),
        title: TEXT_T245,
        width: 150,
      },
      {
        dataIndex: "transportationName",
        sorter: (a, b) =>
          a.transportationName.localeCompare(b.transportationName),
        title: TEXT_T202,
        width: 200,
      },
      {
        dataIndex: "transit",
        sorter: (a, b) => a.transit.localeCompare(b.transit),
        title: TEXT_T220,
        width: 110,
      },
      {
        dataIndex: "departureFlightNumber",
        sorter: (a, b) =>
          a.departureFlightNumber.localeCompare(b.departureFlightNumber),
        title: TEXT_T203,
        width: 150,
      },
      {
        dataIndex: "othersRemarks1",
        sorter: (a, b) => a.othersRemarks1.localeCompare(b.othersRemarks1),
        title: TEXT_T246,
        width: 200,
      },
      {
        dataIndex: "othersRemarks2",
        sorter: (a, b) => a.othersRemarks2.localeCompare(b.othersRemarks2),
        title: TEXT_T247,
        width: 200,
      },
      {
        dataIndex: "othersRemarks3",
        sorter: (a, b) => a.othersRemarks3.localeCompare(b.othersRemarks3),
        title: TEXT_T248,
        width: 200,
      },
      {
        dataIndex: "othersRemarks4",
        sorter: (a, b) => a.othersRemarks4.localeCompare(b.othersRemarks4),
        title: TEXT_T249,
        width: 200,
      },
      {
        dataIndex: "othersRemarks5",
        sorter: (a, b) => a.othersRemarks5.localeCompare(b.othersRemarks5),
        title: TEXT_T250,
        width: 200,
      },
      {
        dataIndex: "differentInforCheck",
        sorter: (a, b) =>
          a.differentInforCheck.localeCompare(b.differentInforCheck),
        title: TEXT_T239,
        width: 300,
      },
      {
        dataIndex: "insuranceCompanyName",
        sorter: (a, b) =>
          a.insuranceCompanyName.localeCompare(b.insuranceCompanyName),
        title: TEXT_T061,
        width: 150,
      },
      {
        dataIndex: "insuranceCompanyPhoneNumber",
        sorter: (a, b) =>
          a.insuranceCompanyPhoneNumber.localeCompare(
            b.insuranceCompanyPhoneNumber,
          ),
        title: TEXT_T062,
        width: 240,
      },
      {
        dataIndex: "insuranceTicketNumber",
        sorter: (a, b) =>
          a.insuranceTicketNumber.localeCompare(b.insuranceTicketNumber),
        title: TEXT_T063,
        width: 150,
      },
      {
        dataIndex: "insuranceSonsignor",
        sorter: (a, b) =>
          a.insuranceSonsignor.localeCompare(b.insuranceSonsignor),
        title: TEXT_T064,
        width: 320,
      },
      {
        dataIndex: "lodgingCheckin",
        sorter: (a, b) => a.lodgingCheckin.localeCompare(b.lodgingCheckin),
        title: TEXT_T251,
        width: 150,
      },
      {
        dataIndex: "lodgingCheckout",
        sorter: (a, b) => a.lodgingCheckout.localeCompare(b.lodgingCheckout),
        title: TEXT_T252,
        width: 150,
      },
      {
        dataIndex: "lodgingName",
        sorter: (a, b) => a.lodgingName.localeCompare(b.lodgingName),
        title: TEXT_T210,
        width: 150,
      },
      {
        dataIndex: "lodgingAddress",
        sorter: (a, b) => a.lodgingAddress.localeCompare(b.lodgingAddress),
        title: TEXT_T253,
        width: getValueByLanguage(250, 300),
      },
      {
        dataIndex: "lodgingPhoneNumber",
        sorter: (a, b) =>
          a.lodgingPhoneNumber.localeCompare(b.lodgingPhoneNumber),
        title: TEXT_T254,
        width: 300,
      },
      {
        dataIndex: "locationPhoneNumber1",
        sorter: (a, b) =>
          a.locationPhoneNumber1.localeCompare(b.locationPhoneNumber1),
        title: TEXT_T256,
        width: 300,
      },
      {
        dataIndex: "emailAddress1",
        sorter: (a, b) => a.emailAddress1.localeCompare(b.emailAddress1),
        title: TEXT_T259,
        width: 230,
      },
      {
        dataIndex: "locationPhoneNumber2",
        sorter: (a, b) =>
          a.locationPhoneNumber2.localeCompare(b.locationPhoneNumber2),
        title: TEXT_T257,
        width: 300,
      },
      {
        dataIndex: "satellitePhoneNumber",
        sorter: (a, b) =>
          a.satellitePhoneNumber.localeCompare(b.satellitePhoneNumber),
        title: TEXT_T258,
        width: 150,
      },
      {
        dataIndex: "emailAddress2",
        sorter: (a, b) => a.emailAddress2.localeCompare(b.emailAddress2),
        title: TEXT_T260,
        width: 230,
      },
      {
        dataIndex: "codeConductConfirmation",
        sorter: (a, b) =>
          a.codeConductConfirmation.localeCompare(b.codeConductConfirmation),
        title: TEXT_T262,
        width: 150,
      },
    ]

    if (!isRoleUser)
      columns.push.apply(columns, [
        {
          dataIndex: "managerRemarks1",
          sorter: (a, b) => a.managerRemarks1.localeCompare(b.managerRemarks1),
          title: TEXT_T078,
          width: 150,
        },
        {
          dataIndex: "managerRemarks2",
          sorter: (a, b) => a.managerRemarks2.localeCompare(b.managerRemarks2),
          title: TEXT_T079,
          width: 150,
        },
        {
          dataIndex: "managerRemarks3",
          sorter: (a, b) => a.managerRemarks3.localeCompare(b.managerRemarks3),
          title: TEXT_T080,
          width: 150,
        },
        {
          dataIndex: "managerRemarks4",
          sorter: (a, b) => a.managerRemarks4.localeCompare(b.managerRemarks4),
          title: TEXT_T081,
          width: 150,
        },
        {
          dataIndex: "managerRemarks5",
          sorter: (a, b) => a.managerRemarks5.localeCompare(b.managerRemarks5),
          title: TEXT_T082,
          width: 150,
        },
      ])

    return {
      columns,
    }
  }
