import { Translate } from "../../../../../../../../constant/Translate"
import { getTextYesNo } from "../../../../../../../../utils/getTextYesNo"
import { GridItemType } from "../../../../../../../../_webui/layout/components/Grid/models"
import { ScheduleInfoType } from "../../../../../models/addSuccess"

const { TEXT_T239, TEXT_T061, TEXT_T062, TEXT_T063, TEXT_T064, TEXT_T100 } =
  Translate

export const getInsuranceGridConfig = (
  scheduleInfo: ScheduleInfoType,
): GridItemType[] => {
  const insuranceGrid = [
    {
      key: TEXT_T239,
      text: getTextYesNo(scheduleInfo.differentInforCheck),
    },
    {
      key: TEXT_T061,
      text: scheduleInfo.insuranceCompanyName,
    },
  ]
  if (scheduleInfo.insuranceCompanyName !== TEXT_T100) {
    insuranceGrid.push(
      {
        key: TEXT_T062,
        text: scheduleInfo.insuranceCompanyPhoneNumber,
      },
      {
        key: TEXT_T063,
        text: scheduleInfo.insuranceTicketNumber,
      },
      {
        key: TEXT_T064,
        text: scheduleInfo.insuranceSonsignor,
      },
    )
  }
  return insuranceGrid
}
