const DPTD_OTHERS_STAFF_NUMBER = [
  "DPTD005",
  "DPTD006",
  "DPTD007",
  "DPTD008",
  "DPTD009",
  "DPTD010",
  "DPTD011",
  "DPTD012",
  "DPTD014",
  "DPTD016",
  "DPTD017",
  "DPTD018",
  "DPTD019",
  "DPTD021",
  "DPTD022",
  "DPTD023",
  "DPTD024",
  "DPTD025",
  "DPTD026",
  "DPTD027",
]
const DPTD_REQUIRED_URGENT_CONTACT = [
  "DPTD002",
  "DPTD003",
  "DPTD004",
  "DPTD005",
  "DPTD008",
  "DPTD009",
  "DPTD010",
  "DPTD011",
]
const E0017 = "E0017"
const E0104 = "E0104"
const E0105 = "E0105"
const E0106 = "E0106"
const E0107 = "E0107"
const E0152 = "E0152"
const E0156 = "E0156"
const PPTV_BUSINESS_TRAVEL = "PPTV115"
const PPTV_FUNIN_AND_FUYO = [
  "PPTV013",
  "PPTV035",
  "PPTV044",
  "PPTV055",
  "PPTV058",
  "PPTV072",
  "PPTV080",
  "PPTV106",
  "PPTV114",
]
const PPTV_GO_BACK = "帰国"
const PPTV_TEMP_GO_BACK = "一時帰国"
const PPTV_TRAINEE = ["DPTD023", "DPTD026"]
const SCHEDULES_DETAIL_ROW_PREFIX = "schedulesAddDetail"
const SCHEDULES_VALIDATE_TABLE_DETAIL = "Detail"
const SCHEDULES_VALIDATE_TABLE_LODGING = "Lodging"
const TPTT_OTHER = "TPTT099"

export const Variables = {
  DPTD_OTHERS_STAFF_NUMBER,
  DPTD_REQUIRED_URGENT_CONTACT,
  E0017,
  E0104,
  E0105,
  E0106,
  E0107,
  E0152,
  E0156,
  PPTV_BUSINESS_TRAVEL,
  PPTV_FUNIN_AND_FUYO,
  PPTV_GO_BACK,
  PPTV_TEMP_GO_BACK,
  PPTV_TRAINEE,
  SCHEDULES_DETAIL_ROW_PREFIX,
  SCHEDULES_VALIDATE_TABLE_DETAIL,
  SCHEDULES_VALIDATE_TABLE_LODGING,
  TPTT_OTHER,
}
