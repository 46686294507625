import styled, { css } from "styled-components"
import { Title } from "../../../../../../../_webui/layout/components/Typography/Title"
import { Regex } from "../../../../../../../constant/Regex"
import { FormSearchRight } from "../../../../../../../_webui/layout/components/Form/Search/Right"
import { Colors } from "../../../../../../../constant/Styled/Colors"

const { FORM_ENTRY_BORDER } = Colors
const { REGEX_IS_DESKTOP } = Regex

const StyledTitle = styled(Title)`
  width: 100%;
  margin-right: 15px;
  margin-bottom: 15px;
`

const StyledGroupItem = styled.div`
  ${REGEX_IS_DESKTOP &&
  css`
    display: flex;
  `}
`

const StyledFormSearchRightGroup = styled(FormSearchRight)`
  .ant-form-item-control-input-content {
    .ant {
      &-form-item {
        margin: 0;
        &-control {
          max-width: 100%;
        }
      }
      &-input {
        border: 1px solid ${FORM_ENTRY_BORDER};
        height: 40px;
        padding-left: 11px;
        &-affix-wrapper {
          flex: 1;
          width: 100%;
        }
        &-group {
          column-gap: 10px;
          &-addon {
            flex: 1;
            width: 100%;
          }
          &-wrapper {
            border: none;
            padding: 0;
          }
        }
      }
    }
  }
  ${!REGEX_IS_DESKTOP &&
  css`
    .ant-form-item-control-input-content {
      .ant {
        &-input {
          &-affix-wrapper {
            display: block;
          }
          &-group {
            &-addon {
              display: block;
              margin-bottom: 25px;
            }
            &-wrapper {
              height: initial;
            }
          }
        }
      }
    }
  `}
`

export const Styled = {
  StyledFormSearchRightGroup,
  StyledGroupItem,
  StyledTitle,
}
