/* eslint-disable max-lines */
/* eslint-disable complexity */
import moment from "moment"
import { Routers } from "../../../../../../../../constant/Routers"
import {
  formatDateSaveDB,
  getDate,
} from "../../../../../../../../utils/getDate"
import { getValueOrUndefined } from "../../../../../../../../utils/getValueOrUndefined"
import {
  ConvertSearchDataSubmitType,
  SchedulesListPostType,
} from "../../../../../models/list"
import { getOverseasResident } from "../../../../../../../../utils/getOverseasResident"
import { getTime } from "../../../../../../../../utils/getTime"

const { SCR210_TRAVELERS } = Routers

const getValue = (value1: string, value2: string) => (value1 ? "" : value2)

export const convertSearchDataSubmit = (
  dataConvert: ConvertSearchDataSubmitType,
) => {
  const {
    isPathnameAttentionsOrSafeties,
    isPathnameSchedules,
    pathname,
    searchCondition,
    values,
  } = dataConvert

  const travelerId = pathname === SCR210_TRAVELERS ? "" : values.travelerId
  const { countryToTravel, fullName, projectName } = values
  const _fullName =
    getValueOrUndefined(!isPathnameSchedules, fullName)?.toString() || ""

  const arrivalTime = getTime(
    searchCondition.arrivalDateTimeHour,
    searchCondition.arrivalDateTimeMinutes,
  )
  const arrivalDateTimeAdvanced = searchCondition.arrivalDateTimeDate
    ? arrivalTime
      ? getDate(searchCondition.arrivalDateTimeDate, arrivalTime)
      : formatDateSaveDB(searchCondition.arrivalDateTimeDate)
    : ""

  const departureTime = getTime(
    searchCondition.departureDateTimeHour,
    searchCondition.departureDateTimeMinutes,
  )

  const departureDateTimeAdvanced = searchCondition.departureDateTimeDate
    ? departureTime
      ? getDate(searchCondition.departureDateTimeDate, departureTime)
      : formatDateSaveDB(searchCondition.departureDateTimeDate)
    : isPathnameAttentionsOrSafeties
    ? formatDateSaveDB(moment().toString())
    : ""
  const overseasResident = getOverseasResident(
    searchCondition.workPlaceLocation === 1,
    searchCondition.workPlace,
    searchCondition.workPlaceCity,
    searchCondition.workPlaceCountry,
  )

  const data: SchedulesListPostType = {
    accommodation: {
      lodgingName: searchCondition.lodgingName,
    },
    assignment: {
      procurementControlNumber: searchCondition.procurementControlNumber,
      projectName: projectName,
      projectNameEn: getValue(projectName, searchCondition.projectNameEn),
      projectNameJp: getValue(projectName, searchCondition.projectNameJp),
      travelPurposeId: searchCondition.travelPurposeId,
    },
    basic: {
      bumonCd: searchCondition.bumonCd,
      dispatchType: searchCondition.dispatchType,
      dispatchTypeDetail: searchCondition.dispatchTypeDetail,
      fullName: _fullName,
      kanjiName: getValue(_fullName, searchCondition.kanjiName),
      kanjiPassportName: searchCondition.kanjiPassportName,
      kanjiPassportSurname: searchCondition.kanjiPassportSurname,
      kanjiSurname: getValue(_fullName, searchCondition.kanjiSurname),
      romajiName: getValue(_fullName, searchCondition.romajiName),
      romajiPassportName: searchCondition.romajiPassportName,
      romajiPassportSurname: searchCondition.romajiPassportSurname,
      romajiSurname: getValue(_fullName, searchCondition.romajiSurname),
    },
    contractPersonJp: {
      personCompanyPosition: searchCondition.personCompanyPosition,
      personDispatcherName: searchCondition.personDispatcherName,
      personPrefectureResidence: searchCondition.personPrefectureResidence,
    },
    other: {
      othersDispatchNumber: searchCondition.othersDispatchNumber,
      othersMemberNumber: searchCondition.othersMemberNumber,
      othersStaffNumber: searchCondition.othersStaffNumber,
    },
    posting: {
      workPlaceLocation: searchCondition.workPlaceLocation,
      ...overseasResident,
    },
    travelerId,
    trip: {
      arrivalCity: values.arrivalCity || searchCondition.arrivalCity,
      arrivalCityCode: searchCondition.arrivalCityCode,
      arrivalCountryCode: getValue(
        searchCondition.arrivalCountryCode,
        countryToTravel,
      ),
      arrivalDateTime: values.arrivalDateTimeDate
        ? formatDateSaveDB(values.arrivalDateTimeDate.toString(), true)
        : arrivalDateTimeAdvanced,
      countryToTravel: countryToTravel,
      departureCity: searchCondition.departureCity,
      departureCityCode: searchCondition.departureCityCode,
      departureCountryCode: searchCondition.departureCountryCode,
      departureDateTime: values.departureDateTimeDate
        ? formatDateSaveDB(values.departureDateTimeDate.toString())
        : isPathnameAttentionsOrSafeties
        ? formatDateSaveDB(moment().toString())
        : departureDateTimeAdvanced,

      departureFlightNumber: searchCondition.departureFlightNumber,
      isTransit: searchCondition.isTransit,
      transportation: searchCondition.transportation,
    },
  }

  return data
}
