const DEFAULT = "DEFAULT"
const FRM101 = "FRM101"
const FRM102 = "FRM102"
const FRM201 = "FRM201"
const FRM301 = "FRM301"
const FRM302 = "FRM302"
const FRM303 = "FRM303"

export const FormId = {
  DEFAULT,
  FRM101,
  FRM102,
  FRM201,
  FRM301,
  FRM302,
  FRM303,
}
