import { FC, Fragment } from "react"
import { Regex } from "../../../../../../../constant/Regex"
import { Space } from "../../../../../../../_webui/layout/components/Space"
import { Table } from "../../../../../../../_webui/layout/components/Table"
import { Tabs } from "../../../../../../../_webui/layout/components/Tabs"
import { SchedulesAddSuccessDetailProps } from "./models"
import { Styled } from "./styled"
import { Translate } from "../../../../../../../constant/Translate"
import { Grid } from "../../../../../../../_webui/layout/components/Grid"

const { REGEX_IS_DESKTOP } = Regex
const {
  StyledRemark,
  StyledRemarkContent,
  StyledRemarkHeader,
  StyledRemarkItem,
  StyledRemarkWrap,
} = Styled
const { TEXT_T240 } = Translate

export const SchedulesAddSuccessDetail: FC<SchedulesAddSuccessDetailProps> = (
  props,
) => {
  const {
    detailsColumnsFirst,
    detailsGridItem,
    detailsTabsItems,
    isHiredLocally,
    scheduleDetails,
  } = props

  return REGEX_IS_DESKTOP ? (
    <Fragment>
      <Grid gridItems={detailsGridItem} />
      {!isHiredLocally && (
        <Space isSpaceTop>
          <Table
            columns={detailsColumnsFirst}
            dataSource={scheduleDetails}
            pagination={false}
            rowKey="travelDetailId"
          />
        </Space>
      )}

      <Space isSpaceTop>
        <StyledRemark>
          {scheduleDetails.map((item, index) => (
            <StyledRemarkWrap key={item.travelDetailId}>
              <StyledRemarkItem>
                <StyledRemarkHeader>{TEXT_T240}</StyledRemarkHeader>
                <StyledRemarkHeader>
                  {item.remarkNames?.remarksName1}
                </StyledRemarkHeader>
                <StyledRemarkHeader>
                  {item.remarkNames?.remarksName2}
                </StyledRemarkHeader>
                <StyledRemarkHeader>
                  {item.remarkNames?.remarksName3}
                </StyledRemarkHeader>
                <StyledRemarkHeader>
                  {item.remarkNames?.remarksName4}
                </StyledRemarkHeader>
                <StyledRemarkHeader>
                  {item.remarkNames?.remarksName5}
                </StyledRemarkHeader>
              </StyledRemarkItem>
              <StyledRemarkItem>
                <StyledRemarkContent>{index + 1}</StyledRemarkContent>
                <StyledRemarkContent>{item.othersRemarks1}</StyledRemarkContent>
                <StyledRemarkContent>{item.othersRemarks2}</StyledRemarkContent>
                <StyledRemarkContent>{item.othersRemarks3}</StyledRemarkContent>
                <StyledRemarkContent>{item.othersRemarks4}</StyledRemarkContent>
                <StyledRemarkContent>{item.othersRemarks5}</StyledRemarkContent>
              </StyledRemarkItem>
            </StyledRemarkWrap>
          ))}
        </StyledRemark>
      </Space>
    </Fragment>
  ) : (
    <Fragment>
      <Grid gridItems={detailsGridItem} />
      <Space isSpaceTop>
        <Tabs defaultActiveKey={"1"} items={detailsTabsItems} />
      </Space>
    </Fragment>
  )
}
