/* eslint-disable max-lines */
import { FC, Fragment } from "react"
import { Translate } from "../../../../../../../../constant/Translate"
import { ItemCheckbox } from "../../../../../../../../_webui/layout/components/Form/Item/Checkbox"
import { ItemDateTimePicker } from "../../../../../../../../_webui/layout/components/Form/Item/DateTimePicker"
import { ItemInput } from "../../../../../../../../_webui/layout/components/Form/Item/Input"
import { ItemSelect } from "../../../../../../../../_webui/layout/components/Form/Item/Select"
import { CollapseTripProps } from "../models"
import { Styled } from "./styled"
import { SelectItems } from "../../../../../../../../constant/SelectItems"

const { OTHER_CITY_SELECT_ITEMS } = SelectItems
const { StyledItemInput } = Styled
const {
  TEXT_T202,
  TEXT_T203,
  TEXT_T204,
  TEXT_T205,
  TEXT_T206,
  TEXT_T207,
  TEXT_T208,
  TEXT_T209,
  TEXT_T215,
  TEXT_T220,
  TEXT_T234,
} = Translate

export const CollapseTrip: FC<CollapseTripProps> = (props) => {
  const {
    allCountries,
    arrivalCities,
    arrivalHourItems,
    arrivalMinuteItems,
    departments,
    departureCities,
    isDefaultValueArrivalDate,
    isDefaultValueDepartureDate,
    isDisabledArrivalCity,
    isDisabledDepartureCity,
    onBlurAdvanced,
    onChangeArrivalCity,
    onChangeArrivalCountryCode,
    onChangeArrivalDate,
    onChangeDepartureCity,
    onChangeDepartureCountryCode,
    onChangeDepartureDate,
    onKeyDownAdvanced,
    purposeTravel,
    transportation,
  } = props

  return (
    <Fragment>
      <ItemSelect
        label={TEXT_T215}
        name="travelPurposeId"
        selectItem={purposeTravel}
      />
      <ItemSelect label={TEXT_T234} name="bumonCd" selectItem={departments} />
      <ItemInput
        label={TEXT_T203}
        maxLength={100}
        name="departureFlightNumber"
      />
      <ItemSelect
        label={TEXT_T202}
        name="transportation"
        selectItem={transportation}
      />
      <ItemCheckbox label={TEXT_T220} name="isTransit" />
      <ItemSelect
        label={TEXT_T205}
        name="departureCountryCode"
        onChange={onChangeDepartureCountryCode}
        selectItem={allCountries}
      />
      <StyledItemInput
        addonBefore={
          <ItemSelect
            name="departureCityCode"
            onChange={onChangeDepartureCity}
            selectItem={[...(departureCities || []), OTHER_CITY_SELECT_ITEMS]}
          />
        }
        isDisabled={isDisabledDepartureCity}
        label={TEXT_T206}
        maxLength={200}
        name="departureCity"
      />
      <ItemDateTimePicker
        datePickerName="departureDateTimeDate"
        isDefaultValue={isDefaultValueDepartureDate}
        label={TEXT_T204}
        name="departureDateTime"
        onBlur={(event) =>
          onBlurAdvanced(event, TEXT_T204, "departureDateTime")
        }
        onChangeDate={onChangeDepartureDate}
        onKeyDown={(event) =>
          onKeyDownAdvanced(event, TEXT_T204, "departureDateTime")
        }
        selectHourName="departureDateTimeHour"
        selectMinutesName="departureDateTimeMinutes"
      />
      <ItemSelect
        label={TEXT_T208}
        name="arrivalCountryCode"
        onChange={onChangeArrivalCountryCode}
        selectItem={allCountries}
      />
      <StyledItemInput
        addonBefore={
          <ItemSelect
            name="arrivalCityCode"
            onChange={onChangeArrivalCity}
            selectItem={[...(arrivalCities || []), OTHER_CITY_SELECT_ITEMS]}
          />
        }
        isDisabled={isDisabledArrivalCity}
        label={TEXT_T209}
        maxLength={200}
        name="arrivalCity"
      />
      <ItemDateTimePicker
        datePickerName="arrivalDateTimeDate"
        isDefaultValue={isDefaultValueArrivalDate}
        label={TEXT_T207}
        name="arrivalDateTime"
        onBlur={(event) => onBlurAdvanced(event, TEXT_T207, "arrivalDateTime")}
        onChangeDate={onChangeArrivalDate}
        onKeyDown={(event) =>
          onKeyDownAdvanced(event, TEXT_T207, "arrivalDateTime")
        }
        selectHourItems={arrivalHourItems}
        selectHourName="arrivalDateTimeHour"
        selectMinuteItems={arrivalMinuteItems}
        selectMinutesName="arrivalDateTimeMinutes"
      />
    </Fragment>
  )
}
