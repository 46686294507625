import { RadioItemType } from "../../../../../../../_webui/layout/components/Radio/models"
import { Translate } from "../../../../../../../constant/Translate"

const { TEXT_T204, TEXT_T207, TEXT_T240 } = Translate

const DETAIL_SORT_TYPES: RadioItemType[] = [
  {
    text: TEXT_T240,
    value: 0,
  },
  {
    text: TEXT_T204,
    value: 1,
  },
  {
    text: TEXT_T207,
    value: 2,
  },
]

export const Constants = {
  DETAIL_SORT_TYPES,
}
