import { getTextYesNo } from "../../../../../../utils/getTextYesNo"

const SCHEDULE_LIST_OVER_RECORD = 240

const FRM302_DEFAULT_VALUE = {
  arrivalCityName: "-",
  arrivalCountryName: "-",
  arrivalDay: "-",
  arrivalTime: "-",
  departureCityName: "-",
  departureCountryName: "-",
  departureDay: "-",
  departureFlightNumber: "-",
  departureTime: "-",
  transit: getTextYesNo(false),
  transportationName: "-",
}

export const Variables = {
  FRM302_DEFAULT_VALUE,
  SCHEDULE_LIST_OVER_RECORD,
}
